import React, { Component } from 'react'
import ContractCard from '../../components/ContractCard'
import map from 'lodash/map'
import propTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import withRouter from '../../components/withRouter'
import { checkIsUserCondominium } from '../../service/user.service'

class ContractList extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      modal: {
        visible: false
      }
    })
  }

  showModal = () => {
    this.modal.visible = true
  }

  openContractOverview = (contract) => {
    if(checkIsUserCondominium([contract])) return
    this.props.contractStore.selectedContract = contract
    this.props.navigate(`/contract-overview/${contract.contractNumber}`)
  }
  render() {
    return (
      <div className='contract-list'>
        {Boolean(this.props.contractStore.contracts.length) && this.props.contractStore.isRabFetched && (
          <div className='contracts-holder'>
            {map(this.props.contractStore.contracts, c => (
              <div className={`contractCardWrapper ${c.rentAccountBalance === '' || !this.props.contractStore.rentAccountEnabled && 'pointerClass'} `} onClick={() => this.openContractOverview(c)} key={c.id}>
                <ContractCard
                  clickable={checkIsUserCondominium([c]) ? false : true}
                  key={c.id}
                  contract={c}
                  rentAccEnabled ={this.props.contractStore.rentAccountEnabled}
                  currContractNum={this.currContractNum}
                  onClick={this.openBalanceHistory}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default inject('contractStore', 'menuStore')(withRouter(observer(ContractList)))
