import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import t from './service/translate.service'
import LoginPage from './pages/LoginPage'
import User from './store/user.store'
import get from 'lodash/get'
import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import LoadingPageOverlay from './components/LoadingPageOverlay'
import { isMobile } from './service/deviceDetection.service'

// UserApp
import SideMenu from './components/SideMenu'
import Footer from './components/Footer'
import Freshchat from './components/Freshchat'
import history from './service/history.service'
import { menuItems } from './config'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { BackIcon } from './components/icons'

// AdminApp
import AdminHeader from './components/AdminHeader'
import './styles/app.css'
import './styles/smooch.css'
import { ConfigProvider } from 'antd'
import enUS from 'antd/es/calendar/locale/en_US'
import withRouter from './components/withRouter'
import EnergyConsumptionNotification from './pages/HeatingAndEnergy/EnergyConsumptionNotification'
import { checkIfUserHasMixedContracts, checkIsUserCondominium } from './service/user.service'


class App extends Component {

  static childContextTypes = {
    user: PropTypes.object
  }

  getChildContext() {
    return {
      user: User
    }
  }

  render() {
    if (User.firstTimeLoading || !this.props.contractStore.ready) {
      return <LoadingPageOverlay />
    }
    let isCondominium = false;
    let mixedContracts = false
    if (User.contracts) {
      isCondominium = checkIsUserCondominium(User.contracts);
      mixedContracts = checkIfUserHasMixedContracts(User.contracts)
    }
    let lettersActive = !(
      isCondominium
        && !this.props.menuStore.lettersEnabledForCondoActive
        || isCondominium
        && !mixedContracts
        && !this.props.menuStore.lettersEnabledForCondoActive
    )
    lettersActive = lettersActive ? this.props.menuStore.lettersActive : false
    const appClass = window.location.hostname === 'mobile-tenant-uat.grandcityproperty.de' ? 'App uat' : 'App'
    return (
      <ConfigProvider locale={enUS}>
        <div>
          <div className={appClass}>
            {get(User, 'user.admin', false) || get(User, 'user.operator', false) ? (
              <div className='admin-app'>
                <AdminApp children={this.props.children} /></div>
            ) : (
              <UserAppWithRouter
                children={this.props.children || <LoginPage />}
                currentPage={this.props.menuStore.currentPage}
                setCurrentPage={this.props.menuStore.setCurrentPage}
                loyaltyActive={this.props.menuStore.loyaltyActive}
                energyAndHeatingActive={this.props.menuStore.energyAndHeatingActive}
                ocInspectionActive = {this.props.menuStore.ocInspectionActive}
                lettersActive = {lettersActive}
                checkSettings={this.props.menuStore.checkSettings}
                newCount={this.props.alertStore.alerts.filter(a => !a.seen).length}
                mobileRedirectPopUpStore={this.props.mobileRedirectPopUpStore.isOpen}
              />
            )}
          </div>
        </div>
      </ConfigProvider>
    )
  }
}

class UserApp extends Component {

  static childContextTypes = {
    user: PropTypes.object,
  }

  getChildContext() {
    return {
      user: User
    }
  }

  logoutUser = () => {
    User.logout()
  }

  renderSubpageHeader = (pathname, menuItems, goBack, navigate) => {
    let shouldRender = ![
      '/',
      '/benefits',
      '/tenant-services',
      '/inbox',
      '/profile',
      '/login',
      '/register',
      '/loyalty-program/tmp-email-sent',
      '/loyalty-program/tmp-logged-in',
      '/onboarding',
      '/onboarding/add-contract',
      '/imprint',
      '/faq'
    ].includes(pathname)

    if (!shouldRender || pathname.includes('/reset-password') || pathname.includes('/forgot-password')) {
      return
    }
    const [findMenuItem] = menuItems.filter(
      item => item.path === '/tenant-services'
    )
    const [findSubmenu] = findMenuItem.submenu.filter(
      item => item.path === pathname
    )
    let title = findSubmenu ? findSubmenu.name : t.NOT_FOUND

    if (pathname === '/contracts') {
      goBack = function () {
        navigate('/tenant-services')
      }
    } else if (pathname === '/contracts/add-contract') {
      title = t.ADD_A_CONTRACT_BUTTON
      goBack = function () {
        navigate('/contracts')
      }
    } else if (pathname.includes('/contracts/prepayments')) {
      title = t.PREPAYMENT_TITLE
      goBack = function () {
        navigate(-2)
      }
    } else if (pathname.includes('/contract-overview')) {
      title = t.CONTRACT_OVERVIEW_TITLE
      goBack = function () {
        navigate('/contracts')
      }
    } else if (pathname === '/forgot-password') {
      title = t.PASSWORD_RESET_TITLE
    } else if (pathname.includes('/rent-account/')) {
      title = t.RENT_ACCOUNT_BALANCE
      goBack = function () {
        navigate(`/contract-overview/${pathname.split('/')[2]}`)
      }
    } else if (pathname.includes('/contract-overview')) {
      title = t.CONTRACT_OVERVIEW
      goBack = function () {
        navigate('/contracts')
      }
    } else if (pathname === '/repairs-and-inquiries') {
      title = t.REPAIRS_AND_INQUIRIES
      goBack = function () {
        navigate('/tenant-services')
      }
    } else if (pathname === '/repairs-and-inquiries/new-request') {
      title = t.NEW_REPORT_TITLE
      goBack = function () {
        navigate('/repairs-and-inquiries')
      }
    } else if (pathname.includes('/feedback')) {
      title = t.FEEDBACK_TITLE
      goBack = function () {
        navigate(-2)
      }
    } else if (pathname.includes('/offer/')) {
      title = t.BENEFITS_OFFERS_TITLE
      goBack = function () {
        navigate('/offers')
      }
    } else if (pathname.includes('/offers')) {
      title = t.OFFERS_TITLE
      goBack = function () {
        navigate('/benefits')
      }
    } else if (pathname === '/documents') {
      title = t.DOCUMENTS
      goBack = function () {
        navigate('/tenant-services')
      }
    }  else if (pathname.includes('/documents/sign')) {
      title = t.DOCUMENTS
      goBack = function () {
        navigate('/documents')
      }
    }
    else if (pathname.includes('/documents/preview') ) {
      title = t.DOCUMENTS
      goBack = function () {
       navigate('/documents')
      }
    }
     else if (pathname.includes('/loyalty-program')) {
      title = t.LOYALTY_PROGRAM
      if (pathname === '/loyalty-program') {
        goBack = function () {
          navigate('/tenant-services')
        }
      } else if (pathname === '/loyalty-program/faq') {
        goBack = function () {
          navigate('/loyalty-program')
        }
      }
    } else if (pathname.includes('/faq')) {
      title = t.MAIN_FAQ
	    goBack = function () {
        navigate('/faq')
      }
    } else if (pathname.includes('/heating-and-energy')) {
      title = t.HEATING_AND_ENERGY_TITLE
      goBack = function () {
        navigate('/tenant-services')
      }
    } else if (pathname.includes('/terms-and-conditions')) {
      title = t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE
      goBack = function () {
        navigate('/loyalty-program')
      }
    } else if (pathname === '/profile/edit') {
      title = t.PROFILE_EDIT
      goBack = function () {
        navigate('/profile')
      }
    } else if (pathname === '/profile/edit/email') {
      title = t.EMAIL_EDIT
      goBack = function () {
        navigate('/profile')
      }
    } else if (pathname === '/profile/edit/password') {
      title = t.PASSWORD_EDIT
      goBack = function () {
        navigate('/profile')
      }
    } else if (pathname.includes('/faq')) {
      title = t.MAIN_FAQ
    } else if (pathname.includes('/consultations')) {
      title = t.CONSULTATIONS_TITLE
      goBack = function () {
        navigate('/tenant-services')
      }
    } else if (pathname.includes('/receipt-inspection')) {
      title = t.RECEIPT_INSPECTION
      goBack = function () {
        navigate('/tenant-services')
      }
    }
    else if (pathname.includes('/letters')) {
      title = t.LETTERS_TITLE
      goBack = function () {
        navigate('/tenant-services')
      }
    } else if (pathname.includes('/oc-calculations')) {
      title = t.OC_CALCULATION
      goBack = function () {
        navigate('/contracts')
      }
    }
    else if (pathname.includes('/rent-contract-documents')) {
      title = t.RENT_CONTRACT_DOCUMENTS
      goBack = function () {
        navigate(`/contract-overview/${pathname.split('/')[2]}`)
      }
    }

    return (
      <header>
        <div className='wrapper'>
          <div className='back' onClick={() => goBack()}>
            {BackIcon}
          </div>
          <div className='title'>{title}</div>    
          <div />
        </div>
      </header>
    )
  }

  render() {
    const pathname = history.location.pathname
    let narrowClass = [
      '/contracts/add-contract',
      '/profile',
      '/profile/edit',
      '/register-success',
      '/register',
      '/login',
      '/forgot-password',
      '/loyalty-program/tmp-email-sent',
      '/onboarding',
      '/onboarding/add-contract',
    ].includes(pathname)
      ? 'narrow'
      : ''

    if (pathname.includes('/reset-password') || pathname.includes('/feedback')) {
      narrowClass = 'narrow'
    }

    let verticalCenterClass = [
      '/register',
      '/login',
      '/onboarding',
      '/onboarding/add-contract'
    ].includes(pathname)
      ? 'vertical-top'
      : ''

    if (pathname.includes('/reset-password')) {
      verticalCenterClass = 'vertical-top'
    }

    let shouldShowSideMenuAndFooter =
      User.user &&
      !['/onboarding', '/onboarding/add-contract'].includes(pathname)

    if (pathname.includes('/reset-password')) {
      shouldShowSideMenuAndFooter = false
    }

    const loggedInClass = shouldShowSideMenuAndFooter ? 'logged-in' : ''

    return (
      <div className={`userApp ${loggedInClass}`}>
        {shouldShowSideMenuAndFooter && (
          <div>
            <Freshchat user={User} />
            <SideMenu
              user={User}
              currentPage={this.props.currentPage}
              setCurrentPage={this.props.setCurrentPage}
              loyaltyActive={this.props.loyaltyActive}
              lettersActive={this.props.lettersActive}
              energyAndHeatingActive={this.props.energyAndHeatingActive}
              ocInspectionActive={this.props.ocInspectionActive}
              checkSettings={this.props.checkSettings}
              logoutUser={this.logoutUser}
              newCount={this.props.newCount}
            />
          </div>
        )}
        <main className='item'>
          {this.renderSubpageHeader(pathname, menuItems, history.goBack, this.props.navigate)}

          <div
            className={`content wrapper ${narrowClass} ${verticalCenterClass}`}
            style={{ paddingTop: `${isMobile ? this.props.mobileRedirectPopUpStore ? '120px' : '40px' : '60px'}` }}
          >
            <EnergyConsumptionNotification user={User?.user} heatingAndEnergyVisibility={this.props.energyAndHeatingActive} />
            <div>
              <Outlet />
            </div>
          </div>

          {shouldShowSideMenuAndFooter && <Footer />}
        </main>
      </div>
    )
  }
}

UserApp.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  loyaltyActive: PropTypes.bool.isRequired,
  energyAndHeatingActive: PropTypes.bool,
  checkSettings: PropTypes.func.isRequired,
  newCount: PropTypes.number.isRequired,
  mobileRedirectPopUpStore: PropTypes.bool
}

const UserAppWithRouter = withRouter(UserApp)

class AdminApp extends Component {

  static childContextTypes = {
    user: PropTypes.object
  }

  getChildContext() {
    return {
      user: User
    }
  }

  render() {
    return (
      <div className='ant-layout-aside'>
        <aside className='ant-layout-sider'>
          <AdminHeader user={User} />
        </aside>
        <div className='ant-layout-main'>
          <div className='ant-layout-container'>
            <div className='ant-layout-content'> <Outlet /></div>
          </div>
        </div>
      </div>
    )
  }
}

AdminApp.propTypes = {
  children: PropTypes.object.isRequired
}

App.propTypes = {
  children: PropTypes.element,
  menuStore: PropTypes.object.isRequired,
  contractStore: PropTypes.object.isRequired,
  alertStore: PropTypes.object.isRequired,
  mobileRedirectPopUpStore: PropTypes.object
}

export default inject('alertStore', 'contractStore', 'menuStore', 'mobileRedirectPopUpStore')(observer(App))
