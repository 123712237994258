import React, { Component } from 'react'
import history from '../../service/history.service'
import { observer, inject } from 'mobx-react'
import t from '../../service/translate.service'
import { Link } from 'react-router-dom'
import { RepairsAndInquiriesImage, ContractsImage } from '../../components/icons'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import MultipaneLayout from '../../components/MultipaneLayout'
import { RepairsAndInquiriesItems, RepairsAndInquiriesContent } from '.'
import RepairsAndInquiriesStore from '../../store/repairsAndInquiries.store'
import NoContent from '../../components/NoContent'
import propTypes from 'prop-types'
import RepairsAndInquiriesSideBar from './RepairsAndInquiriesSideBar'
import historyService from '../../service/history.service'

class RepairsAndInquiries extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired
  }
  constructor (props) {
    super(props)

    this.store = new RepairsAndInquiriesStore()
    this.store.getReports()
    this.store.showAmendConfig()
    this.amendment = ''
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  onItemClick = id => {
    this.store.submitted = false
    this.store.selectedReport = this.store.reports.filter(r => r.id === id)[0]
    this.store.showSuccessMessage = false
    this.store.showAmendForm = false
    this.store.onAmendInput('')
  }
  toggleAmendForm = () => {
    this.store.submitted = false
    this.store.onAmendInput('')
    this.store.showAmendForm = !this.store.showAmendForm
  }
  submitAmendment = (e, reportId) => {
    e.preventDefault()
    this.store.loading = true
    this.store.submitAmendment(reportId, this.store.amendment)
  }
  goToNewRequestPage = () => {
    this.props.navigate('/repairs-and-inquiries/new-request')
  }
  toggleShowResolved = () => {
    this.store.selectedReport = this.store.showResolved ? this.store.reports.filter(r => r.repairAndInquiryStatus.id !== 3)[0] : this.store.reports[0]
    this.store.showResolved = !this.store.showResolved
  }
  render () {
    const { reports, loading, selectedReport, showResolved, showAmendForm, showSuccessMessage, statuses, srAmendmentImagesEnabled, excludedFiles } = this.store
    const filteredReports = reports.filter(r => r.repairAndInquiryStatus.id !== 3)
    const actions = [
      {
        classes: 'primary',
        text: t.NEW_REQUEST,
        onClick: this.goToNewRequestPage
      }]
    if (filteredReports.length && filteredReports.length !== reports.length) {
      actions.push({
        classes: 'secondary',
        text: this.store.showResolved ? t.HIDE_RESOLVED : t.SHOW_RESOLVED,
        onClick: this.toggleShowResolved
      })
    }

    if (loading) {
      return <LoadingPageOverlay />
    }
    if (!this.props.contractStore.selectedContract) {
      return (
        <div className='no-content-holder'>
          <div className='text-center'>
            <Link to='/contracts/add-contract' className='button primary'
            style={{ marginBottom: '30px' }}
            onClick={() => historyService.push('/contracts/add-contract')}>
              {t.ADD_A_CONTRACT_BUTTON}
            </Link>
          </div>
          <NoContent message={t.NO_CONTRACTS} image={ContractsImage} />
        </div>
      )
    }
    if (!reports.length) {
      return (
        <div className='text-center'>
          <Link to='/repairs-and-inquiries/new-request'
            className='primary button'
            style={{ marginBottom: '30px' }}
            onClick={() => historyService.push('/repairs-and-inquiries/new-request')}>{t.NEW_REQUEST}</Link>
          <NoContent
            message={t.NO_REPORTS}
            image={RepairsAndInquiriesImage}
          />
        </div>
      )
    }
    return (
      <div>
        <div className='reports'>
          <div className='multipane-group'>
            <MultipaneLayout>
              <RepairsAndInquiriesItems
                items={showResolved ? reports : filteredReports}
                selectedItem={selectedReport}
                handleClick={this.onItemClick}
                actions={actions}
              />
              <RepairsAndInquiriesSideBar
                items={showResolved ? reports : filteredReports}
                selectedItem={selectedReport}
              />
              <RepairsAndInquiriesContent
                addFile={this.store.addFile}
                downloadDocument={this.store.downloadDocument}
                files={this.store.files}
                item={selectedReport}
                submitAmendment={this.submitAmendment}
                excludedFiles={excludedFiles}
                onAmendInput={this.store.onAmendInput}
                amendment={this.store.amendment}
                showAmendForm={showAmendForm}
                srAmendmentImagesEnabled={srAmendmentImagesEnabled}
                toggleAmendForm={this.toggleAmendForm}
                error={this.store.error}
                showSuccessMessage={showSuccessMessage}
                statuses={statuses}
                validators={this.store.validators}
              />
            </MultipaneLayout>
          </div>
        </div>
      </div>
    )
  }
}

export default inject(
  'contractStore'
)(observer(RepairsAndInquiries))
