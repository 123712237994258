import React, {useState, useEffect} from 'react'
import OtherContractPartner from './Forms/OtherContractPartner'
import OperatingCostsPrepayment from './Forms/OperatingCostsPrepayment'
import DataRelease from './Forms/DataRelease'
import Deposit from './Forms/Deposit'
import NameChange from './Forms/NameChange'
import Credit from './Forms/Credit'
import KeepAnimals from './Forms/KeepAnimals'
import Key from './Forms/Key'
import Fixtures from './Forms/Fixtures'
import DocumentStore from '../../../store/documentForm.store'
import PropTypes from 'prop-types'
import './documentForm.css'
import { observer } from 'mobx-react'
import { useNavigate } from "react-router-dom";
import { documents } from '../../../config'
import { useParams } from 'react-router-dom';
import { api } from '../../../service/api.service'

const DocumentForm = () => {
const navigate = useNavigate();
const [file, setFile] = useState()
let { name } = useParams()

useEffect(() => {
  if(documents) {
  const foundFile = documents.find((d) => d.fileName === name)
  setFile(foundFile)
  }
 }, [documents]) 

  const renderForm = (fileName) => {
    switch (fileName) {
      case 'DE_BK-VZ_v02.pdf':
        return <OperatingCostsPrepayment file={file} />
      case 'DE_weiterer Vertragspartner_v02.pdf':
        return <OtherContractPartner file={file} />
      case 'DE_Datenfreigabe_v02.pdf':
        return <DataRelease file={file} />
      case 'DE_Kaution_v02.pdf':
        return <Deposit file={file} />
      case 'DE_Namensanderung_v02.pdf':
        return <NameChange file={file} />
      case 'DE_Guthaben_v02.pdf':
        return <Credit file={file} />
      case 'DE_Tierhaltung_v02.pdf':
        return <KeepAnimals file={file} />
      case 'DE_Schlussel_v02.pdf':
        return <Key file={file} />
      case 'DE_Einbauten_v02.pdf':
        return <Fixtures file={file} />
    }
  }

  return (
    <div className='document-form-holder'>
      <button className='close-form' type='button' onClick={() => {
        DocumentStore.onCloseForm()
        navigate('/documents')
      }}>
        x
      </button>
      {file && renderForm(file.fileName)}
    </div>
  )
}

DocumentForm.propTypes = {
  file: PropTypes.object
}

export default observer(DocumentForm)
