import React, {useEffect} from 'react'
import PrimaryTitle from '../../components/PrimaryTitle'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import ContractSelector from '../../components/contractSelector'
import { Alert } from 'antd'
import LoadingPageOverlay from "../../components/LoadingPageOverlay";

export const LettersRegistration = ({
  handleClick,
  handleCancel,
  contract,
  loading,
  isLettersConsumptionEnabled,
  loaded,
  error,
  setIsLettersConsumptionEnable
}) => {

  useEffect(() => {
    (async () => {
      await setIsLettersConsumptionEnable(contract)
    })()
  }, [contract])

  if (loading || (!isLettersConsumptionEnabled && !loaded)) {
    return <LoadingPageOverlay />
  }

  return (
    <div className='wrapper heating-energy-modal'>
            <PrimaryTitle title={t.LETTERS_TITLE} />
      <div style={{marginBottom: '10px'}} className='description'>{t.LETTERS_REGISTRATION_TEXT_1}</div>
      <div className='description'>{t.LETTERS_REGISTRATION_TEXT_2}</div>
      {error && (
        <Alert message={error} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
      )}
         <div className='energy-contract-picker'>
            <ContractSelector page='letters' label padding />
          </div> 
      <div className='btns-holder'>
        <button type='button' className='button tertiary' onClick={handleCancel}>{t.HEATING_AND_ENERGY_CANCEL}</button>
        <button type='button' className='button primary' onClick={handleClick}>{t.LOYALTY_SUBMIT_CODE}</button>
      </div>
    </div>
  )
}
LettersRegistration.propTypes = {
    handleClick: PropTypes.func,
    handleCancel: PropTypes.func,
    isLettersConsumptionEnabled: PropTypes.bool,
    loaded: PropTypes.bool,
    setIsLettersConsumptionEnable: PropTypes.func,
    contract: PropTypes.object,
      
}