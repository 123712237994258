import { extendObservable, autorun } from 'mobx'
import { api, API_BASE } from '../service/api.service'
import { extractError } from '../service/response.service'
import {
  buildValidators,
  email,
  phone,
  requiredFormField
} from '../service/validate'
import find from 'lodash/find'
import { stores } from './index'
import User from './user.store'
import t from '../service/translate.service'
import moment from 'moment'
import * as DOMPurify from 'dompurify'

class DocumentFormStore {
  constructor() {
    extendObservable(this, {
      model: {
        operatingCostOn: '',
        operatingCostRaise: '',
        heatingCostOn: '',
        heatingCostRaise: '',
        operatingCostPayDate: '',
        tenantNo: '',
        fullName: '',
        firstName: '',
        lastName: '',
        address: '',
        phone: '',
        email: '',
        place: '',
        date: '',
        otherContractFirstName: '',
        otherContractLastName: '',
        otherContractRelationship: '',
        otherContractBirthDate: '',
        otherContractAddress: '',
        otherContractPostCode: '',
        otherContractTown: '',
        otherContractPhone: '',
        dataReleaseFileNumber: '',
        dataReleaseResponsibleOffice: '',
        dataReleaseContactPerson: '',
        depositCurrentAddress: '',
        depositTerminationDate: '',
        depositApartmentHandoverDate: '',
        depositAmount: '',
        depositAccountHolder: '',
        depositIban: '',
        depositBic: '',
        nameChangeFirstName: '',
        nameChangeLastName: '',
        nameChangeReason: '',
        creditAccountOwner: '',
        creditIban: '',
        creditBic: '',
        keepAnimalSpecie: '',
        keepAnimalRace: '',
        keepAnimalDescription: '',
        keepAnimalQuantity: '',
        keepAnimalOwnPet: t['LOGOUT_NO'],
        keepAnimalType: '',
        keepAnimalNumber: '',
        keyReorderReason: '',
        fixturesDesignation: '',
        fixturesScope: '',
        fixturesPlaceOfConversion: '',
        fixturesSpecialistCompany: '',
        fixturesCostEstimate: false,
        fixturesDetailedDrawing: false,
        fixturesDescriptionOfTheMeasure: false,
        showOptions: false,
        selectedKeys: [],
        additionalLength: 0,
        keyFormError: '',
        language: ''
      },
      submitErr: '',
      submitted: false,
      loader: false,
      isFormOpen: false,
      submitSuccess: false,
      file: '',
      preview: false,
      keyOptions: [
        { value: t.KEY_HEAD_A, key: 'centralKey' },
        { value: t.KEY_HEAD_B, key: 'frontDoorKey' },
        { value: t.KEY_HEAD_C, key: 'apartmentKey' },
        { value: t.KEY_HEAD_D, key: 'letterBoxKey' },
        { value: t.KEY_HEAD_E, key: 'roomKey' },
        { value: t.KEY_HEAD_F, key: 'garage' },
        { value: t.KEY_HEAD_G, key: 'additional', count: 0 }
      ],
      validateKeysObject: {},
      tenantInstalationFiles: [],
      fileUploadError: '',
      digitalFormsEnabled: false
    })
    autorun(() => {
      if (stores.contractStore.selectedContract) {
        this.setOnLoad(stores.contractStore.selectedContract)
      }
    })
  }
  showDigitalFormsConfig() {
    api.get(`${API_BASE}/api/config`)
      .then(res => {
        if (res.data.settings.digitalFormsEnabled) {
          this.digitalFormsEnabled = res.data.settings.digitalFormsEnabled
        }
      })
  }
  get operatingCostValidators() {
    return buildValidators(
      {
        operatingCostOn: [requiredFormField],
        operatingCostRaise: [requiredFormField],
        heatingCostOn: [requiredFormField],
        heatingCostRaise: [requiredFormField],
        operatingCostPayDate: [requiredFormField],
        fullName: [requiredFormField],
        address: [requiredFormField],
        phone: [phone],
        email: [requiredFormField, email],
        place: [requiredFormField],
        date: [requiredFormField]
      },
      this.submitted
    )
  }
  get otherContractValidators() {
    return buildValidators(
      {
        fullName: [requiredFormField],
        place: [requiredFormField],
        date: [requiredFormField],
        otherContractFirstName: [requiredFormField],
        otherContractLastName: [requiredFormField],
        otherContractRelationship: [requiredFormField],
        otherContractBirthDate: [requiredFormField],
        otherContractAddress: [requiredFormField],
        otherContractPostCode: [requiredFormField],
        otherContractTown: [requiredFormField],
        otherContractPhone: [phone]
      },
      this.submitted
    )
  }
  get dataReleaseValidators() {
    return buildValidators(
      {
        fullName: [requiredFormField],
        place: [requiredFormField],
        date: [requiredFormField],
        dataReleaseFileNumber: [requiredFormField],
        dataReleaseResponsibleOffice: [requiredFormField],
        dataReleaseContactPerson: [requiredFormField]
      },
      this.submitted
    )
  }
  get tenantInstallationsValidators() {
    return buildValidators(
      {
        place: [requiredFormField],
        date: [requiredFormField],
        fixturesDesignation: [requiredFormField],
        fixturesScope: [requiredFormField],
        fixturesPlaceOfConversion: [requiredFormField]
      },
      this.submitted
    )
  }
  get creditOutpaymentValidators() {
    return buildValidators(
      {
        tenantNo: [requiredFormField],
        fullName: [requiredFormField],
        address: [requiredFormField],
        phone: [phone],
        email: [requiredFormField, email],
        place: [requiredFormField],
        date: [requiredFormField],
        creditAccountOwner: [requiredFormField],
        creditIban: [requiredFormField],
        creditBic: [requiredFormField]
      },
      this.submitted
    )
  }
  get depositOutpaymentValidators() {
    return buildValidators(
      {
        tenantNo: [requiredFormField],
        firstName: [requiredFormField],
        lastName: [requiredFormField],
        address: [requiredFormField],
        phone: [phone],
        place: [requiredFormField],
        date: [requiredFormField],
        depositCurrentAddress: [requiredFormField],
        depositTerminationDate: [requiredFormField],
        depositApartmentHandoverDate: [requiredFormField],
        depositAmount: [requiredFormField],
        depositAccountHolder: [requiredFormField],
        depositIban: [requiredFormField],
        depositBic: [requiredFormField]
      },
      this.submitted
    )
  }
  get nameChangeValidators() {
    return buildValidators(
      {
        fullName: [requiredFormField],
        place: [requiredFormField],
        date: [requiredFormField],
        nameChangeFirstName: [requiredFormField],
        nameChangeLastName: [requiredFormField],
        nameChangeReason: [requiredFormField]
      },
      this.submitted
    )
  }
  get keyReorderValidators() {
    return buildValidators(
      {
        ...this.validateKeysObject,
        fullName: [requiredFormField],
        place: [requiredFormField],
        date: [requiredFormField],
        keyReorderReason: [requiredFormField]
      },
      this.submitted
    )
  }
  get keepAnimalValidators() {
    return buildValidators(
      {
        place: [requiredFormField],
        date: [requiredFormField],
        keepAnimalSpecie: [requiredFormField],
        keepAnimalRace: [requiredFormField],
        keepAnimalDescription: [requiredFormField],
        keepAnimalQuantity: [requiredFormField],
        keepAnimalOwnPet: [requiredFormField],
        keepAnimalType: [requiredFormField],
        keepAnimalNumber: [requiredFormField]
      },
      this.submitted
    )
  }
  validateForms = (file) => {
    let validator
    switch (file) {
      case 'DE_BK-VZ_v02.pdf':
        validator = this.operatingCostValidators
        break
      case 'DE_weiterer Vertragspartner_v02.pdf':
        validator = this.otherContractValidators
        break
      case 'DE_Datenfreigabe_v02.pdf':
        validator = this.dataReleaseValidators
        break
      case 'DE_Einbauten_v02.pdf':
        validator = this.tenantInstallationsValidators
        break
      case 'DE_Guthaben_v02.pdf':
        validator = this.creditOutpaymentValidators
        break
      case 'DE_Kaution_v02.pdf':
        validator = this.depositOutpaymentValidators
        break
      case 'DE_Namensanderung_v02.pdf':
        validator = this.nameChangeValidators
        break
      case 'DE_Schlussel_v02.pdf':
        if (this.model.selectedKeys.length === 0) {
          this.model.keyFormError = t.KEY_FORM_ERROR
          return
        }
        validator = this.keyReorderValidators
        break
      case 'DE_Tierhaltung_v02.pdf':
        validator = this.keepAnimalValidators
    }
    const isValid = !find(validator, (validate, field) => {
      if (this.model['keepAnimalOwnPet'] === t['LOGOUT_YES']) {
        return validate(this.model[field])
      } else if (this.model['keepAnimalOwnPet'] === t['LOGOUT_NO'] && field !== 'keepAnimalType') {
        if (field !== 'keepAnimalNumber') {
          return validate(this.model[field])
        }
      }
    })
    return isValid
  }
  setOnLoad = (contract) => {
    if (User.user) {
      const { user } = User
      this.model.operatingCostPayDate = new Date()
      this.model.tenantNo = contract.contractNumber
      this.model.fullName = `${user.firstName} ${user.lastName}`
      this.model.firstName = `${user.firstName}`
      this.model.lastName = `${user.firstName}`
      this.model.address = contract.address
      this.model.phone = user.phone
      this.model.email = user.email
      this.model.date = new Date()
      this.model.otherContractBirthDate = new Date()
      this.model.depositTerminationDate = new Date()
      this.model.depositApartmentHandoverDate = new Date()
    }
  }
  setVal = (field, val) => {
    this.model[field] = val
  }
  addFile(files) {
    if (files.length) {
      files.forEach(file => this.tenantInstalationFiles.push(file))
      this.fileUploadError = ''
    } else {
      this.fileUploadError = t.INVALID_FILE_TYPE
    }
  }
  setValidateKeysObejct = (key, type, input, value) => {
    if (type === 'add') {
      this.validateKeysObject[`${key}KeyNumber`] = [requiredFormField]
      this.validateKeysObject[`${key}Manufacturer`] = [requiredFormField]
      this.validateKeysObject[`${key}Quantity`] = [requiredFormField]
      this.model[`${key}KeyNumber`] = ''
      this.model[`${key}Manufacturer`] = ''
      this.model[`${key}Quantity`] = ''
      if (key.includes('additional')) {
        this.validateKeysObject[`${key}Head`] = [requiredFormField]
        this.model[`${key}Head`] = ''
      }
    } else if (type === 'remove') {
      delete this.validateKeysObject[`${key}KeyNumber`]
      delete this.validateKeysObject[`${key}Manufacturer`]
      delete this.validateKeysObject[`${key}Quantity`]
      delete this.model[`${key}KeyNumber`]
      delete this.model[`${key}Manufacturer`]
      delete this.model[`${key}Quantity`]
      if (key.includes('additional')) {
        delete this.validateKeysObject[`${key}Head`]
        delete this.model[`${key}Head`]
      }
    } else if (type === 'input-change') {
      switch (input) {
        case 'keyNumber':
          this.model[`${key}KeyNumber`] = value
          break
        case 'manufacturer':
          this.model[`${key}Manufacturer`] = value
          break
        case 'quantity':
          this.model[`${key}Quantity`] = value
          break
        case 'head':
          this.model[`${key}Head`] = value
      }
    }
  }
  setSelectedKeys = (option, type, input = '', value = '') => {
    const optionKey = option.key === 'additional' ? option.key + option.count : option.key
    const optionHead = option.key === 'additional' ? '' : option.value
    if (type === 'add') {
      this.model.selectedKeys = [...this.model.selectedKeys, { head: optionHead, key: optionKey, keyNumber: '', manufacturer: '', quantity: '' }]
      this.setValidateKeysObejct(optionKey, 'add')
      this.keyOptions.forEach((keyOption) => {
        if (keyOption.key === 'additional' && option.key === 'additional') {
          keyOption.count++
          this.model.additionalLength++
        }
      })
      this.model.keyFormError = ''
      this.submitted = false
    } else if (type === 'remove') {
      this.model.selectedKeys = this.model.selectedKeys.filter((selectedKey) => selectedKey.key !== option.key)
      this.setValidateKeysObejct(optionKey, 'remove')
      if (option.key.includes('additional')) {
        this.model.additionalLength--
      }
    } else if (type === 'input-change') {
      this.model.selectedKeys.forEach((selectedKey) => {
        if (selectedKey.key === option.key) {
          selectedKey[input] = value
        }
      })
      this.setValidateKeysObejct(optionKey, 'input-change', input, value)
    }
  }
  deleteFromModelAndValidateObject = () => {
    Object.keys(this.model).forEach(i => {
      if (i.includes('KeyNumber') || i.includes('Manufacturer') || (i.includes('Quantity') && i !== 'keepAnimalQuantity') || i.includes('Head')) {
        delete this.model[i]
      }
    })
    Object.keys(this.validateKeysObject).forEach(i => {
      if (i.includes('KeyNumber') || i.includes('Manufacturer') || (i.includes('Quantity') && i !== 'keepAnimalQuantity') || i.includes('Head')) {
        delete this.validateKeysObject[i]
      }
    })
  }
  resetValues = () => {
    Object.keys(this.model).forEach(i => { this.model[i] = '' })
    this.model['fixturesCostEstimate'] = false
    this.model['fixturesDetailedDrawing'] = false
    this.model['fixturesDescriptionOfTheMeasure'] = false
    this.model['keepAnimalOwnPet'] = t['LOGOUT_NO']
    this.model['selectedKeys'] = []
    this.deleteFromModelAndValidateObject()
    this.submitted = false
    this.tenantInstalationFiles = []
    setTimeout(() => {
      this.submitErr = ''
    }, 3000)
  }
  onCloseForm = () => {
    this.isFormOpen = false
    this.resetValues()
  }
  onOpenForm = () => {
    this.resetValues()
    this.setOnLoad(stores.contractStore.selectedContract)
    this.isFormOpen = true
  }
  submit = (file, contractNumber) => {
    this.submitted = true
    this.submitErr = null
    const isValid = this.validateForms(file)
    if (!isValid) return
    this.loader = true
    Object.keys(this.model).forEach(i => {
      if (i.includes('date') || i.includes('Date')) {
        this.model[i] = moment(this.model[i]).format('DD/MM/YYYY')
      }
    })
    const data = new FormData()
    const language = t.locale
    this.model.contractNumber = contractNumber
    this.model.pdfName = file
    this.model.language = language
    this.tenantInstalationFiles.forEach((file, i) => data.append(`file_${i}`, file, file.name))
    api
      .post('/api/document-form', this.model)
      .then((res) => {
        const id = res.data.id
        const srNumber = res.data.srNumber.replace(/^(\.\.(\/|\\|$))+/, '')
        data.set('repairAndInquiryId', id)
        data.set('srNumber', srNumber)
        try {
          api.post('/api/document-form-attachments', data, {
            timeout: 300000, headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        } catch(error) {
          this.submitErr = t[extractError(error)] || t['ERROR']
          this.loader = false
          throw error
        }
        this.loader = false
        this.submitSuccess = true
      })
      .catch(err => {
        this.submitErr = t[extractError(err)] || t['ERROR']
        this.loader = false
        throw err
      })
    this.onCloseForm()
    return { succes: true }
  }
}

const DocumentStore = new DocumentFormStore()

export default DocumentStore
