import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import { extendObservable, runInAction } from 'mobx'
import { api, API_BASE } from '../service/api.service'
import t from '../service/translate.service'
import moment from 'moment'

class PrepaymentsFormStore {
  constructor(contractNumber) {
    extendObservable(this, {
      model: {
        editablePrepayments: []
      },
      dateToday: moment().format('YYYY-MM-DD'),
      editablePrepayments: [],
      futurePrepayments: [],
      currentPrepayments: [],
      loading: false,
      submitting: false,
      submitSuccess: false,
      error: ''
    })

    window.store = this
    window.scrollTo(0, 0)
    this.loadPrepayments(contractNumber)
  }

  async loadPrepayments(contractNumber) {
    runInAction(() => {
      this.loading = true
    })
    try {
      const response = await api.get(`${API_BASE}/api/contract/${contractNumber}/prepayments`)
      const data = extractResponse(response)
      const editablePrepayments = data.editablePrepayments.reduce((acc, prepayment) => {
        acc.push({
          ...prepayment,
          checked: false
        })
        return acc
      }, [])
      console.log('ok')
      runInAction(() => {
        this.currentPrepayments.replace(data.currentPrepayments.reduce((acc, prepayment) => {
          acc.push({
            ...prepayment,
            label: prepayment.condtype === '9200'
              ? t.PREPAYMENT_ADJUST_OPERATIONAL_COSTS
              : t.PREPAYMENT_ADJUST_HEATING_COSTS,
          })
          return acc
        }, []))
        this.editablePrepayments.replace(editablePrepayments)
        this.futurePrepayments.replace(data.futurePrepayments.map(prepayment => ({
          ...prepayment, 
          label: prepayment.condtype === '9200'
            ? t.PREPAYMENT_OPERATIONAL_COSTS
            : t.PREPAYMENT_HEATING_COSTS
        })))
        this.model.editablePrepayments.replace(editablePrepayments)
        this.loading = false
      })
    } catch (err) {
      console.log(err)
      runInAction(() => {
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
        window.scrollTo(0, 0)
        this.loading = false
      })
    }
  }

  changePrepayments = (value, editablePrepayment) => runInAction(() => {
    const amount = Number(value.replace('.', ''))
    if (!amount) {
      return
    }
    const prepayments = this.model.editablePrepayments.map(prepayment => {
      if (prepayment.condtype === editablePrepayment.condtype) {
        prepayment.unitprice = amount
      }
      return prepayment
    })
    this.model.editablePrepayments.replace(prepayments)
  })

  togglePrepayments = (editablePrepayment) => runInAction(() => {
    const prepayments = this.model.editablePrepayments.map(prepayment => {
      if (prepayment.condtype === editablePrepayment.condtype) {
        prepayment.checked = !prepayment.checked
      }
      return prepayment
    })

    this.model.editablePrepayments.replace(prepayments)
  })

  async submit(contractNumber) {
    runInAction(() => {
      this.submitting = true
      this.error = ''
      this.submitSuccess = false
    })

    try {
      await api.post(`${API_BASE}/api/contract/${contractNumber}/prepayments`, {
        contractNumber,
        editablePrepayments: this.model.editablePrepayments
          .filter(prepayment => prepayment.checked)
          .map(prepayment => {
            delete prepayment.checked
            delete prepayment.label
            return prepayment
          })
      })

      runInAction(() => {
        this.submitting = false
        this.error = ''
        this.submitSuccess = true
        window.scrollTo(0, 0)
      })
    } catch (err) {
      console.log(extractError(err))
      runInAction(() => {
        this.submitting = false
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
        this.submitSuccess = false
        window.scrollTo(0, 0)
      })
    }
  }
}

export default PrepaymentsFormStore