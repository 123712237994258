import { extendObservable, autorun } from 'mobx'
import moment from 'moment'
import { api, API_BASE } from '../service/api.service'
import { stores } from './index'
import User from './user.store'
import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import t from '../service/translate.service'
import * as DOMPurify from 'dompurify'
import { Buffer } from 'buffer'
import contractService from '../service/contract.service'
import { isEmpty } from 'lodash'
class HeatingAndEnergyStore {
  constructor() {
    extendObservable(this, {
      model: {
        datePeriod: this.subtractMonths(new Date(), 1),
        contractNumber: '',
        dataAvailable: true,
        error: '',
        noData: false,
      },
      submitErr: null,
      heatingData: {
        consumption: '',
        previousMonth: '',
        currentMonthLastYear: '',
        comparison: ''
      },
      warmWaterData: {
        consumption: '',
        previousMonth: '',
        currentMonthLastYear: '',
        comparison: ''
      },
      owner: '',
      tenants: [],
      partners: [],
      company: '',
      errorRegistration: null,
      errorInfo: null,
      loading: false,
      energyRegistrationInfoLoaded: false,
      loaded: false,
      loadingData: false,
      loadingPdfDownload: false,
      initialLoad: true,
      isEnergyConsumptionEnabled: false, // TO-DO: GET THIS VALUE FROM BACKEND
      energyConsumptionUnregisterModal: false,
    })
    this.getEnergyByDate = this.getEnergyByDate.bind(this)
    autorun(() => {
      if (this.isEnergyConsumptionEnabled) {
        this.getEnergyByDate(this.model.datePeriod)
      }
    })
  }
  setVal = (field, value) => {
    this.model[field] = value
  } 

  downloadEnergyDocument = async () => {
    try {
    this.loadingPdfDownload = true
    const isCondominium = contractService.checkIsContractCondominium(stores.contractStore.selectedContract.contractNumber)
    const contractNumber = stores.contractStore.selectedContract.contractNumber
    const monthYear = moment(this.model.datePeriod).locale('de').format("YYYYMM")
    const formattedDate = monthYear.replace(/ /g, '-')
    const documentName = `UVI-${formattedDate}-${contractNumber}.pdf`
    const currentMonth = moment(this.model.datePeriod).add(1, 'month')
    const res = await api.get(API_BASE + `/api/energy/${contractNumber}/download-pdf`, {
      params: {
        condominium: isCondominium,
        date: currentMonth,
        platform: 'web',
        heatingData: JSON.stringify(this.heatingData),
        warmWaterData: JSON.stringify(this.warmWaterData)
      }
    })
    if(res) {
      const buffer = Buffer.from(res.data.buffer)
      const blob = new Blob([buffer])
      let url = window.URL.createObjectURL(blob)
      url = DOMPurify.sanitize(url)
      const a = document.createElement('a')
      a.setAttribute('style', 'display: none;')
      a.href = url
      a.download = documentName
      a.click()
      window.URL.revokeObjectURL(url)
    }
    this.loadingPdfDownload = false
  } catch(err) {
    this.loadingPdfDownload = false
  }
     this.trackViewedEnergyData('download')
  }

  trackViewedEnergyData = async (type) => { 
    const monthYear = moment(this.model.datePeriod).format("YYYYMM")
    await api.post(API_BASE + `/api/energy/track`, {
      appId: stores.contractStore.selectedContract.id.toString(),
      period: monthYear,
      access_type: type,
      contract_number: stores.contractStore.selectedContract.contractNumber
    })
  }

  onRegister = async (contract, consumptionInfoViaApp) => {
    this.setLoading(true)
    this.errorRegistration = null
    await api.post(API_BASE + `api/consumption/register`, {
      contract_number: contract.contractNumber,
      appId: contract.id,
      consumptionInfoViaApp,
      optingFor: 'Energy'
    })
    .catch((err) => {
      this.errorRegistration = 
        extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
    })
    this.isEnergyConsumptionEnabled = consumptionInfoViaApp
    this.setLoading(false)
  }

  onRefuseModalConfirml = () => {
    localStorage.removeItem('ENERGY_CONSUMPTION_MODAL')
  }

  onCheckboxChange = (state) => {
    if (state) {
      this.onRegister(stores.contractStore.selectedContract, true)
    } else {
      this.energyConsumptionUnregisterModal = true
    }
  }

  onUnregisterCancel = () => {
    this.energyConsumptionUnregisterModal = false
  }

  setIsEnergyConsumptionEnable = async (contract, setContract) => {
    this.setLoading(true)
    this.energyRegistrationInfoLoaded = false
    const consumptionInfoViaApp =
      await stores.consumptionStore.getConsumptionRegistrationInfo(
        contract.contractNumber,
        'Energy'
      )
    this.isEnergyConsumptionEnabled = consumptionInfoViaApp
    this.energyRegistrationInfoLoaded = true
    this.setLoading(false)
    return consumptionInfoViaApp
  }

  getEnergyRegistrationInfo = async (contract) => {
    this.errorRegistration = null
    this.errorInfo = null
    const consumptionInfoViaApp =
      await stores.consumptionStore.getConsumptionRegistrationInfo(
        contract.contractNumber,
        'Energy'
      )
    return consumptionInfoViaApp
  }

  setLoading = (loading) => {
    this.loading = loading 
  }

  setLoaded = (loading) => {
    this.loaded = loading
  }

  subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  async getEnergyByDate(date) {
    let contract = stores.contractStore.selectedContract
    try {
      this.loadingData = true
      const r = await stores.consumptionStore.getConsumptionRegistrationInfo(
        contract.contractNumber,
        'Energy'
      )
      if (!r) {
        stores.contractStore.selectedContract = contract
        this.isEnergyConsumptionEnabled = false
        return
      }
      const { data: result } = await api.get(API_BASE + `/api/energy/${contract.contractNumber}`, {
        params: {
          startPeriod: moment(date, 'YYYY-MM').subtract(13, 'months').format('YYYY-MM'),
          endPeriod: moment(date, 'YYYY-MM').format('YYYY-MM')
        }
      })
      if (result) {
        this.model.noData = !result.length
        this.loadingData = result.length
        
        if(this.initialLoad) {
          result
            .reverse()
            .every(
              (r) => {
                if (r.heating.amountConv || r.hot_water.amountConv) {
                  const dateMonth = moment(r.period).toISOString()
                  const newDate = new Date(dateMonth)
                  this.model.datePeriod = newDate
                  date = newDate
                  return false;
                } else return true;
              },
            );
          this.initialLoad = false
        }
        const chosenMonthString = moment(date, 'YYYY-MM').format('YYYYMM')
        const previousMonthString = moment(date, 'YYYY-MM').subtract(1, 'months').format('YYYYMM')
        const currentMonthLastYearString = moment(date, 'YYYY-MM').subtract(12, 'months').format('YYYYMM')
        this.model.error = ''
        this.model.dataAvailable = true
        let chosenMonthData = result.find((data) => data.period === chosenMonthString)
        if (!chosenMonthData) {
          this.model.dataAvailable = false
          this.loadingData = false 
          return
        }
        let previousMonthData = result.find((data) => data.period === previousMonthString)
        let currentMonthLastYear = result.find((data) => data.period === currentMonthLastYearString)
        this.heatingData.consumption = chosenMonthData?.heating?.amountConv.replace('.', ',')
        this.heatingData.previousMonth = previousMonthData?.heating?.amountConv.replace('.', ',')
        this.heatingData.currentMonthLastYear = currentMonthLastYear?.heating?.amountConv.replace('.', ',')
        this.heatingData.comparison = chosenMonthData?.heating?.amountBenchmark.replace('.', ',')
        this.warmWaterData.consumption = chosenMonthData?.hot_water?.amountConv.replace('.', ',')
        this.warmWaterData.previousMonth = previousMonthData?.hot_water?.amountConv.replace('.', ',')
        this.warmWaterData.currentMonthLastYear = currentMonthLastYear?.hot_water?.amountConv.replace('.', ',')
        this.warmWaterData.comparison = chosenMonthData?.hot_water?.amountBenchmark.replace('.', ',')
        if(!isEmpty(this.heatingData) || !isEmpty(this.warmWaterData)) {
          this.trackViewedEnergyData('view')
        }
        if (
          !this.heatingData.consumption &&
          !this.heatingData.previousMonth &&
          !this.heatingData.currentMonthLastYear &&
          !this.heatingData.comparison &&
          !this.warmWaterData.consumption &&
          !this.warmWaterData.previousMonth &&
          !this.warmWaterData.currentMonthLastYear &&
          !this.warmWaterData.comparison
        ) {
          this.model.dataAvailable = false
        }
      } else {
        this.model.dataAvailable = false
      }
      this.loadingData = false
    } catch (err) {
      this.model.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
      console.log('Heating consumption error:', err)
      this.loadingData = false
    }
  }
}

export default HeatingAndEnergyStore
