import { extendObservable } from 'mobx'
import {
  buildValidators,
  required,
  password,
  minLength,
  maxLength,
  passwordValidation
} from '../service/validate'
import find from 'lodash/find'
import { api } from '../service/api.service'

class ChangePasswordStore {
  constructor() {
    extendObservable(this, {
      model: {
        password: '',
        newPassword: '',
        passwordConfirm: ''
      },
      submitted: false,
      loader: true,
      submitErr: null
    })
  }

  get validators() {
    return buildValidators(
      {
        newPassword: [
          required,
          minLength(10),
          maxLength(50),
          passwordValidation,
          password(this.model.passwordConfirm)
        ]
      },
      this.submitted
    )
  }
  setVal = (field, val) => {
    this.model[field] = val
  }
  submit = () => {
    this.submitted = true
    const isValid = !find(this.validators, (validate, field) =>
      validate(this.model[field])
    )
    if (!isValid) return
    return api
      .put('/api/auth/change-password', { password: this.model.password, newUserPassword: this.model.newPassword, passwordConfirm: this.model.passwordConfirm })
  }
}

export default ChangePasswordStore
