import React, { useState } from 'react'
import BasicSlider from '../components/BasicSlider'
import onboardingBenefits from '../assets/onboarding-benefits.png'
import onboardingTenantService from '../assets/onboarding-tenantService.png'
import onboardingLoyalty from '../assets/GCP_Icons_money.png'
import onboardingLoyaltyIcon from '../assets/GCP_Icons_Treueprogramm.png'
import onboardingNews from '../assets/newsIcon.png'
import { LoyaltyPointIcon } from '../components/icons'
import t from '../service/translate.service'
import PropTypes from 'prop-types'
import withRouter from '../components/withRouter'

const Onboarding = props => {
  const steps = [
    {
      id: 1,
      image: onboardingTenantService,
      title: t.TENANT_SERVICES,
      description: t.SCREEN_1_TEXT
    },
    {
      id: 2,
      image: onboardingBenefits,
      title: t.BENEFITS,
      description: t.SCREEN_2_TEXT
    },
    {
      id: 3,
      image: onboardingNews,
      title: t.NEWS_SLIDE_TITLE,
      description: t.SCREEN_3_TEXT
    },
    {
      id: 4,
      image: onboardingLoyalty,
      image_text_1: t.LOYALTY_SLIDE_TEXT_1,
      image_text_2: t.LOYALTY_SLIDE_TEXT_2,
      image_text_3: t.LOYALTY_SLIDE_TEXT_3,
      description: t.SCREEN_4_TEXT,
      icon_1: LoyaltyPointIcon,
      icon_text_1: t.SCREEN_4_IMAGE_TEXT_1,
      icon_2: onboardingLoyaltyIcon,
      icon_text_2: t.SCREEN_4_IMAGE_TEXT_2
    }
  ]

  const [sliderInstance, setSliderInstance] = useState({})
  const [hideSkipButton, setHideSkipButton] = useState(false)
  const [lastStep, setLastStep] = useState(false)

  const customClasses = hideSkipButton ? 'hide' : ''
  const nextButtonText = lastStep ? t.GOT_IT : t.NEXT

  const goToAddContract = () => props.navigate('/onboarding/add-contract')
  const nextButtonHandleClick = lastStep ? goToAddContract : nextSlide

  return (
    <div className='text-center'>
      <BasicSlider
        classNames='onboarding'
        setSliderInstance={setSliderInstance}
        items={steps}
        settings={{
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: <SkipButton customClasses={customClasses} handleClick={goToAddContract} text={t.SKIP} />,
          nextArrow: <NextButton text={nextButtonText} handleClick={nextButtonHandleClick} slider={sliderInstance} />,
          beforeChange: (current, next) => {
            if (next === steps.length - 1) {
              setLastStep(true)
              setHideSkipButton(true)
            } else if (next > 0) {
              setLastStep(false)
              setHideSkipButton(true)
            } else {
              setLastStep(false)
              setHideSkipButton(false)
            }
          }
        }}
      />
    </div>
  )
}

function SkipButton ({ className, customClasses, style, handleClick, text }) {
  return (
    <div
      className={`${className} ${customClasses}`}
      style={{ ...style }}
      onClick={handleClick}
    >
      <div className='button tertiary'>{text}</div>
    </div>
  )
}
SkipButton.propTypes = {
  className: PropTypes.string,
  customClasses: PropTypes.string,
  style: PropTypes.object,
  handleClick: PropTypes.func,
  text: PropTypes.string
}
function NextButton ({ className, style, handleClick, slider, text }) {
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={e => handleClick(slider)}
    >
      <div className='button tertiary'>{text}</div>
    </div>
  )
}

NextButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  handleClick: PropTypes.func,
  slider: PropTypes.object,
  text: PropTypes.string
}
function nextSlide (sliderInstance) {
  sliderInstance.slickNext()
}

export default withRouter(Onboarding)
