import User from "../store/user.store";
import { extractError, extractErrorFromSap } from "../service/response.service";
import { extendObservable, autorun } from "mobx";
import { stores } from "./index";
import { api, API_BASE } from "../service/api.service";
import t from "../service/translate.service";
import _ from "lodash";
class LetterStore {
  constructor() {
    extendObservable(this, {
      isLettersConsumptionEnabled: false,
      user: User.user,
      errorRegistration: null,
      lettersConsumptionUnregisterModal: false,
      loading: false,
      lettersRegistrationInfoLoaded: false,
      loaded: false,
      letters: [],
      page: 1,
      count: 0,
    });
    this.getLetters = this.getLetters.bind(this);
    autorun(() => {
      if (this.isLettersConsumptionEnabled) {
        this.getLetters();
      }
    });
  }
  onRegister = async (contract, consumptionInfoViaApp) => {
    this.setLoading(true);
    this.errorRegistration = null;
    await api
      .post(API_BASE + `api/consumption/register`, {
        appId: contract.id,
        consumptionInfoViaApp,
        contract_number: contract.contractNumber,
        optingFor: "Letters",
      })
      .catch((err) => {
        this.errorRegistration =
          extractErrorFromSap(err) || t[extractError(err)] || t["ERROR"];
      });
    this.isLettersConsumptionEnabled = consumptionInfoViaApp
    this.setLoading(false);
  };

  async trackLetters(contract, type, letter) {
    const { data: result } = await api.post(API_BASE + 'api/letters/track', {
        contract_number: contract.contractNumber,
        appId: contract?.id?.toString(),
        access_type: type,
        docId: letter.id,
        fileName: letter.name,
        tplid: letter.link
    })
  }

  async getLetters() {
    this.setLoading(true);
    let contract = stores.contractStore.selectedContract;
    const lettersToApp =
      await stores.consumptionStore.getConsumptionRegistrationInfo(
        contract.contractNumber,
        "Letters"
      );
    this.isLettersConsumptionEnabled = lettersToApp;
    if (!lettersToApp) {
      stores.contractStore.selectedContract = contract;
      return;
    }
    try {
      const { data: result } = await api.get(
        API_BASE + `api/letters/${contract.contractNumber}`
      );
      this.count = result.count;
      let limit = 10;
      let offset = this.page;
      if (result && result.letters) {
        const allLetters = result.letters;
        let skip = this.page === 1 ? 0 : (offset - 1) * limit;
        const sliced = allLetters.slice(skip, skip + 10);
        this.letters = sliced;
      }
      this.setLoading(false);
    } catch (err) {
      this.letters = [];
      this.setLoading(false);
    }
  }

  setIsLettersConsumptionEnable = async (contract, setContract) => {
    this.setLoading(true);
    this.lettersRegistrationInfoLoaded = false;
    const lettersToApp =
      await stores.consumptionStore.getConsumptionRegistrationInfo(
        contract.contractNumber,
        "Letters"
      );
    if (lettersToApp) {
      if (setContract) {
        stores.contractStore.selectedContract = contract;
      }
    }
    this.lettersRegistrationInfoLoaded = true;
    this.isLettersConsumptionEnabled = lettersToApp
    this.setLoading(false);
    return lettersToApp;
  };

  onPageChange = async (p) => {
    this.page = p
    this.getLetters()
  } 

      onSubscriptionChange = (state) => {
        if (state) {
          this.onRegister(stores.contractStore.selectedContract, true)
        } else {
          this.lettersConsumptionUnregisterModal = true
        }
      }

      onUnregisterCancel = () => {
        this.lettersConsumptionUnregisterModal = false
      }

      setLoading = (loading) => {
        this.loading = loading
      }

      setLoaded = (loaded = false) => {
        this.loaded = loaded
      }

      onLetterDownload = async (letter) => {
        this.setLoading(true)
        const { data: result } = await api.get(API_BASE + `api/letter/${letter.id}`).catch((err) => this.errorInfo = err)
        this.setLoading(false)
        return result
     }
}
export default LetterStore;
