import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PrimaryTitle from '../../components/PrimaryTitle'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import ContractSelector from '../../components/contractSelector'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { Alert } from 'antd'

export const HeatingAndEnergyRegister = ({
  handleClick,
  handleCancel,
  loading,
  isEnergyConsumptionEnabled,
  loaded,
  contract,
  setIsEnergyConsumptionEnable,
  error
}) => {
  useEffect(() => {
    (async () => {
      await setIsEnergyConsumptionEnable(contract)
    })()
  }, [contract])

  if (loading || (!isEnergyConsumptionEnabled && !loaded)) {
    return <LoadingPageOverlay />
  }

  return (
    <div className='wrapper heating-energy-modal'>
      {}
      <PrimaryTitle title={t.HEATING_AND_ENERGY_TITLE} />
      <div className='description'>{t.HEATING_AND_ENERGY_WELCOME_ONE}</div>
      <div className='description'>{t.HEATING_AND_ENERGY_WELCOME_TWO}</div>
      {error && (
        <Alert message={error} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
      )}
      <div className='energy-contract-picker'>
        <ContractSelector label padding />
      </div>
      <div className='btns-holder'>
        <button type='button' className='button primary' onClick={handleClick}>{t.HEATING_AND_ENERGY_REGISTER}</button>
        <button type='button' className='button tertiary' onClick={handleCancel}>{t.HEATING_AND_ENERGY_CANCEL}</button>
      </div>
    </div>
  )
}
HeatingAndEnergyRegister.propTypes = {
  handleClick: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  isEnergyConsumptionEnabled: PropTypes.bool,
  loaded: PropTypes.bool,
  contract: PropTypes.object,
  setIsEnergyConsumptionEnable: PropTypes.func,
}