const strings = {
  CURRENT_LANGUAGE: { de: 'DE', en: 'EN' },
  CLEAR: { de: 'Alles löschen', en: 'Clear all' },
  REDIRECT_TO_MOBILE: {
    de: 'Mieter-Service schnell & digital',
    en: 'Tenant Service quick & digital'
  },
  REDIRCT_TO_MOBILE_LINK: {
    de: 'In der App',
    en: 'In the app'
  },
  INVALID_CONTRACT_FORMAT: {
    de: 'Die eingegebene Mieternummer ist nicht gültig. Bitte tragen Sie eine gültige Mieternummer oder Verwaltungseinheit ein. Diese finden Sie in Ihrem Mietvertrag bzw. auf unseren Schreiben an Sie. Achten Sie bei der Eingabe auf das korrekte Format (z. B. A0011_M_001.01).',
    en: 'The entered tenant number is not valid. Please enter your valid tenant number, which you find in your contract and in letters directed at you. Please pay attention to the correct form (e.g. A0011_M_001.01).'
  },
  PENDING_CONTRACT_STICKY: {
    en: 'Your contract information has been submitted for manual approval by our staff. You will be updated via email as soon as it is done.',
    de: 'Ihre Angaben wurden übermittelt. Sie werden per E-Mail informiert, sobald die Überprüfung abgeschlossen ist.'
  },
  PENDING_CONTRACT: {
    en: 'CONTRACT',
    de: 'VERTRAG'
  },
  SAVE_SUCCESS: {
    en: 'Your changes have been saved successfully.',
    de: 'Ihre Änderungen wurden erfolgreich gespeichert.'
  },
  SAVE_EMAIL: {
    en: 'Confirmation link has been sent to your new email address.',
    de: 'Der Bestätigungslink wurde an Ihre neue E-Mail-Adresse gesendet.'
  },
  SAVE_PASSWORD: {
    en: 'You have successfully changed your password.',
    de: 'Sie haben Ihr Passwort erfolgreich geändert.'
  },
  CONTRACT_REQUIRED: {
    de: 'Bitte tragen Sie eine gültige Mieternummer oder Verwaltungseinheit ein. Diese finden Sie in Ihrem Mietvertrag bzw. auf unseren Schreiben an Sie (z. B. A0011_M_001.01).',
    en: 'Please enter your valid tenant number, which you find in your contract and in letters directed at you. (E.g. A0011_M_001.01).'
  },
  PHONE_FORMAT: {
    de: 'Die Telefonnummer sollte mit 0 oder + beginnen und aus mindestens 11 Zeichen bestehen. Bitte nur Zahlen, - und + nutzen.',
    en: "Phone number should start with 0 or + and consist of at least 11 characters. Only numbers, '-' and '+' are accepted."
  },
  SWITCH_CONTRACT: { de: 'Vertrag wechseln', en: 'Switch contract' },
  BM_BURGER_LABEL: { de: 'Menü', en: 'Menu' },
  ADMIN_CONSULTATIONS_TITLE: {
    de: 'Persönliche Mietersprechstunde vor Ort',
    en: 'Personal tenant consultation on site'
  },
  SCHEDULED_CONSULTATIONS_TITLE: {
    de: 'Besetzt Mietersprechstunden',
    en: 'Scheduled consultations'
  },
  SCHEDULED_CONSULTATION: {
    de: 'Besetzt Mietersprechstunde',
    en: 'Scheduled consultation'
  },
  CONSULTATIONS_PAGE_DISCLAIMER: {
    en: 'Short-term information on our tenant consultation hours can also always be found on the in-house notice boards.',
    de: 'Kurzfristige Informationen zu unseren Mietersprechstunden finden Sie auch immer auf den aktuellen Hausaushängen.'
  },
  CONSULTATIONS_TITLE: {
    de: 'Mietersprechstunden',
    en: 'Tenant Consultations'
  },
  LETTERS_TITLE: {
    de: 'Briefe',
    en: 'Letters'
  },
  CONSULTATIONS_SUBTITLE: {
    de: 'Bitte wählen Sie Ihren Standort und ein Datum aus.',
    en: 'Select your property and the date to schedule consultation'
  },
  CONSULTATIONS_PROP_MANAGERS: { de: 'Hausverwalter', en: 'Property managers' },
  CONSULTATIONS_WORKING_HOURS: { de: 'Sprechstunden', en: 'Consultation hours' },
  CONSULTATIONS_APPOINTMENT_NEEDED: { de: 'Termin erforderlich', en: 'Appointment necessary' },
  CONSULTATIONS_FREQUENCY: { de: 'Frequenz', en: 'Frequency' },
  CONSULTATIONS_NO_OFFICE_1: { de: 'Zu Ihrem Mietobjekt konnte kein Service Büro gefunden werden, bitte ', en: 'There is no consultation office assigned to your contract. Please ' },
  CONSULTATIONS_NO_OFFICE_2: { de: 'kontaktieren Sie unser Service Center ', en: 'contact our service center' },
  CONSULTATIONS_NO_HOURS_1: { de: 'Zu Ihrem Service Büro konnten keine Sprechzeiten gefunden werden, bitte ', en: 'There are no consultation hours assigned to your consultation office. Please' },
  CONSULTATIONS_NO_HOURS_2: { de: 'kontaktieren Sie unser Service Center ', en: 'contact our service center' },
  CONSULTATIONS_YES: { de: 'Ja', en: 'Yes' },
  CONSULTATIONS_NO: { de: 'Nein', en: 'No' },
  CONSULTATIONS_FREQUENCY_AW: { de: 'wöchentlich', en: 'weekly' },
  CONSULTATIONS_FREQUENCY_GW: { de: 'gerade Wochen', en: 'even weeks' },
  CONSULTATIONS_FREQUENCY_UW: { de: 'ungerade Wochen', en: 'odd weeks' },
  CONSULTATIONS_FREQUENCY_1W: { de: 'erste Woche des Monats', en: 'first week of the month' },
  CONSULTATIONS_FREQUENCY_2W: { de: 'zweite Woche des Monats', en: 'second week of the month' },
  CONSULTATIONS_FREQUENCY_3W: { de: 'dritte Woche des Monats', en: 'third week of the month' },
  CONSULTATIONS_FREQUENCY_4W: { de: 'vierte Woche des Monats', en: 'fourth week of the month' },
  CHANGE_PASSWORD: { de: 'Passwort ändern', en: 'Change Password' },
  WRONG_PASSWORD_CHANGE_PASSWORD: { de: 'Das Passwort ist nicht korrekt', en: 'Wrong password' },
  CONSULTATIONS_CHOOSE_SLOT: {
    de: 'Wählen Sie immer nur ein Zeitfenster aus, um einen Termin zu vereinbaren.',
    en: 'Choose only one slot to schedule consultation with property manager on'
  },
  CONSULTATIONS_USERNAME_MODAL_WARNING: {
    de: 'Bitte wählen Sie einen Nutzer aus der Liste aus.',
    en: 'Please select a user from the list'
  },
  CONSULTATIONS_MODAL_TEXT1: {
    de: 'Sprechstunde am',
    en: 'Consultation on'
  },
  CONSULTATIONS_MODAL_TEXT2: {
    de: 'um',
    en: 'at'
  },
  CONSULTATIONS_MODAL_SUBJECT: {
    de: 'Betreff',
    en: 'Subject'
  },
  CONSULTATIONS_MODAL_USER_PHONE: {
    de: 'Ihre Telefonnummer',
    en: 'Your phone number'
  },
  CONSULTATIONS_MODAL_USER_EMAIL: {
    de: 'Ihre E-Mail-Adresse',
    en: 'Your email address'
  },
  CONSULTATIONS_MODAL_WARNING: {
    de: 'Bitte geben Sie einen Betreff ein.',
    en: 'Please enter your subject'
  },
  CONSULTATIONS_NO_ADDITIONAL_INFO: {
    de: 'Keine zusätzlichen Informationen.',
    en: 'No additional info'
  },
  CONSULTATIONS_NO_CONSULTATIONS: {
    de: 'Derzeit sind für diesen Standort keine Mietersprechstunden verfügbar.',
    en: 'Currently there are no tenant consultations available for this building.'
  },
  CONSULTATIONS_NO_SCHEDULED: {
    de: 'Derzeit sind für diesen Standort keine Mietersprechstunden verfügbar.',
    en: 'Currently there are no tenant consultations scheduled for this building.'
  },
  CONSULTATIONS_PM_UNAVAILABLE: {
    de: 'Der ausgewählte Verwalter ist nicht verfügbar.',
    en: 'The property manager you selected is unavailable'
  },
  CONSULTATION_ALREADY_SCHEDULED_FOR_THIS_CONTRACT: {
    de: 'Sie haben bereits einen Termin unter dieser Vertragsnummer gebucht am',
    en: 'You already have a tenant consultation scheduled under this contract number tenant number on'
  },
  CONSULTATION_IS_TAKEN: {
    de: 'Bereits ausgebucht',
    en: 'Already scheduled.'
  },
  CONSULTATIONS_SCHEDULED_AT: { de: 'Geplant um', en: 'Scheduled at' },
  CONSULTATION_BLOCKED_TEXT_1: {
    de: 'Wir sind weiterhin jederzeit für Sie da – auf vielen digitalen Wegen, persönlich und in der Mietersprechstunde.',
    en: 'We are still here for you at any time - in many digital ways, in person and during tenant consultation hours.'
  },
  CONSULTATION_BLOCKED_TEXT_2: {
    de: 'Um Ihre Gesundheit und die Gesundheit unserer Mitarbeiter zu schützen, wird die persönliche Mietersprechstunde im Mieterbüro vor Ort ab 11. November 2021 vorerst wieder telefonisch über das zentrale Service-Center stattfinden.',
    en: 'To protect your health and the health of our employees, the personal tenant consultation hours in the tenant office will be conducted again by telephone via the central service center from November 11, 2021.'
  },
  CONSULTATION_BLOCKED_TEXT_3: {
    de: 'Auf unseren ausgezeichneten Mieterservice können Sie sich dabei wie immer verlassen. Kontaktieren Sie unsere Mitarbeiterinnen und Mitarbeiter im zentralen Service-Center jeden Tag rund um die Uhr – auch am Wochenende und an Feiertagen. Besprechen Sie Ihr Anliegen mit uns am Telefon oder teilen Sie uns mit, wann Sie am besten für eine Kontaktaufnahme erreichbar sind.',
    en: 'As always, you can rely on our excellent tenant service. Contact our staff at the service center around the clock, every day - even on weekends and holidays. Discuss your concerns with us on the phone or let us know the best time to contact you.'
  },
  CONSULTATION_BLOCKED_TEXT_4: {
    de: 'Nutzen Sie den Chat oder teilen Sie uns im Bereich Reparaturen & Anfragen Ihr Anliegen bequem und schnell mit.',
    en: ' Use the Chat or use the Repairs & Inquiries section in the app to let us know your concerns comfortably and fast.'
  },
  CONSULTATION_BLOCKED_TEXT_5: {
    de: 'Alle',
    en: 'All'
  },
  CONSULTATION_BLOCKED_TEXT_6: {
    de: 'Kontaktmöglichkeiten',
    en: 'contact possibilities'
  },
  CONSULTATION_BLOCKED_TEXT_7: {
    de: 'inklusive Kontaktformular finden Sie auch auf unserer Website.',
    en: 'including the contact form can be found on our website.'
  },
  CONSULTATION_BLOCKED_TEXT_8: {
    de: 'Unsere Mitarbeiter im zentralen Service-Center erreichen Sie unter der Rufnummer',
    en: 'You can reach our staff at the central service center by calling'
  },
  SCHEDULED_TEXT: {
    de: 'Sie haben erfolgreich einen Termin in der Mietersprechstunde gebucht. Wir freuen uns, Sie zu treffen.',
    en: 'You have successfully scheduled the tenant consultation with your Property manager. Looking forward to meeting you!'
  },
  SLOT_SUBTITLE: {
    en: 'Select time for tenant consultation on',
    de: 'Wählen Sie eine Uhrzeit für die Mietersprechstunde am'
  },
  TIME_PLACEHOLDER: { de: 'Zeitfenster wählen', en: 'Slot Selection' },

  REASON: { de: 'Grund für den Termin', en: 'Tenant consultation reason' },
  REASON_PLACEHOLDER: {
    de: 'Bitte nennen Sie uns Ihr Anliegen.',
    en: 'Please add your reason'
  },
  PROPERTY_MANAGERS: { de: 'Hausverwalter', en: 'Property managers' },
  PROERTY_MANAGER_NAME: { de: 'Name', en: 'Managers name' },
  PROERTY_MANAGER_EMAIL: { de: 'Email', en: 'Managers email' },
  SCHEDULED_AT: { de: 'Datum', en: 'Scheduled at' },
  SCHEDULED: { de: 'Geplant', en: 'Scheduled' },
  SCHEDULE: { de: 'Buchen', en: 'Schedule' },
  TIME: { de: 'Zeit', en: 'Time' },
  CONTRACT_TYPE_APARTMENT: { de: 'Wohnung', en: 'Apartment' },
  CONTRACT_TYPE_CELLAR: { de: 'Keller', en: 'Cellar' },
  CONTRACT_TYPE_COMMERCIAL_USE: { de: 'Gewerbe', en: 'Commercial use' },
  LOADING: { de: 'Lädt...', en: 'Loading...' },
  CONTRACT_TYPE_CONDOMINIUM: { de: 'Eigentumswohnung', en: 'Condominium' },
  CONTRACT_TYPE_GARAGE_OR_PARKING_SPACE: {
    de: 'Garage/Parkplatz',
    en: 'Garage / Car parking space'
  },
  ADD_CONTRACT_SUBTITLE: {
    de: 'Bitte geben Sie Ihre Vertragsdaten an.',
    en: 'Please enter your contract details.'
  },
  ADD_CONTRACT_SUBTITLE_ONBOARDING: {
    de: 'Damit Sie vollen Zugang zu allen digitalen GCP Services haben, benötigen wir abschließend noch Ihre Vertragsdaten zur Verifizierung.',
    en: 'In order to complete your registration and give you full access, we need your contract details for verification.'
  },
  CONTRACT_TYPE_PARKING_SPOT: { de: 'Parkplatz', en: 'Parking spot' },
  CONTRACT_TYPE_SPECIAL_AREA: { de: 'Besondere Nutzung', en: 'Special area' },
  CONTRACT_TYPE_STORAGE: { de: 'Lager', en: 'Storage' },
  DAMAGE_REPORT_CATEGORY: { de: 'Kategorie', en: 'Category' },
  DAMAGE_REPORT_DESCRIPTION: { de: 'Beschreibung', en: 'Description' },
  DAMAGE_REPORT_DESCRIPTION_PLACEHOLDER: {
    en: 'Please describe the issue in as much detail as possible e.g. when does the issue occur? How long has this been happening for?',
    de: 'Bitte beschreiben Sie das Problem so detailliert wie möglich, z. B. wann tritt das Problem auf? Wie lange besteht das Problem bereits?'
  },
  DAMAGE_REPORT_ON_ERROR: { de: 'Ein Fehler ist aufgetreten.', en: 'Error' },
  DAMAGE_REPORT_SUBJECT: { de: 'Thema', en: 'Subject' },
  DAMAGE_REPORT_TITLE: {
    de: 'Schadensmeldung',
    en: 'Damage Report'
  },
  DAMAGE_REPORT_SUCCESS: {
    de: 'Ihre Meldung wurde erfolgreich gesendet.',
    en: 'Your report has been sent successfully'
  },
  DAMAGE_REPORT_URGENT: { de: 'Dringend', en: 'Urgent' },
  DAMAGE_REPORT_EMERGENCY: { de: 'Notfall', en: 'Emergency!' },
  DAMAGE_REPORT_UPLOAD_IMAGE: { de: 'Bild hochladen', en: 'Upload image' },
  FEEDBACK_FOOTER_MESSAGE: {
    de: 'Ihre Meinung ist uns wichtig.',
    en: 'Your opinion is important to us'
  },
  INVALID_EMAIL_OR_PASSWORD: {
    de: 'Email oder Passwort sind nicht korrekt.',
    en: 'Email or password is incorrect.'
  },
  INVALID_EMAIL: {
    de: 'Dieser Benutzername ist nicht vergeben. Bitte registrieren Sie sich.',
    en: 'This username is not registered. Please register first.'
  },
  USER_INACTIVE: {
    de: 'Ihr Account wurde deaktiviert. Um sich erneut einzuloggen, wurde Ihnen eine E-Mail mit einem Aktivierungslink geschickt. Bitte nutzen Sie diesen Link und versuchen Sie es noch einmal.',
    en: 'Your account was deactivated. To reactivate, we have sent you an email with an activation link. Please click this link and try again.'
  },
  EMAIL_NOT_CONFIRMED: {
    de: 'Um sich einzuloggen, müssen Sie zuerst Ihr Benutzerkonto aktivieren. Sie finden den Aktivierungslink in der Registrierungs-E-Mail, die wir Ihnen gesendet haben.',
    en: 'To log in you first have to activate your account. You can find the activation link in the registration email we sent you.'
  },
  EMAIL_NOT_CONFIRMED_1: {
    de: 'Um sich einzuloggen, müssen Sie zuerst Ihr Benutzerkonto aktivieren. Sie finden den Aktivierungslink in der Registrierungs-E-Mail, die wir Ihnen gesendet haben. Klicken Sie ',
    en: 'To log in you first have to activate your account. You can find the activation link in the registration email we sent you. Click '
  },
  EMAIL_NOT_CONFIRMED_2: {
    de: 'hier',
    en: 'here'
  },
  EMAIL_NOT_CONFIRMED_3: {
    de: ', um den Aktivierungslink erneut zu senden.',
    en: ' to resend the activation link.'
  },
  RENT_DUE_UPDATE_OPTIN_FAIL: {
    de: 'Die Einstellungen konnten nicht aktualisiert werden.',
    en: 'Settings could not be updated.'
  },
  RENT_DUE_GET_OPTIN_FAIL: {
    de: 'Die Einstellungen konnten nicht abgerufen werden.',
    en: 'Unable to fetch rent reminder settings.'
  },
  FORM_CANCEL: { de: 'Abbrechen', en: 'Cancel' },
  FORM_SUBMIT: { de: 'Abschicken', en: 'Submit' },
  MANUAL_APPROVAL_SUBMIT: {
    de: 'Abschicken zur manuellen Überprüfung',
    en: 'Submit for manual approval'
  },
  SUBMITTING: { de: 'Wird gesendet', en: 'Submitting' },
  FORM_BACK: { de: 'Zurück', en: 'Back' },
  FORM_INCOMPLETE: { de: 'Eingaben unvollständig', en: 'Form incomplete' },
  FORM_SUCCESS: {
    de: 'Ihre Anfrage wurde erfolgreich gesendet.',
    en: 'Your request has been successfully sent'
  },
  FORM_PLACEHOLDER: {
    de: function (f) {
      return f + ' eingeben'
    },
    en: function (f) {
      return 'Enter ' + f.toLowerCase()
    }
  },
  FORM_SELECT_PLACEHOLDER: {
    de: function (f) {
      return f + ' wählen'
    },
    en: function (f) {
      return 'Select ' + f.toLowerCase()
    }
  },
  FORM_ADDRESS_SELECT_PLACEHOLDER: {
    de: function (f) {
      return f + ' eingeben'
    },
    en: function (f) {
      return 'Enter ' + f.toLowerCase()
    }
  },
  OPTION_GENERAL: { de: 'Allgemeines', en: 'General information' },
  OPTION_LEASE: { de: 'Vertragsangelegenheiten', en: 'Contractual matters' },
  OPTION_DEPOSIT: { de: 'Kaution', en: 'Deposit' },
  OPTION_OCC: { de: 'Betriebskosten', en: 'Operating costs' },
  OPTION_PUBLIC_AREA: { de: 'Öffentlicher Hausbereich', en: 'Public area' },
  OPTION_MAINTENANCE: { de: 'Instandhaltung', en: 'Maintenance' },
  OPTION_RENT_INCREASE: { de: 'Mieterhöhung', en: 'Rent increase' },
  OPTION_1_WEEK: { de: 'Jede Woche', en: 'Every week' },
  OPTION_2_WEEK: { de: 'Jede zweite Woche', en: 'Every second week' },
  LINK_EXPIRED: {
    de: 'Dieser Link ist nicht mehr gültig.',
    en: 'This link has expired'
  },
  LOGIN_PAGE_TENANT_NUMBER: { de: 'Vertragsnummer', en: 'Contract number' },
  LOGIN_PAGE_PASSWORD: { de: 'Passwort', en: 'Password' },
  LOGIN_PAGE_FORGOT_PASSWORD: {
    de: 'Passwort vergessen?',
    en: 'Forgot password?'
  },
  LOGIN_PAGE_REGISTRATION: { de: 'Log-in', en: 'Log-in' },
  LOGIN_PAGE_REGISTER: { de: 'Registrierung', en: 'Sign up' },
  PASSWORD_CHANGED_SUCCESS: {
    de: 'Ihr Passwort wurde aktualisiert.',
    en: 'Password updated'
  },
  PASSWORD_CHANGED: {
    de: 'Ihr Passwort wurde geändert.',
    en: 'Your password has been changed.'
  },
  LOGIN_PAGE_TITLE: {
    de: 'GCP Mieter-Service-Portal',
    en: 'GCP Tenant Service Portal'
  },
  EMAIL_NOT_FOUND: {
    de: 'Diese E-Mail-Adresse wurde nicht gefunden.',
    en: 'Email not found'
  },
  USER_NOT_FOUND: { de: 'Die eingegebene E-Mail-Adresse ist nicht registriert. Bitte versuchen Sie es erneut oder verwenden Sie mit eine gültigen E-Mail-Adresse.', en: 'The entered email address is not registered. Please try again or use a valid email address.' },
  MENU_CONSULTATIONS: { de: 'Mietersprechstunden', en: 'Consultations' },
  MENU_LETTERS: { de: 'Briefe', en: 'Letters' },
  FEEDBACK_THANK_YOU: {
    en: 'Thank you for your feedback',
    de: 'Vielen Dank für Ihr Feedback!'
  },
  NO_CONTRACTS: {
    en: "You haven't added any contract yet. Click here to add the contract",
    de: 'Sie haben noch keinen Vertrag hinzugefügt. Bitte klicken Sie hier, um einen Vertrag hinzuzufügen.'
  },
  MODAL_SUBJECT_ENTRY_TITLE: {
    de: 'Bitte nennen Sie das Thema, über das Sie sprechen möchten.',
    en: 'Please enter subject you would like to discuss about'
  },
  MODAL_SUBJECT_ENTRY_OKTEXT: { de: 'Buchen', en: 'Schedule' },
  MODAL_CONTRACT_ENTRY_TITLE: {
    de: 'Bitte geben Sie die unten aufgeführten Informationen ein.',
    en: 'Please enter info required below'
  },
  MODAL_CONTRACT_ENTRY_OPEN_MODAL_TEXT: {
    de: 'Neuen Vertrag hinzufügen',
    en: 'Add new contract'
  },
  ADD_A_CONTRACT_BUTTON: { de: 'Vertrag hinzufügen', en: 'Add a contract' },
  ADD_CONTRACT_BUTTON: { de: 'Vertrag hinzufügen', en: 'Add contract' },
  ADD_CONTRACT_AGAIN: {
    de: 'Bitte versuchen Sie noch einmal.',
    en: 'Try again'
  },
  PREPAYMENT_TITLE: { de: 'Betriebskostenvorauszahlung', en: 'Operational costs prepayment' },
  PREPAYMENT_I_REQUEST: { de: "Hiermit beantrage ich die Vorauszahlungen für den untenstehenden Vertrag wie folgt anzupassen:", en: "I hereby request to change the prepayment amounts for the following contract:" },
  PREPAYMENT_ADJUST_OPERATIONAL_COSTS: { de: "Betriebskosten anpassen", en: "Adjust operational costs" },
  PREPAYMENT_OPERATIONAL_COSTS: { de: "Betriebskosten", en: "Operational" },
  PREPAYMENT_ADJUST_HEATING_COSTS: { de: "Heizkosten anpassen", en: "Adjust heating costs" },
  PREPAYMENT_HEATING_COSTS: { de: "Heizkosten", en: "Heating" },
  PREPAYMENT_NOTE: { de: "Hinweis: Es ist nur möglich, die Vorauszahlung zu erhöhen. Wenn Sie die Vorauszahlung senken möchten, erstellen Sie bitte eine ", en: "Note: You are only allowed to increase the amount. If you want to decrease it, please create an " },
  PREPAYMENT_NOTE_LINK: {de: "Serviceanfrage", en: "inquiry"},
  PREPAYMENT_REQUEST_MADE_AT_1: { de: "Anfrage getätigt am", en: "Request made at" },
  PREPAYMENT_REQUEST_MADE_AT_2: { de: "in", en: "at" },
  PREPAYMENT_COST_TYPE: { de: "Kostenart", en: "Cost type" },
  PREPAYMENT_COST_AMOUNT: { de: "Höhe der Kosten", en: "Cost amount" },
  PREPAYMENT_VALID_FROM: { de: "Gültig ab", en: "Valid from" },
  PREPAYMENT_VALID_TO: { de: "Gültig bis", en: "Valid to" },
  PREPAYMENT_SUBMIT_SUCCESS: { de: "Sie haben Ihre Vorauszahlungsbeträge erfolgreich gespeichert.", en: "You have successfuly saved your prepayment amounts" },
  PREPAYMENT_FUTURE_COSTS: { de: "Hinweis: Dies sind alle zukünftigen Kosten, die mit Ihrem Vertrag verknüpft sind. Wenn Sie diese ändern möchten, erstellen Sie bitte eine ", en: "Note: These are all future costs associated with this contract, if you want to change them, please create an " },
  CURRENTLY: { de: "derzeit", en: "currently" },
  SAVE: { de: "Speichern", en: "Save" },
  RENT_ACCOUNT_BALANCE: { de: 'Saldo', en: 'Account balance' },
  CONTRACT_RENT_ACCOUNT_BALANCE: {de: 'Mietkontostand', en: 'Rent account balance'},
  CONTRACT_OVERVIEW_DOCUMENTS: {de: 'Mieterunterlagen', en: 'Tenant documents'},
  RENT_ACCOUNT_MESSAGE: { de: 'Bitte beachten Sie, dass eine getätigte Zahlung nicht sofort dargestellt wird.', en: 'Please note that your payment will not be displayed immediately.' },
  RENT_ACCOUNT_CREDIT: { de: 'Guthaben', en: 'credit' },
  RENT_ACCOUNT_RESIDUE: { de: 'Rückstand', en: 'residue' },
  RENT_ACCOUNT_DETAILS_CREDIT: { de: 'Haben', en: 'credit' },
  RENT_ACCOUNT_DETAILS_RESIDUE: { de: 'Soll', en: 'debit' },
  RENT_ACCOUNT_DETAILS_CLARIFICATION: { de: 'Klärung', en: 'clarification' },
  RENT_ACCOUNT_HISTORY_ERROR: {de: "Fehler beim Abrufen der Daten. Bitte versuchen Sie es später erneut.", en: 'Error retrieving data. Please try again later.'},
  RENT_ACCOUNT_HISTORY_EMPTY: {de: 'Keine Buchungen oder Zahlungen vorhanden.', en: 'No transactions or payments available.'},
  CONTRACT_OVERVIEW: {de: 'Vertragsübersicht' ,en: 'Contract overview'},
  RENT_ACCOUNT_START_DATE: {de: 'Mietvertragsbeginn', en: 'Rent account start date'},
  RENT_ACCOUNT_END_DATE: {de: 'Mietvertragsende', en: 'Rent account end date'},
  COLD_RENT: {de: 'Kaltmiete', en: 'Cold rent'},
  HEATING_COSTS: {de: 'Heizkosten', en: 'Heating cost' },
  OPERATIONAL_COSTS: {de: 'Betriebskosten', en: 'Operational cost '},
  TOTAL_COST: {de: 'Gesamtmiete', en: 'Total cost'},
  TENANTS_CONTRACT_OVERVIEW: {de: 'Vetragspartner', en: 'Tenant name(s)'},
  MY_RENT: {de: 'Meine Miete', en: 'My Rent'},
  PROFILE_TITLE: { de: 'PROFIL', en: 'PROFILE' },
  PROFILE_SUCCESS: {
    de: 'Ihr Profil wurde aktualisiert.',
    en: 'Your profil has been updated'
  },
  PROFILE_ERROR: {
    de: 'Fehler - Die Änderungen wurde nicht gespeichert.',
    en: 'Error - The changes have not been saved'
  },
  ERROR: {
    de: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
    en: 'An error occured. Please try again later.'
  },
  PAYMENT_ADJUST_ERROR: {
    de: 'Die angepassten Beträge können nur erhöht werden',
    en: 'Adjusted amounts are only allowed to be increased'
  },
  PROFILE_ERROR_GETTING: {
    de: 'Beim Abrufen des Profils ist ein Fehler aufgetreten.',
    en: 'When loading your profil an unknown error has occured, please try again'
  },
  PROFILE_CONTRACTS_TITLE: { de: 'Verträge', en: 'Contracts' },
  CONTRACT: { de: 'Vertrag', en: 'Contract' },
  CONTRACTS: { de: 'Verträge', en: 'Contracts' },
  PROFILE_CONTRACTS_SUBTITLE: {
    de: 'Es kann jeweils nur ein Vertrag zur Zeit aktiv sein.',
    en: 'Only one contract may be active at a time'
  },
  PROFILE_CONTRACTS_STATUS: { de: 'Status', en: 'Status' },
  PROFILE_CONTRACTS_STATUS_ACTIVE: { de: 'Aktiv', en: 'active' },
  PROFILE_CONTRACTS_STATUS_PENDING_SHORT: {
    de: 'Ausstehend',
    en: 'Pending'
  },
  PROFILE_CONTRACTS_STATUS_PENDING: {
    de: 'Ausstehend - Ihre Vertragsangaben werden derzeit geprüft.',
    en: 'Pending - your contract information is currently being validated'
  },
  PROFILE_CONTRACTS_STATUS_INACTIVE: { de: 'Abgelehnt', en: 'denied' },
  PROFILE_MODAL_ADD_NEW_CONTRACT_OKTEXT: { de: 'Hinzufügen', en: 'Add' },
  PROFILE_EDIT: { de: 'Profil ändern', en: 'Edit profile' },
  EMAIL_EDIT: { de: 'Email ändern', en: 'Edit email' },
  PASSWORD_EDIT: { de: 'Password ändern', en: 'Edit password' },
  PROFILE_SAVE: { de: 'Profil speichern', en: 'Save' },
  EMAIL_SAVE: { de: 'Email speichern', en: 'Save' },
  BACK_TO_PROFILE: { de: 'Zurück zum Profil', en: 'Back to profile' },
  USER_FIRST_NAME: { de: 'Vorname', en: 'First Name' },
  USER_LAST_NAME: { de: 'Nachname', en: 'Last Name' },
  USER_EMAIL: { de: 'E-Mail', en: 'Email' },
  USER_EMAIL_CONFIRM: { de: 'Email bestätigen', en: 'Email confirm' },
  NEW_USER_EMAIL: { de: 'Neue E-Mail', en: 'New email' },
  NEW_USER_EMAIL_CONFIRM: {
    de: 'Neue Email bestätigen',
    en: 'Confirm new email'
  },
  USER_OLD_PASSWORD: { de: 'Altes Passwort', en: 'Old password' },
  USER_PASSWORD: { de: 'Passwort', en: 'Password' },
  USER_PASSWORD_CONFIRM: {
    de: 'Passwort bestätigen',
    en: 'Confirm Password'
  },
  USER_PASSWORD_NEW: { de: 'Neues Passwort', en: 'New Password' },
  USER_PASSWORD_SAVE: { de: 'Password speichern', en: 'Save' },
  USER_DATE_OF_BIRTH: { de: 'Geburtsdatum', en: 'Date of birth' },
  USER_ADDRESS: { de: 'Adresse', en: 'Address' },
  USER_CONTRACT_TYPE: { de: 'Vertragstyp', en: 'Contract type' },
  USER_TENANT_NUMBER: { de: 'Vertragsnummer', en: 'Contract number' },
  USER_STREET_NAME: { de: 'Straße', en: 'Street Name' },
  USER_STREET_NUMBER: { de: 'Hausnummer', en: 'Street Number' },
  USER_ZIP: { de: 'PLZ', en: 'ZIP' },
  USER_CITY: { de: 'Stadt', en: 'City' },
  USER_CONTRACT: { de: 'Stadt', en: 'City' },
  USER_PHONE: { de: 'Telefon', en: 'Phone' },
  USER_MOBILE: { de: 'Handynummer', en: 'Mobile' },
  USER_SECONDARY_EMAIL: { de: 'Zweite E-Mail', en: 'Secondary email' },
  USER_PREFERRED_LANGUAGE: {
    de: 'Bevorzugte Sprache',
    en: 'Prefered language'
  },
  USER_ACCEPTED: { de: 'Bestätigt', en: 'Accepted' },
  USER_SUBSCRIBE: {
    de: 'Newsletter abonnieren',
    en: 'Newsletter subscribtion'
  },
  USER_WANT_NEWSLETTER: {
    de: 'Ich möchte den GCP Newsletter erhalten.',
    en: 'I would like to receive the GCP newsletter.'
  },
  CONTARCT_RENT_DUE_OPT_IN: {
    de: 'Ich möchte Erinnerungen an die fällige Miete bekommen.',
    en: 'I want to receive rent due date reminders.'
  },
  NEWSLETTER_POLICY_TEXT_1: {
    en: '*By entering your title, name and email address and by clicking the „Request Newsletter“ button, you agree vis-à-vis Grand City Property Ltd. Zweigniederlassung Deutschland that your data will be processed so that Grand City Property Ltd. Zweigniederlassung Deutschland will regularly inform you by email in the form of a newsletter about news concerning GCP, great tenant promotions and events. The newsletter is sent out in accordance with ',
    de: '*Mit der Eingabe Ihrer Anrede, Ihres Namens sowie Ihrer E-Mail-Adresse und dem Betätigen des Buttons „Newsletter abonnieren“ willigen Sie gegenüber der Grand City Property Ltd - Zweigniederlassung Deutschland ein, dass Ihre Daten verarbeitet werden, damit die Grand City Property Ltd - Zweigniederlassung Deutschland Sie regelmäßig im Rahmen eines Newsletters per E-Mail über Neuigkeiten rund um GCP, tolle Mieteraktionen und -events informiert. Der Newsletterversand erfolgt entsprechend '
  },
  NEWSLETTER_POLICY_TEXT_2: {
    en: '. Your consent is voluntary and can be revoked at any time with effect for the future.',
    de: '. Ihre Einwilligung erfolgt freiwillig und kann jederzeit mit Wirkung für die Zukunft widerrufen werden.'
  },
  NEWSLETTER_POLICY_LINK: {
    en: 'our data protection regulations',
    de: 'unserer Datenschutzbestimmungen'
  },
  DOWNLOAD_FILES: { de: 'Dateien herunterladen', en: 'Download Files' },
  REGISTRATION_THANK_YOU_TEXT: {
    de: '...für Ihre Anmeldung. Wir haben Ihnen eine E-Mail gesendet. Bitte bestätigen Sie Ihre Anmeldung über den Link in dieser E-Mail.',
    en: '... for your registration. We have sent you an email. Please confirm your registration using the link in this e-mail.'
  },
  REGISTRATION_BACK_TO_LOGIN: {
    de: 'Zurück zum Log-in',
    en: 'Back to Log-in'
  },
  CHANGE_EMAIL_THANK_YOU_TEXT: {
    de: 'Ihre E-Mail-Adresse wurde erfolgreich geändert!',
    en: 'Your email address has been changed successfuly!'
  },
  CHANGE_EMAIL_INVALID_HASH: {
    de: 'Invalid hash...',
    en: 'Invalid hash...'
  },
  REVOKE_EMAIL_THANK_YOU_TEXT: {
    de: 'Aktion wurde widerrufen.',
    en: 'Action has been revoked.'
  },
  REQUEST_CATEGORY: { de: 'Kategorie', en: 'Category' },
  FEEDBACK_TITLE: { de: 'Feedback', en: 'Feedback' },
  FEEDBACK_TYPE: { de: 'Feedback', en: 'Feedback' },
  FEEDBACK_TEXT: { de: 'Nachricht', en: 'Message' },
  FEEDBACK_CAN_PUBLISH: {
    de: 'Darf GCP Ihre Kommentare veröffentlichen?',
    en: 'Do you allow GCP to publish your feedback?'
  },
  WELCOME_TITLE: { de: 'Willkommen', en: 'Welcome' },
  SUBSCRIBING: { de: 'Anmelden...', en: 'Subscribing...' },
  SCHEDULED_CONSULTATIONS_STICKY_TITLE: {
    de: 'Mietersprechstunde geplant für',
    en: 'Tenant consultation scheduled for'
  },
  SCHEDULED_CONSULTATIONS_STICKY_AT: { de: 'um', en: 'at' },
  UNSUBSCRIBE: { de: 'Abmelden', en: 'Unsubscribe' },
  UNSUBSCRIBED: { de: 'Abgemeldet', en: 'Unsubscribed' },
  SUBSCRIBE: { de: 'Abonnieren', en: 'Subscribe' },
  THANK_YOU: { de: 'Vielen Dank!', en: 'Thank you!' },
  UNSUBSCRIBE_TXT: {
    de: 'Sie sind nun von unserem Newsletter abgemeldet.',
    en: 'You have unsubscribed from our newsletter.'
  },
  SUBSCRIBE_TXT: {
    de: 'Sie haben sich für unseren Newsletter angemeldet.',
    en: 'You have subscribed to our newsletter'
  },
  FORGOT_PASSWORD_SUCCESS_MSG: {
    de: 'Wir haben Ihnen eine E-Mail mit einem Link gesendet, um Ihr Passwort zurückzusetzen.',
    en: 'We have sent you an email with a link to reset your password.'
  },
  FILE_PAGES_DOWNLOAD_FILES: { de: 'Herunterladen', en: 'Download Files' },
  FILE_PAGES_DOWNLOAD_ITEM_1: {
    de: 'Betriebskostenvorauszahlung',
    en: 'Operation costs prepayment'
  },
  FILE_PAGES_DOWNLOAD_ITEM_2: { de: 'Datenfreigabeerklärung', en: 'Data release declaration' },
  FILE_PAGES_DOWNLOAD_ITEM_3: { de: 'Mietereinbauten', en: 'Approval of tenant installations' },
  FILE_PAGES_DOWNLOAD_ITEM_4: { de: 'Guthabenauszahlung', en: 'Credit outpayment' },
  FILE_PAGES_DOWNLOAD_ITEM_5: { de: 'Kautionsrückerstattung', en: 'Deposit outpayment' },
  FILE_PAGES_DOWNLOAD_ITEM_6: { de: 'Namensänderung', en: 'Name change' },
  FILE_PAGES_DOWNLOAD_ITEM_7: { de: 'Schlüsselnachbestellung', en: 'Key reorder' },
  FILE_PAGES_DOWNLOAD_ITEM_8: { de: 'Tierhaltung', en: 'Pet ownership' },
  FILE_PAGES_DOWNLOAD_ITEM_9: {
    de: 'Weiterer Vertragspartner',
    en: 'Additional contract partner'
  },
  PASSWORD_RESET_TEXT: {
    de: 'Geben Sie Ihr neues Passwort ein und bestätigen Sie die Eingabe.',
    en: 'Enter your new password and confirm it to update.'
  },
  PASSWORD_RESET_TITLE: { de: 'Passwort zurücksetzen', en: 'Password Reset' },
  PASSWORD_RESET_LINK: { de: 'Zurücksetzen', en: 'Reset' },
  FORGOT_PASSWORD_TITLE: { de: 'Passwort vergessen?', en: 'Forgot Password?' },
  FORGOT_PASSWORD_TEXT: {
    de: 'Geben Sie hier Ihre E-Mail-Adresse ein. Sie erhalten danach einen Link zur Eingabe eines neuen Passworts.',
    en: "Enter your email and we'll send you a password recovery link."
  },
  EMAIL_PLACEHOLDER: {
    de: 'z. B. max.mustermann@mail.com',
    en: 'E.g. doe.john@mail.com'
  },
  RETURN_TO_LOGIN: { de: 'Erneut anmelden', en: 'Back to login' },
  OPTIONS_GENERAL: { de: 'Allgemeines', en: 'General' },
  OPTIONS_LEASE: { de: 'Vertragsangelegenheiten', en: 'Contractual matters' },
  OPTIONS_DEPOSIT: { de: 'Kaution', en: 'Deposit' },
  OPTIONS_OCC: { de: 'Betriebskosten', en: 'Operating cost' },
  OPTIONS_PUBLIC_AREA: { de: 'Öffentliche Hausbereiche', en: 'Public areas' },
  OPTIONS_MAINTENANCE: { de: 'Instandhaltung', en: 'Maintenance' },
  OPTIONS_RENT_INCREASE: { de: 'Mieterhöhungen', en: 'Rent increases' },
  USER_EXISTS: {
    en: 'This email address is already in use or you may already have an account associated with this email address. Please try again or sign in using your existing email address.',
    de: 'Diese E-Mail-Adresse wird bereits verwendet oder Sie verfügen möglicherweise bereits über ein Konto mit dieser E-Mail-Adresse. Bitte versuchen Sie es erneut oder melden Sie sich mit einer alternativen E-Mail-Adresse an.'
  },
  ERROR_INVALID_CONTRACT: {
    de: 'Die angegebene Verwaltungseinheit ist ungültig. Bitte überprüfen Sie die Nummer und versuchen Sie es erneut.',
    en: 'Contract number is invalid. Please check it and try again.'
  },

  CONTRACT_INFO: {
    en: 'Please find this number in your contract and make sure you retype it correctly (the format has to be 0000/MV20000000 for GCP tenants and W000/W000-000-00 for condominium).',
    de: 'Sie finden diese Nummer in Ihrem Vertrag. Bitte stellen Sie sicher, dass Sie die Nummer richtig eingeben (das Format ist 0000/MV20000000 für GCP Mieter und W000/W000-000-00 für WEG-Eigentümer).'
  },
  CONTRACT_VERIFIED: {
    de: 'bestätigt',
    en: 'verified'
  },
  RENT_OVERDUE: {
    de: 'Überfällig',
    en: 'Overdue'
  },
  CONTRACT_ALREADY_VALIDATED: {
    de: 'Dieser Vertrag wurde bereits bestätigt.',
    en: 'This contract has already been approved.'
  },
  INVALID_CONTRACT: {
    de: 'Wir konnten Ihre Verwaltungseinheit nicht identifizieren. Bitte versuchen Sie es erneut oder reichen Sie die Nummer zur manuellen Überprüfung ein. Unsere Mitarbeiter sind gerne für Sie da und werden Ihr Benutzerkonto entsprechend aktivieren.',
    en: 'We were not able to identify your contract number. Please try again or just submit for manual approval. Our staff will check it out and activate your account.'
  },
  INAVALID_CONTRACT_ENERGY_REGISTER: {
    de: 'Wir konnten Ihre Verwaltungseinheit nicht identifizieren.',
    en: 'We were not able to identify your contract number.'
  },
  CONTRACT_EXISTS: {
    de: 'Dieser Vertrag wurde bereits hinzugefügt.',
    en: 'This contract has already been added.'
  },
  BACK_TO_CONTRACTS: {
    de: 'Zurück zu Verträgen',
    en: 'Back to contracts'
  },
  INVALID_PARTNER_CATEGORY_ID: {
    de: 'Invalid partner category ID.',
    en: 'Invalid partner category ID.'
  },
  INVALID_PARTNER_ID: {
    de: 'Invalid partner ID.',
    en: 'Invalid partner ID.'
  },
  INVALID_FAQ_QUESTION_ID: {
    de: 'Invalid FAQ question ID.',
    en: 'Invalid FAQ question ID.'
  },
  MAIN_FAQ: {
    de: 'FAQ',
    en: 'FAQ'
  },
  MAIN_FAQ_TEXT: {
    de: 'Hier finden Sie die wichtigsten Infos zur GCP Service-App.',
    en: 'Here you will find the most important information about the GCP service app.'
  },
  MAIN_FAQ_CONTACT_US_1: {
    de: 'Wenn Ihnen diese Antwort nicht weitergeholfen hat, nehmen Sie bitte mit uns',
    en: 'If this answer was not helpful, please feel free to'
  },
  MAIN_FAQ_CONTACT_US_2: {
    de: ' Kontakt ',
    en: ' contact '
  },
  MAIN_FAQ_CONTACT_US_3: {
    de: 'auf.',
    en: 'us.'
  },
  MAIN_FAQ_SUBMIT: {
    de: 'Senden',
    en: 'Send'
  },
  LIMIT_EXCEEDED: {
    de: 'Ihre Anfragen werden derzeit verarbeitet. Wenn Sie weitere Anfragen stellen möchten, versuchen Sie es später erneut.',
    en: 'Your requests are currently being processed. If you would like to make additional requests, please try again later.'
  },
  MAIN_FAQ_FEEDBACK_TEXT: {
    de: 'Wenn Sie Fragen oder Verbesserungsvorschläge haben, senden Sie uns diese gerne zu.',
    en: 'If you have any questions or suggestions for improvement, please send them to us.'
  },
  BACK_TO_FAQ: { de: 'Zurück zum FAQ', en: 'Back to FAQ' },
  EMAIL_IN_USE: { de: 'E-Mail bereits verwendet', en: 'E-mail already in use' },
  VALIDATE_ADDRESS: {
    de: function (f) {
      return 'Bitte wählen Sie eine Adresse aus der Dropdown-Liste.'
    },
    en: function (f) {
      return 'Please select an address from the dropdown'
    }
  },
  VALIDATE_REQUIRED: {
    de: function (f) {
      return 'Bitte füllen sie die ' + f + ' aus.'
    },
    en: function (f) {
      return 'Please enter ' + f
    }
  },
  VALIDATE_FORM_FIELD: {
    de: function (f) {
      return 'Das ' + f + ' muss ausgefüllt sein'
    },
    en: function (f) {
      return 'The ' + f + ' field is required'
    }
  },
  VALIDATE_NAME: {
    de: function (f) {
      return 'Das Vorname Feld muss zwischen 1 und 32 liegen.'
    },
    en: function (f) {
      return 'The Last name field must be between 1 and 32 characters.'
    }
  },
  VALIDATE_LASTNAME: {
    de: function (f) {
      return 'Das Nachname Feld muss zwischen 1 und 32 liegen.'
    },
    en: function (f) {
      return 'The Last name field must be between 1 and 32 characters.'
    }
  },
  IS_REQUIRED: {
    de: ' wird benötigt.',
    en: ' is required.'
  },
  VALIDATE_EMPTY_MIN_ONE_LOWER_CASE_LETTER: {
    de: 'Das password muss mindestens einen Kleinbuchstaben enthalten.',
    en: 'Password must contain at least one lower case letter.'
  },
  VALIDATE_EMPTY_MIN_ONE_CAPITAL_LETTER: {
    de: 'Das password muss mindestens ein Großbuchstaben enthalten.',
    en: 'Password must contain at least one capital letter.'
  },
  VALIDATE_EMPTY_MIN_ONE_NUMBER: {
    de: 'Das password muss mindestens eine Ziffer enthalten.',
    en: 'Password must contain at least one number.'
  },
  VALIDATE_EMPTY_MIN_ONE_SPECIAL_CHARACTER: {
    de: 'Das password muss mindestens ein Sonderzeichen enthalten',
    en: 'Password must contain at least one special character.'
  },
  VALIDATE_EMPTY_PASSWORD: {
    de: function (f) {
      return 'Das Password Feld muss mindestens 10 Zeichen lang sein.'
    },
    en: function (f) {
      return 'The Password must be at lest 10 characters'
    }
  },
  VALIDATE_MIN_LENGTH: {
    de: function (f, len) {
      return (
        'Das ' + f + ' Feld muss mindestens ' + len + ' Zeichen lang sein.'
      )
    },
    en: function (f, len) {
      return f + ' lenght should be at least ' + len + ' characters long'
    }
  },
  VALIDATE_MAX_LENGTH: {
    de: function (f, len) {
      return f + ' darf max.  ' + len + ' Zeichen lang sein.'
    },
    en: function (f, len) {
      return f + ' lenght should be at most ' + len + ' characters long'
    }
  },
  VALIDATE_BETWEEN_LENGTH: {
    de: function (f, min, max) {
      return (
        'Das ' + f + ' Feld muss zwischen ' + min + ' und ' + max + ' liegen.'
      )
    },
    en: function (f, min, max) {
      return 'The ' + f + ' field must be between ' + min + ' and ' + max + ' characters.'
    }
  },
  VALIDATE_EMAIL: {
    de: function (f) {
      return 'Das E-Mail Format ist ungültig.'
    },
    en: function (f) {
      return 'The Email format is invalid'
    }
  },
  VALIDATE_EMAIL_CONFIRM: {
    de: function (f) {
      return 'Bitte geben Sie Ihre E-Mail-Adresse erneut ein.'
    },
    en: function (f) {
      return 'Please enter your email address again.'
    }
  },
  VALIDATE_PHONE: {
    de: function (f) {
      return 'Bitte geben Sie eine gültige Telefonnummer ein.'
    },
    en: function (f) {
      return 'Please enter valid phone number'
    }
  },
  VALIDATE_CONTRACT_NUMBER: {
    de: 'Das Vertragsnummer Format ist ungültig.',
    en: 'The Contract Number is invalid.'
  },
  VALIDATE_DATE: {
    de: 'Ungültiges Datumsformat',
    en: 'Invalid date format'
  },
  VALIDATE_PASSWORD: {
    de: function (f) {
      return 'Das Passwort muss dem bestätigten Passwort entsprechen.'
    },
    en: function (f) {
      return f + ' must match confirm password'
    }
  },
  VALIDATE_EXISTING_EMAIL: {
    de: function (f) {
      return 'Zu dieser E-Mail-Adresse gibt es bereits ein Nutzerkonto.'
    },
    en: function (f, val) {
      return 'User with email' + val + ' already exists'
    }
  },
  VALIDATE_EMAIL_CHANGE_CONFIRM: {
    en: 'The Email confirmation does not match.',
    de: 'Die beiden Email Adressen stimmen nicht überein.'
  },
  OPERATING_COST_PREPAYMENT: {
    de: 'Betriebskostenvorauszahlung', en: 'Operation costs prepayment'
  },
  OPERATING_COST_PREPAYMENT_HEAD_ONE_A: {
    de: 'Meine Betriebskostenvorauszahlung soll von aktuell', en: 'My current operation cost prepayment should be raised from actual'
  },
  OPERATING_COST_PREPAYMENT_HEAD_ONE_B: {
    de: 'auf', en: 'to the amount of'
  },
  OPERATING_COST_PREPAYMENT_HEAD_ONE_C: {
    de: 'erhöht werden.', en: '.'
  },
  OPERATING_COST_PREPAYMENT_HEAD_TWO_A: {
    de: 'Meine Heizkostenvorauszahlung soll von aktuell', en: 'My current heating cost prepayment should be raised from actual '
  },
  OPERATING_COST_PREPAYMENT_HEAD_THREE_A: {
    de: 'Die angepasste Mietzahlung leiste ich ab', en: 'I will pay the adjusted rent from'
  },
  OPERATING_COST_PREPAYMENT_HEAD_THREE_B: {
    de: 'auf das mir bekannte Konto. ', en: 'to the account known to me.'
  },
  OPERATING_COST_PREPAYMENT_HEAD_THREE: {
    de: 'Die Mietzahlung leiste ich ab:', en: 'I will pay the rent:'
  },
  DIGITAL_CONTRACT_FORM_SEND: {
    de: 'Absenden', en: 'Send'
  },
  DIGITAL_CONTRACT_FORM_CANCEL: {
    de: 'Abbrechen', en: 'Cancel'
  },
  DIGITAL_CONTRACT_FORM_TENANT_NUMBER: {
    de: 'Vertragsnummer', en: 'Contract number'
  },
  DIGITAL_CONTRACT_FORM_TERMINATION_DATE: {
    de: 'Datum Beendigung Mietverhältnis', en: 'Termination date'
  },
  DIGITAL_CONTRACT_FORM_APARTMENT_HANDOVER_DATE: {
    de: 'Datum der Wohnungsübergabe', en: 'Apartment handover date'
  },
  DIGITAL_CONTRACT_FORM_DEPOSIT_AMOUNT: {
    de: 'Kautionsbetrag', en: 'Deposit amount'
  },
  DIGITAL_CONTRACT_FORM_ACCOUNT_HOLDER: {
    de: 'Kontoinhaber', en: 'Account holder'
  },
  DIGITAL_CONTRACT_FORM_IBAN: {
    de: 'IBAN', en: 'IBAN'
  },
  DIGITAL_CONTRACT_FORM_BIC: {
    de: 'BIC', en: 'BIC'
  },
  DIGITAL_CONTRACT_FORM_ON: {
    de: '€ Aktuell', en: '€ Actual'
  },
  DIGITAL_CONTRACT_FORM_RAISE: {
    de: '€ Zukünftig', en: '€ Future'
  },
  OTHER_CONTRACT_PARTNER: {
    de: 'Weiterer Vertragspartner', en: 'Additional contract partner'
  },
  OTHER_CONTRACT_PARTNER_HEAD_ONE: {
    de: 'Hiermit bitte ich:', en: 'I'
  },
  OTHER_CONTRACT_PARTNER_HEAD_TWO: {
    de: 'Folgende Person in den Mietvertrag aufzunehmen:', en: 'hereby request, that the following person should be added to my rent contract:'
  },
  DATA_RELEASE: {
    de: 'Datenfreigabeerklärung', en: 'Data release declaration'
  },
  DATA_RELEASE_HEAD_ONE: {
    de: 'Hiermit erteile ich', en: 'I hereby authorize'
  },
  DATA_RELEASE_PARA_ONE: {
    de: 'die Erlaubnis zur Datenfreigabe gegenüber der Grand City Property Ltd. Zweigniederlassung Deutschland.', en: 'for the release of data to Grand City Property Ltd. Branch Office Germany.'
  },
  DATA_RELEASE_PARA_TWO: {
    de: 'Die Grand City Property Ltd. Zweigniederlassung Deutschland ist berechtigt Mietrückstände mit dem zuständigen Amt zu klären, insbesondere Betriebs- und Heizkostenabrechnungen, sowie jede Änderung der Miethöhe in meinem Namen einzureichen.', en: 'The Grand City Property Ltd. branch office in Germany is entitled to clarify rent arreas with the responsible office, in particular to submit statements of operation and heating costs, as well as any change in the amount of rent in my name.'
  },
  DEPOSIT: {
    de: 'Formular zur Kautionsrückerstattung', en: 'Deposit outpayment'
  },
  DEPOSIT_HEAD_ONE: {
    de: 'Daten des Mieters:', en: 'Tenant information:'
  },
  DEPOSIT_HEAD_TWO: {
    de: 'Daten des Mietverhältnisses:', en: 'Lease contract information:'
  },
  DEPOSIT_HEAD_THREE: {
    de: 'Ihre Bankverbindung:', en: 'Your bank account details:'
  },
  DEPOSIT_PARA_ONE: {
    de: 'Die personenbezogenen Daten (Name, Vorname, Adresse, Telefonnummer, Adresse des Mietobjektes, Mieternummer, Kontoinformationen), die Sie uns im Rahmen des Formulars zur Rückerstattung der Kaution zur Verfügung stellen, werden nur für diesen Zweck (Kautionsrückerstattung) verwendet. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. b DSGVO. Im Anschluss werden die Daten gelöscht, sofern nichteine gesetzliche Aufbewahrungspflicht besteht. Auf Ihre Rechte hinsichtlich Ihrer personenbezogenen Daten aus der Datenschutzgrundverordnung (insbesondere Zugang, Auskunft, Berichtigung oder Löschung, Einschränkung der Verarbeitung, Anrufung der Datenschutzbehörde) weisen wir hin. Beiweiteren Fragen zur Datenverarbeitung und zum Datenschutz steht Ihnen unser Datenschutzbeauftragter unter datenschutzbeauftragter@grandcityproperty.de gern zur Verfügung.', en: 'The personal data (name, first name, address, telephone number, address of the rental property,tenant number, account information) that you provide to us as part of the form for the refund of the deposit refund form will only be used for this purpose (deposit refund).The legal basis is Art. 6 para. 1 p. 1 lit. b DSGVO. The data will then be deleted, unless there is no legal obligation to retain the data. Your rights with regard to your personal data arising from the General Data Protection Regulation (in particular access, information, correction or deletion, restriction of processing, appeal to the data protection authority). For If you have any further questions regarding data processing and data protection, please contact our data protection officer at: datenschutzbeauftragter@grandcityproperty.de.'
  },
  NAME_CHANGE: {
    de: 'Namensänderung', en: 'Name change'
  },
  NAME_CHANGE_HEAD_ONE: {
    de: 'Hiermit bitte ich:', en: 'I hereby ask:'
  },
  NAME_CHANGE_HEAD_TWO: {
    de: 'Meinen Namen wie folgt zu ändern:', en: 'Change my name to'
  },
  NAME_CHANGE_PARA_ONE: {
    de: '*Bitte legen Sie vor Ort das Originaldokument zur Namensänderung vor (Ausweis, Eheurkunde etc.). Kopien werden nicht entgegengenommen.', en: '*Please present the original document for the name change on site (ID, marriage certificate, etc.). Copies will not be accepted.'
  },
  CREDIT: {
    de: 'Guthabenauszahlung', en: 'Credit outpayment'
  },
  CREDIT_HEAD_ONE: {
    de: 'Hiermit bitte ich um Auszahlung von meinem Guthaben:', en: 'I hereby request payment of my credit:'
  },
  KEEP_ANIMALS: {
    de: 'Anfrage zur Tierhaltung', en: 'Inquiry about pet ownership'
  },
  KEEP_ANIMALS_HEAD_ONE: {
    de: 'Hiermit bitte ich um Genehmigung zur Tierhaltung in der Wohnung:', en: 'I hereby ask for permission to keep animals in the apartment:'
  },
  KEEP_ANIMALS_PARA_ONE: {
    de: '*bei Fischen bitte die Aquarien mit Volumen in Litern angeben.', en: '*for fish, please indicate the aquariums with volume in litres.'
  },
  KEEP_ANIMALS_PARA_TWO: {
    de: 'Folgende benötigte Unterlagen werden nach Erteilung der Genehmigung nachgereicht: Hundehaftpflichtversicherung, Hundeführerschein (Rassenbedingt), Steueranmeldung.', en: 'The following required documents will be submitted after the permit has been issued: Dog liability insurance, dog license (breed-related), tax declaration.'
  },
  KEY: {
    de: 'Schlüsselnachbestellung', en: 'Key reorder'
  },
  KEY_HEAD_ONE: {
    de: 'Um die Genehmigung zur Nachbestellung folgender Schlüssel:', en: 'For approval to reorder following keys:'
  },
  KEY_HEAD_A: {
    de: 'Zentralschlüssel', en: 'Central key'
  },
  KEY_HEAD_B: {
    de: 'Haustürschlüssel', en: 'Front door key'
  },
  KEY_HEAD_C: {
    de: 'Wohnungsschlüssel', en: 'Apartment key'
  },
  KEY_HEAD_D: {
    de: 'Briefkastenschlüssel', en: 'Letter box key'
  },
  KEY_HEAD_E: {
    de: 'Zimmerschlüssel', en: 'Room key'
  },
  KEY_HEAD_F: {
    de: 'Garage', en: 'Garage'
  },
  KEY_HEAD_G: {
    de: 'Zusätzlicher Schlüssel', en: 'Additional key'
  },
  KEY_PARA_ONE: {
    de: 'Gleichzeitig bestätigen wir die Anfertigung der Schlüssel auf unsere eigenen Kosten durchführen zu lassen.', en: 'At the same time, we confirm that we will have the keys made at our own expense.'
  },
  KEY_ADD_NEW: {
    de: 'Neuen Schlüsseltyp hinzufügen', en: 'Add new type of key'
  },
  KEY_FORM_ERROR: {
    de: 'Sie müssen mindenstens einen schlüssel auswählen', en: 'You need to select minimum one key'
  },
  FIXTURES: {
    de: 'Mietereinbauten', en: 'Approval of tenant installations'
  },
  FIXTURES_HEAD_ONE: {
    de: 'Hiermit bitte ich um Genehmigung zum Durchführen folgender Maßnahmen in der Wohnung:', en: 'I hereby ask for permission to carry out the following tenant installations in the apartment'
  },
  FIXTURES_HEAD_TWO: {
    de: 'Folgende Unterlagen sind dem Schreiben beigefügt:', en: 'The following documents are attached to the letter attached:'
  },
  FIXTURES_PARA_ONE: {
    de: 'Bei Beendigung des Mietverhältnisses werden auf Verlangen des Eigentümers, die durchgeführten Maßnahmen zu meinen Lasten rückgängig gemacht. Gleichzeitig bestätige ich, für sämtliche Schäden die durch diese Maßnahmen entstehen, aufzukommen. Dies betrifft insbesondere Schadensersatzansprüche nach §536 bis §536c BGB sowie nach §823 und §836 BGB.', en: 'Upon termination of the rent contract, the measures carried out will be reversed at my expense at the owner\'s request. At the same time, I confirm that I will pay for all damages caused by these measures. This concerns in particular Claims for damages according to §536 to §536c BGB as well as according to §823 and §836 BGB.'
  },
  DIGITAL_CONTRACT_FORM_COST_ESTIMATE: {
    de: 'Kostenvoranschlag', en: 'Cost estimate'
  },
  DIGITAL_CONTRACT_FORM_DETAILED_DRAWING: {
    de: 'Detaillierte Zeichnung der geplanten Änderung', en: 'Detailed drawing of the proposed change'
  },
  DIGITAL_CONTRACT_FORM_DESCRIPTION_OF_THE_MEASURE: {
    de: 'Beschreibung der Maßnahme', en: 'Improvement description'
  },
  DIGITAL_CONTRACT_FORM_DESIGNATION: {
    de: 'Bezeichnung / Art', en: 'Description / kind'
  },
  DIGITAL_CONTRACT_DESCRIPTION_DESIGNATION: {
    de: 'z.B. SAT - Anlage, Markise, Katzengitter, Einbau Wannen', en: 'e.g. satellite system, awning, cat gates, built-in tubs)'
  },
  DIGITAL_CONTRACT_FORM_SCOPE: {
    de: 'Umfang', en: 'Scope'
  },
  DIGITAL_CONTRACT_DESCRIPTION_SCOPE: {
    de: 'z.B. Bohrungen, Entfernung Duschkabine', en: 'e.g. drilling, removal of shower cubicle'
  },
  DIGITAL_CONTRACT_FORM_PLACE_OF_CONVERSION: {
    de: 'Ort des Umbaus', en: 'Place of modification'
  },
  DIGITAL_CONTRACT_DESCRIPTION_PLACE_OF_CONVERSION: {
    de: 'z.B. Fassade Balkon, Bad', en: 'e.g. facade balcony, bathroom'
  },
  DIGITAL_CONTRACT_FORM_SPECIALIST_COMPANY: {
    de: 'Fachfirma', en: 'Specialist company'
  },
  DIGITAL_CONTRACT_DESCRIPTION_SPECIALIST_COMPANY: {
    de: 'bei Umfangreichen Arbeiten die in den Bestand des Objektes gehen, Verlegung Stromleitungen', en: 'for extensive work in the Inventory of the object go, relocation power lines'
  },
  DIGITAL_CONTRACT_FORM_KEY_NUMBER: {
    de: 'Schlüsselnummer', en: 'Key number'
  },
  DIGITAL_CONTRACT_FORM_MANUFACTURER: {
    de: 'Hersteller', en: 'Manufacturer'
  },
  DIGITAL_CONTRACT_FORM_ANIMAL_SPECIES: {
    de: 'Tierart (z.B. Hund, Katze, Fische…)', en: 'Animal species (e.g. dog, cat, fish...)'
  },
  DIGITAL_CONTRACT_FORM_RACE: {
    de: 'Rasse', en: 'Breed'
  },
  DIGITAL_CONTRACT_DESCRIPTION_WEIGHT: {
    de: 'Beschreibung/Gewicht', en: 'Description/Weight'
  },
  DIGITAL_CONTRACT_DESCRIPTION_QUANTITY: {
    de: 'Anzahl', en: 'Quantity'
  },
  DIGITAL_CONTRACT_FORM_REORDER_REASON: {
    de: 'Grund für die Nachbestellung', en: 'Reorder reason'
  },
  DIGITAL_CONTRACT_DESCRIPTION_ALREADY_OWN_PET: {
    de: 'Ich/wir besitzen bereits ein Haustier?', en: 'I/we already own one Pet?'
  },
  DIGITAL_CONTRACT_DESCRIPTION_ALREADY_OWN_PET_HEAD: {
    de: 'ja, Art und Anzahl / nein', en: 'yes, type and number / no'
  },
  DIGITAL_CONTRACT_ANIMAL_TYPE: {
    de: 'Art', en: 'Type'
  },
  DIGITAL_CONTRACT_ANIMAL_NUMBER: {
    de: 'Anzahl', en: 'Number'
  },
  DIGITAL_CONTRACT_DESCRIPTION_RELATIONSHIP: {
    de: 'z.B. Lebenspartner, Onkel...', en: 'e.g. partner, uncle...'
  },
  DIGITAL_CONTRACT_FORM_ACCOUNT_OWNER: {
    de: 'Kontoinhaber', en: 'Account owner'
  },
  DIGITAL_CONTRACT_FORM_REASON: {
    de: 'Begründung', en: 'Reason'
  },
  DIGITAL_CONTRACT_FORM_APARTMENT_ADDRESS: {
    de: 'Adresse der Wohnung', en: 'Apartment address'
  },
  DIGITAL_CONTRACT_FORM_FILE_NUMBER: {
    de: 'BG-Nummer (Aktenzeichen)', en: 'BG-number (file number)'
  },
  DIGITAL_CONTRACT_FORM_RESPONSIBLE_OFFICE: {
    de: 'Dem zuständigen Amt', en: 'The responsible office'
  },
  DIGITAL_CONTRACT_FORM_CONTACT_PERSON: {
    de: 'Ansprechpartner in: ', en: 'Contact person:'
  },
  DIGITAL_CONTRACT_FORM_FULL_NAME: {
    de: 'Vorname und Nachname', en: 'Full name'
  },
  DIGITAL_CONTRACT_FORM_FIRST_NAME: {
    de: 'Vorname', en: 'First name'
  },
  DIGITAL_CONTRACT_FORM_LAST_NAME: {
    de: 'Nachname', en: 'Last name'
  },
  DIGITAL_CONTRACT_FORM_KIND_OF_RELATIONSHIP: {
    de: 'Art der Verwandschaft', en: 'Kind of relation'
  },
  DIGITAL_CONTRACT_FORM_DATE_OF_BIRTH: {
    de: 'Geburtsdatum', en: 'Date of birth'
  },
  DIGITAL_CONTRACT_FORM_ADDRESS: {
    de: 'Adresse', en: 'Address'
  },
  DIGITAL_CONTRACT_FORM_PREVIOUS_ADDRESS: {
    de: 'Bisherige Anschrift', en: 'Previous address'
  },
  DIGITAL_CONTRACT_FORM_CURRENT_ADDRESS: {
    de: 'Aktuelle Adresse', en: 'Current address'
  },
  DIGITAL_CONTRACT_FORM_POSTCODE: {
    de: 'PLZ', en: 'Postcode'
  },
  DIGITAL_CONTRACT_FORM_TOWN: {
    de: 'Ort', en: 'Town'
  },
  DIGITAL_CONTRACT_FORM_PHONE_NUMBER: {
    de: 'Telefonnummer', en: ' Phone number'
  },
  DIGITAL_CONTRACT_FORM_PLACE_DATE: {
    de: 'Ort, Datum', en: 'Place, Date'
  },
  DIGITAL_CONTRACT_FORM_PLACE: {
    de: 'Ort', en: 'Place'
  },
  DIGITAL_CONTRACT_FORM_DATE: {
    de: 'Datum', en: 'Date'
  },
  BACK_TO_DOCUMENTS: {
    de: 'Zurück zu Dokumenten',
    en: 'Back to Documents'
  },
  SIGN_DOCUMENT_SUCCESS: {
    de: 'Sie haben das Dokument erfolgreich unterschrieben',
    en: 'You have successfully signed the document'
  },
  OPTIONS: {
    GENERAL: 'Allgemeines',
    LEASE: 'Vertragsangelegenheiten',
    DEPOSIT: 'Kaution',
    OCC: 'Betriebskosten',
    PUBLIC_AREA: 'Öffentliche Hausbereiche',
    MAINTENANCE: 'Instandhaltung',
    RENT_INCREASE: 'Mieterhöhungen',
    '1_WEEK': 'Every week',
    '2_WEEK': 'Every second week'
  },
  FEEDBACK: {
    TITLE: 'Feedback',
    FEEDBACK_TYPE: 'Feedback Art',
    FEEDBACK_TEXT: 'Feedback Nachricht',
    CAN_PUBLISH: 'Darf GCP Ihre Kommentare veröffentlichen?'
  },
  FORGOT_PASSWORD: {
    SUCCESS_MSG:
      'Wir haben Ihnen eine E-Mail mit einem Link gesendet, um Ihr Passwort zurückzusetzen.',
    RESET_LINK: 'Passwort zurücksetzen',
    TITLE: 'Passwort zurücksetzen',
    RETURN_TO_LOGIN: 'Erneut anmelden'
  },
  PARTNER_NAME: { de: 'Name', en: 'Name' },
  ADMIN_FULL_NAME: { de: 'Nachname, Vorname', en: 'Surname, Forename' },
  ADMIN_CONTRACT_NUMBER: { de: 'Vertragsnummer', en: 'Contract Number' },
  CREATED_AT: { de: 'Erstellt um', en: 'Created at' },
  NOT_FOUND: {
    TEXT: '404 Seite nicht gefunden',
    BACK: 'Zurück zur Startseite'
  },
  TERMS_AND_CONDITIONS_1: {
    en: 'We have updated our data privacy policy to reflect new the European Union data protection legislation: Please read',
    de: 'Wir haben unsere Datenschutzbestimmungen aktualisiert, um neue gesetzliche Datenschutzregelungen der Europäischen Union zu berücksichtigen: Bitte lesen Sie'
  },
  TERMS_AND_CONDITIONS_2: {
    en: 'our Privacy Policy',
    de: 'unsere Datenschutzerklärung'
  },
  CONTRACTS_PENDING: {
    en: "Your contract information has been submitted for manual approval by our staff. You'll be updated via email as soon as it's done.",
    de: 'Ihre Registrierungsinformationen wurden zur manuellen Überprüfung versandt. Sie werden per E-Mail informiert, sobald die Überprüfung abgeschlossen ist.'
  },
  TERMS_AND_CONDITIONS_3: {
    en: '. By tapping the „Register“ button you declare that you have read the privacy policy.',
    de: '. Mit dem Tippen auf den Button „Anmelden“ erklären Sie, dass Sie die Datenschutzbestimmungen zur Kenntnis genommen haben.'
  },
  PRIVACY_POLICY_REQUIRED: {
    en: 'The Privacy Policy field is required',
    de: 'Das Feld zur Datenschutzerklärung muss ausgefüllt sein.'
  },
  INBOX_TITLE: {
    en: 'INBOX & ALERTS',
    de: 'NEUE NACHRICHTEN'
  },
  ALERTS_GENERAL_TITLE: {
    en: 'General',
    de: 'Allgemeines'
  },
  ALERTS_LETTER_TITLE: {
    en: 'Tenant communication',
    de: 'Mieterpost'
  },
  OC_INSPECTION: {
    en: 'Operational cost inspection',
    de: 'Betriebskosten Belegeinsicht'
  },
  ALERTS_OC_INSPECTION_TITLE: {
    en: 'Operational cost inspection',
    de: 'Betriebskosten Belegeinsicht'
  },
  INBOX_UNAVAILABLE: {
    en: 'Still under construction, coming with the next update.',
    de: 'Mit dem nächsten Update verfügbar.'
  },
  INBOX_EMPTY: {
    en: 'Inbox is empty.',
    de: 'Posteingang ist leer.'
  },
  IMPRINT: {
    en: 'Imprint',
    de: 'Impressum'
  },
  LOYALTY_PROGRAM: {
    en: 'Loyalty program',
    de: 'Treueprogramm'
  },
  HOME: {
    en: 'Home',
    de: 'Hauptmenü'
  },
  BACK_HOME: {
    en: 'Back to home',
    de: 'Zurück zum Hauptmenü'
  },
  BENEFITS: {
    en: 'Top-Deals',
    de: 'Top-Deals'
  },
  BENEFITS_SUBTITLE: {
    en: 'What are Top-Deals?',
    de: 'Wie funktionieren die Top-Deals?'
  },
  BENEFITS_TEXT: {
    en: "As a GCP tenant, you're eligible for great offers from top brands. Explore the possibilities below. Tap a banner to view more details on the partner page and claim your benefits.",
    de: 'Hier finden Sie attraktive und immer wieder neue Top-Deals von Top-Marken. Entdecken Sie spannende Rabatte! Weitere Informationen erhalten Sie nach jedem Klick auf ein Banner.'
  },
  BENEFITS_OFFERS_TITLE: {
    en: 'GCP Offers',
    de: 'GCP Gutschein-Aktionen'
  },
  BENEFITS_VOUCHERS_LINK: {
    en: 'Vouchers for new rentals',
    de: 'GCP Gutschein-Aktionen'
  },
  BENEFITS_OTHER_TITLE: {
    en: 'More great deals',
    de: 'Weitere tolle Angebote'
  },
  BENEFIT_VALID_UNTIL: {
    en: 'Valid until',
    de: 'Gültig bis zum'
  },
  OFFERS_TITLE: {
    en: 'Sales-voucher',
    de: 'Gutschein-Aktionen'
  },
  TENANT_SERVICES: {
    en: 'Tenant Services',
    de: 'Digitale Services'
  },
  REPAIRS_AND_INQUIRIES: {
    en: 'Repairs & Inquiries',
    de: 'Reparaturen & Anfragen'
  },
  DOCUMENTS: {
    en: 'Digital forms',
    de: 'Formulare'
  },
  INBOX: {
    en: 'Inbox',
    de: 'Posteingang'
  },
  PROFILE: {
    en: 'Profile',
    de: 'Profil'
  },
  LOGOUT: {
    en: 'Log out',
    de: 'Ausloggen'
  },
  OFFICE_LOCATION: {
    en: 'Office address',
    de: 'Büroadresse'
  },
  QUICK_LINKS: {
    en: 'Quick links',
    de: 'Schnellzugriff'
  },
  ADD_CONTRACT_THANK_YOU_TEXT: {
    de: 'Danke, dass Sie einen Vertrag hinzugefügt haben.',
    en: 'Thank you for adding a contract.'
  },
  ADD_CONTRACT_MANUAL_THANK_YOU_TEXT: {
    de: 'Ihre Angaben wurden übermittelt. Sie werden per E-Mail informiert, sobald die Überprüfung abgeschlossen ist.',
    en: "Your contract information has been submitted for manual approval by our staff. You'll be updated via email as soon as it is done."
  },
  OVERLAY_TITLE: {
    en: 'AVAILABLE ONCE YOU ADD A CONTRACT',
    de: 'Fügen sie bitte zuerst Ihre Vertragsdaten hinzu.'
  },
  ALREADY_HAVE_ACCOUNT: {
    de: 'Sie haben bereits ein Nutzerkonto?',
    en: 'Already have an account?'
  },
  DO_THIS_LATER_BUTTON: {
    de: 'Später/Nicht jetzt',
    en: 'Do this later'
  },
  SCREEN_1_TEXT: {
    de:
      'Nutzen Sie die GCP Mieterservices\n' +
      'für die Verwaltung von Mietverträgen,\n' +
      'Schadensmeldungen, Anfragen oder die\n' +
      'Buchung einer Mietersprechstunde.',
    en:
      'Use GCP tenant services\n' +
      'like managing rental contracts, reporting\n' +
      'damage, making enquiries and\n' +
      'booking a tenant consultation.'
  },
  SCREEN_2_TEXT: {
    de: 'Profitieren Sie von Top-Deals mit attraktiven Rabatten bei vielen Partnern.',
    en: 'Benefit from Top-Deals with attractive discounts from many partners.'
  },
  SCREEN_3_TEXT: {
    de: 'Unsere Neuigkeiten\nund Events immer\naktuell.',
    en: 'Stay up to date\nwith the latest news\nand events.'
  },
  SCREEN_4_TEXT: {
    de: 'Jetzt mitmachen: Sammeln Sie Treuepunkte und lösen Sie diese in Guthaben auf dem Mieterkonto ein!',
    en: 'Join now: Collect loyalty points and redeem them for credit on your rent account!'
  },
  SCREEN_4_IMAGE_TEXT_1: {
    de: 'Treuepunkte',
    en: 'Loyalty points'
  },
  SCREEN_4_IMAGE_TEXT_2: {
    de: 'Guthaben',
    en: 'Credit'
  },
  BACK_TO_REPAIRS_AND_INQUIRES: {
    de: 'Zurück zu Reparaturen und Anfragen',
    en: 'Back to Repairs & Inquires'
  },
  INQUIRY: { de: 'Anliegen', en: 'Inquiry' },
  YOUR_INQUIRY: { de: 'Ihr Anliegen', en: 'Your inquiry' },
  INQUIRY_SUBMITTED: { de: 'Übertragen', en: 'Submitted' },
  INQUIRY_IN_PROGRESS: { de: 'In Bearbeitung', en: 'In progress' },
  INQUIRY_RESOLVED: { de: 'Abgeschlossen', en: 'Resolved' },
  AMENDED_ON: { de: 'Aktualisiert am', en: 'Amended on' },
  AMEND: { de: 'Ergänzen', en: 'Amend' },
  AMEND_LABEL: { de: 'Aktualisierung', en: 'Amendment' },
  AMEND_CANCEL: { de: 'Abbrechen', en: 'Cancel' },
  AMEND_PLACEHOLDER: {
    de: 'Ursprüngliche Anfrage ändern',
    en: 'Amend original inquiry'
  },
  AMEND_SUCCESS: {
    de: 'Sie haben diese Anfrage erfolgreich geändert.',
    en: 'You have successfully amended this request'
  },
  AMEND_EXCLUDED_FILES: {
    de: 'Einige Dateien sind von der Anfrage ausgeschlossen:',
    en: 'Some of files are excluded from the request:'
  },
  URGENT: { de: 'Notfall', en: 'Urgent' },
  SUBMITTED_ON: { de: 'Erstellt am', en: 'Submitted on' },
  SHOW_RESOLVED: { de: 'Abgeschlossen anzeigen', en: 'Show resolved' },
  HIDE_RESOLVED: { de: 'Abgeschlossen ausblenden', en: 'Hide resolved' },
  NEW_REQUEST: { de: 'Neue Anfrage', en: 'New request' },
  NO_REPORTS: {
    de: 'Sie haben derzeit keine Anfragen eingereicht. Sie können Anfragen über die Schaltfläche oben hinzufügen.',
    en: 'You currently have no tenant requests. You can add a request via button above.'
  },
  FOOTER_PRIVACY_POLICY: {
    en: 'Privacy Policy',
    de: 'Datenschutzerklärung'
  },
  FOOTER_DATA_PROTECTION: {
    en: 'Data Protection',
    de: 'Datenschutz'
  },
  LEGAL: {
    en: 'Legal',
    de: 'Recht'
  },
  NEW_REPORT_TITLE: {
    de: 'Neue Anfrage',
    en: 'New Request'
  },
  SKIP: { en: 'Skip', de: 'Überspringen' },
  NEXT: { en: 'Next', de: 'Vor' },
  GOT_IT: { en: 'Got it', de: 'Verstanden' },
  IMPRINT_PAGE_BRANCH_OFFICE: {
    en: 'Branch office',
    de: 'Firmensitz'
  },
  IMPRINT_PAGE_BRANCH_MANAGER: {
    en: 'Branch manager',
    de: 'Niederlassungsleiter'
  },
  IMPRINT_PAGE_THIS_APP_IS_HOSTED: {
    en: 'This app is hosted and published by Grand City Property Ltd - Zweigniederlassung Deutschland.',
    de: 'Diese App wird betrieben und herausgegeben von Grand City Property Ltd - Zweigniederlassung Deutschland.'
  },
  IMPRINT_PAGE_HEADQUARTERS: {
    en: 'Headquarters',
    de: 'Hauptsitz'
  },
  IMPRINT_PAGE_CONTACT: {
    en: 'Contact',
    de: 'Kontaktperson'
  },
  IMPRINT_PAGE_EDITORIAL_CONTENT: {
    en: 'Editorial content',
    de: 'Redaktioneller Inhalt'
  },
  IMPRINT_PAGE_IMAGE_RIGHTS: {
    en: 'Image rights',
    de: 'Bildrechte'
  },
  IMPRINT_PAGE_IMAGE_RIGHTS_TEXT: {
    en: '© All image rights are owned by GCP or one of the copyright holders listed below:',
    de: '© Alle Bildrechte liegen bei GCP oder bei einem der unten aufgeführten Urheberrechtsinhaber:'
  },
  IMPRINT_PAGE_TECHNICAL_SUPPORT: {
    en: 'Technical support',
    de: 'Technische Betreuung'
  },
  IMPRINT_PAGE_DATA_PROTECTION_DECLARATION: {
    en: 'Data protection declaration',
    de: 'Datenschutzerklärung'
  },
  IMPRINT_PAGE_DATA_PROTECTION_DECLARATION_TEXT: {
    en: 'Please find our data protection declaration ',
    de: 'Unsere aktuelle Datenschutzerklärung '
  },
  IMPRINT_PAGE_DATA_PROTECTION_DECLARATION_LINK: {
    en: 'here',
    de: 'finden Sie hier'
  },
  CONTACT: { en: 'Contact', de: 'Kontakt' },
  IMPORTANT_PHONE_NUMBERS: {
    en: 'Important Phone Numbers',
    de: 'Wichtige Telefonnummern'
  },
  SERVICE_CENTER: { en: 'Service Center', de: 'Service Center' },
  PROSPECTIVE_TENANTS: { en: 'Prospective Tenants', de: 'Mietinteressenten' },
  TENANTS: { en: 'Tenants', de: 'Mieter' },
  EMERGENCY_HOTLINE: { en: 'Emergency Hotline', de: 'Notfall-Hotline' },
  LOYALTY_TENANT_NOT_FOUND: {
    en: 'Tenant not found for selected contract.',
    de: 'Es wurde kein Mieter für den ausgewählten Vertrag gefunden.'
  },
  LOYALTY_TENANT_NOT_FOUND_TITLE: {
    en: 'Registration failed',
    de: 'Registrierung fehlgeschlagen'
  },
  LOYALTY_CONTRACT_NOT_RESIDENTIAL: {
    en: 'You may only apply for our loyalty program with a residential contract.',
    de: 'Zur Teilnahme am Treueprogramm benötigen Sie einen aktiven Wohnungsmietvertrag.'
  },
  LOYALTY_CONTRACT_NOT_RESIDENTIAL_TITLE: {
    en: 'Registration failed - non-residential contract',
    de: 'Registrierung fehlgeschlagen - kein Wohnungsmietvertrag'
  },
  LOYALTY_CONTRACT_NOT_ACTIVE: {
    en: 'This contract is not active.',
    de: 'Dieser Vertrag ist nicht aktiv.'
  },
  LOYALTY_CONTRACT_NOT_ACTIVE_TITLE: {
    en: 'Registration failed',
    de: 'Registrierung fehlgeschlagen'
  },
  LOYALTY_CONTRACT_DEFAULT_ERROR: {
    en: 'Something is not right with your data. Please contact our Service center for help.',
    de: 'Hier ist leider etwas schiefgegangen. Bitte wenden Sie sich an unser Service-Center. '
  },
  LOYALTY_CONTRACT_DEFAULT_ERROR_TITLE: {
    en: 'Registration failed',
    de: 'Registrierung fehlgeschlagen'
  },
  LOYALTY_CONTRACT_NOT_FOUND: {
    en: 'Rent contract not found.',
    de: 'Mietvertrag nicht gefunden.'
  },
  LOYALTY_CONTRACT_NOT_FOUND_TITLE: {
    en: 'Registration failed',
    de: 'Registrierung fehlgeschlagen'
  },
  TERMS_AND_CONDITIONS_REQUIRED: {
    en: 'You must accept terms and conditions in order to join the loyalty program',
    de: 'Für eine Teilnahme am Treueprogramm müssen Sie die Teilnahme- und Nutzungsbedingungen akzeptieren.'
  },
  PARTICIPATE_REQUIRED: {
    en: 'Please confirm that you would like to join the program',
    de: 'Bitte bestätigen Sie, dass Sie am Treueprogramm teilnehmen möchten.'
  },
  LOYALTY_TITLE: {
    en: 'HERE IS HOW IT WORKS',
    de: 'SO FUNKTIONIERT‘S'
  },
  LOYALTY_PROGRAM_VIEW: {
    en: 'YOUR CURRENT LOYALTY POINT BALANCE',
    de: 'IHR AKTUELLER TREUEPUNKTESTAND'
  },
  LOYALTY_SUBTITLE: {
    en: 'Being a GCP tenant pays off! Because you can exchange your loyalty points for an actual credit on your rent account or for attractive rewards!',
    de: 'GCP Mieter sein zahlt sich aus! Denn Sie können Ihre Treuepunkte in ein tatsächliches Guthaben auf Ihrem Mieterkonto oder in attraktive Prämien tauschen!'
  },
  CONTRACT_LIST_TITLE: {
    en: 'Select a contract:',
    de: 'Bitte wählen Sie einen Vertrag aus:'
  },
  PARTICIPATE: {
    en: 'I would like to participate in the loyalty program',
    de: 'Ich möchte am Treueprogramm teilnehmen.'
  },
  LOYALTY_REGISTER: {
    en: 'Join now!',
    de: 'Gleich anmelden!'
  },
  LOYALTY_MAINTENANCE_P1: {
    en: 'Maintenance work is currently taking place due to a software change in our operating system.',
    de: 'Zurzeit finden Wartungsarbeiten aufgrund einer Softwareumstellung in unserem Betriebssystem statt.'
  },
  LOYALTY_MAINTENANCE_P2: {
    en: 'The loyalty program will be available again after the maintenance work has been completed. Your previously collected loyalty points will of course be retained. You can add new loyalty points by yourself at any time after the maintenance work has been completed. ',
    de: 'Das Treueprogramm steht Ihnen nach Abschluss der Wartungsarbeiten wieder zur Verfügung. Ihre bisher gesammelten Treuepunkte bleiben selbstverständlich erhalten. Neue Treuepunkte können Sie nach Abschluss der Wartungsarbeiten jederzeit wie gewohnt selbst hinzufügen.'
  },
  LOYALTY_MAINTENANCE_P3: {
    en: 'Congratulations: After the maintenance work, we will double your current points balance as a thank you for your patience!',
    de: 'Herzlichen Glückwunsch: Nach den Wartungsarbeiten verdoppeln wir Ihren aktuellen Punktestand als Dank für Ihre Geduld!'
  },
  LOYALTY_QUEUE_THANK_YOU_P1: {
    en: 'Thank you for your registration in the loyalty program.',
    de: 'Vielen Dank für Ihre Registrierung im Treueprogramm. '
  },
  LOYALTY_QUEUE_THANK_YOU_P2: {
    en: 'Maintenance work is currently taking place due to a software change in our operating system.',
    de: 'Zurzeit finden Wartungsarbeiten aufgrund einer Softwareumstellung in unserem Betriebssystem statt. '
  },
  LOYALTY_QUEUE_THANK_YOU_P3: {
    en: '500 loyalty points for your registration will be credited to you automatically after the maintenance work is completed.',
    de: '500 Treuepunkte für Ihre Anmeldung werden Ihnen nach Abschluss der Wartungsarbeiten automatisch gutgeschrieben.'
  },
  LOYALTY_QUEUE_THANK_YOU_P4: {
    en: 'You can add all further loyalty point codes yourself at any time after the maintenance work has been completed, as usual.',
    de: 'Alle weiteren Treuepunkte-Codes können Sie jederzeit nach Abschluss der Wartungsarbeiten wie gewohnt selbst hinzufügen.'
  },
  LOYALTY_QUEUE_THANK_YOU_P5: {
    en: 'Thank you for your understanding. ',
    de: 'Wir danken für Ihr Verständnis.'
  },
  LOYALTY_QUEUE_THANK_YOU_BACK_BUTTON: {
    en: 'Back to main menu',
    de: 'Zurück zum Hauptmenü'
  },
  REGISTER: {
    en: 'Register',
    de: 'Registrieren'
  },
  LOYALTY_TERMS_AND_CONDITIONS_1: {
    en: 'I accept the ',
    de: 'Ich akzeptiere die '
  },
  LOYALTY_TERMS_AND_CONDITIONS_2: {
    en: 'terms and conditions',
    de: 'Teilnahme-/Nutzungsbedingungen'
  },
  LOYALTY_FAQ_SUBTITLE: {
    en: 'Here you will find the most important information about the GCP loyalty program.',
    de: 'Hier finden Sie die wichtigsten Infos zum GCP Treueprogramm.'
  },
  LOYALTY_FAQ_TEXT: {
    en: 'Do you have any further questions? Then ask us in the chat.',
    de: 'Haben Sie weitere Fragen? Dann fragen Sie uns im Chat.'
  },
  FAQ_Q_1: {
    de: 'Wer kann sich für das GCP Treueprogramm registrieren?',
    en: 'Who can register for the GCP loyalty program?'
  },
  FAQ_A_1: {
    de: 'Alle Mieter, die über einen laufenden Mietvertrag verfügen, können am GCP Treueprogramm teilnehmen. Pro Haushalt kann sich eine Person für das Treueprogramm registrieren.',
    en: 'All tenants who have a current rent contract can participate in the GCP Loyalty Program. One person per household can register for the loyalty program.'
  },
  FAQ_Q_2: {
    en: 'How can I collect loyalty points?',
    de: 'Wie kann ich Treuepunkte sammeln?'
  },
  FAQ_A_2: {
    en: "You receive your first loyalty points directly when you register for the loyalty program. And you can collect loyalty points in many other ways: for example, for regular, punctual and complete rent payments, for taking out and providing proof of homeowner's insurance or an active green energy contract, for your participation in various tenant events and service surveys, and if you stay with us for a long time. Because every completed rental year also brings additional loyalty points in the form of credit on your rent account!",
    de: 'Sie erhalten Ihre ersten Treuepunkte direkt bei der Registrierung zum Treueprogramm. Und Sie können auf vielen weiteren Wegen Treuepunkte sammeln: zum Beispiel für regelmäßige, pünktliche und vollständige Mietzahlung, für Abschluss und Nachweis einer Hausratsversicherung oder eines aktiven Ökostromvertrags, für Ihre Teilnahme an verschiedenen Mieter-Aktionen und Service-Umfragen und wenn Sie lange bei uns bleiben. Denn auch jedes abgeschlossene Mietjahr bringt zusätzliche Treuepunkte in Form von Guthaben auf Ihrem Mieterkonto!'
  },
  FAQ_Q_3: {
    en: 'What is the value of the loyalty points?',
    de: 'Wie viel sind die Treuepunkte wert?'
  },
  FAQ_A_3: {
    en: '120 loyalty points are equivalent to 1 Euro.',
    de: '120 Treuepunkte entsprechen einem Wert von 1 Euro.'
  },
  FAQ_Q_4: {
    en: 'How can I cash out my loyalty points?',
    de: 'Wie kann ich meine Treuepunkte auszahlen lassen?'
  },
  FAQ_A_4: {
    en: 'After one year of participation in the loyalty program and at least one year of rental contract duration, the collected loyalty points will be credited to your tenant account. If your tenant account shows a credit balance, you can have this paid out.',
    de: 'Nach einem Jahr Teilnahme am Treueprogramm und mindestens einem Jahr Mietvertragsdauer erfolgt eine Gutschrift der gesammelten Treuepunkte auf Ihr Mieterkonto. Sofern Ihr Mieterkonto ein Guthaben ausweist, können Sie sich dieses auszahlen lassen.'
  },
  NO_CONTRACT_SELECTED_ERROR: {
    en: 'Please select the contract.',
    de: 'Bitte wählen Sie den Vertrag aus.'
  },
  CONTRACT_ALREADY_IN_USE: {
    en: 'Another user with the same contract has already enrolled in the Loyalty program. If you think that is incorrect, please contact us.',
    de: 'Ein anderer Nutzer hat sich mit dem gleichen Vertrag für das Treueprogramm angemeldet. Sollte dies nicht korrekt/gewünscht sein, nehmen Sie bitte Kontakt zu uns auf.'
  },
  GCP_POINTS: {
    en: 'Loyalty Points',
    de: 'Treuepunkte'
  },
  SHOW_ALL: {
    en: 'Show all',
    de: 'Alle anzeigen'
  },
  SHOW_LESS: {
    en: 'Show less',
    de: 'Weniger anzeigen'
  },
  TERMS_AND_CONDITIONS_TITLE: {
    en: 'Terms and conditions',
    de: 'Teilnahme-/Nutzungsbedingungen Treueprogramm'
  },
  HOW_TO_RECEIVE_POINTS_TITLE: {
    en: 'How to receive points?',
    de: 'Wie erhält man Treuepunkte?'
  },
  HOW_TO_RECEIVE_POINTS_CONTENT: {
    en: 'You will receive your first loyalty points when you register for the loyalty program. The longer you are our tenant and the more promotions you participate in, the more loyalty points you will receive in the form of credit in your tenant account.',
    de: 'Sie erhalten Ihre ersten Treuepunkte bei der Registrierung zum Treueprogramm. Je länger Sie unser Mieter sind und an je mehr Aktionen Sie teilnehmen, desto mehr Treuepunkte erhalten Sie in Form von Guthaben auf Ihrem Mieterkonto.'
  },
  UNREGISTER: {
    en: 'Unregister',
    de: 'Abmeldung'
  },
  UNREGISTER_TITLE: {
    en: 'Unregister',
    de: 'Abmelden'
  },
  UNREGISTRATION_TEXT: {
    en: 'Are you sure you want to unregister from loyalty program?',
    de: 'Sind Sie sicher, dass Sie sich vom Treueprogramm abmelden möchten?'
  },
  UNREGISTER_BUTTON_TEXT: {
    en: 'I am sure',
    de: 'Ich bin sicher'
  },
  UNREGISTER_LINK_TEXT: {
    en: 'Cancel',
    de: 'Abbrechen'
  },
  TENANT: {
    en: 'Tenant',
    de: 'Mieter'
  },
  CHRISTMAS_GREETING: {
    en: 'This year GCP Santa Claus visits all GCP tenants and tenant children virtually! Taking part is worth it!',
    de: 'In diesem Jahr kommt der GCP Weihnachtsmann virtuell zu allen GCP Mietern und Mieterkindern nach Hause! Mitmachen lohnt sich!'
  },
  CHRISTMAS_GREETING_LINK: {
    en: 'More info here!',
    de: 'Mehr Infos hier!'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE: {
    en: 'Terms of participation and use GCP Loyalty Program',
    de: 'Teilnahme- und Nutzungsbedingungen GCP Treueprogramm'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_1: {
    en: '1. General',
    de: '1. Allgemeines'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_1: {
    en: '(1) Grand City Property Ltd - Branch Office Germany ("GCP") offers its tenants a customer loyalty program ("loyalty program") within the framework of the already existing service app. Here, the tenants, also called participants, have the opportunity to collect loyalty points for various activities related to the tenancy. The collected loyalty points can then, from a certain amount, be converted into credits or other rewards.',
    de: '(1) Grand City Property Ltd - Zweigniederlassung Deutschland („GCP“) bietet seinen Mietern im Rahmen der bereits bestehenden Service-App ein Kundenbindungsprogramm („Treueprogramm“) an. Hierbei haben die Mieter, auch Teilnehmer genannt, die Möglichkeit, für   verschiedene   Aktivitäten,   die   im   Zusammenhang   mit   dem   Mietverhältnis   stehen, Treuepunkte zu sammeln. Die gesammelten Treuepunkte können dann, ab einer bestimmten Anzahl, in Gutschriften oder in sonstige Prämien umgewandelt werden.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_2: {
    en: '(2) The loyalty program is offered and operated by GCP.',
    de: '(2) Das Treueprogramm wird angeboten und durchgeführt von GCP.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_3: {
    en: '(3) The  use  of  the  loyalty  program  by  participants  is  subject  to  these  terms  and  conditions  of participation  and  use,  which  always  refer  to  the  updated  version  of  the  service  app,  which means that these terms and conditions of participation and use continue to apply even after any update of the service app.',
    de: '(3) Die Verwendung des Treueprogramms durch die Teilnehmer unterliegt diesen Teilnahme- und Nutzungsbedingungen, die sich immer auf die aktualisierte Fassung der Service-App beziehen, was  bedeutet,  dass  diese  Teilnahme-  und  Nutzungsbedingungen  auch  nach  einem  etwaigen Update der Service-App fortbestehen.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_4: {
    en: '(4) Participation in the loyalty program requires a separate registration by the participant within the service app.',
    de: '(4) Für  die  Teilnahme  an  dem  Treueprogramm  ist  eine  gesonderte  Anmeldung  durch  den Teilnehmer innerhalb der Service-App notwendig.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_5: {
    en: '(5) By  registering  in  the  loyalty  program,  participants  agree  to  these  terms  and  conditions  of participation and use and take note of the data protection provisions linked below under item 9 (1).',
    de: '(5) Durch ihre Anmeldung im Treueprogramm erklären sich die Teilnehmer mit diesen Teilnahme- und Nutzungsbedingungen einverstanden und nehmen die unten unter Punkt 9 (1) verlinkten Datenschutzbestimmungen zur Kenntnis.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_6: {
    en: '(6) The  service  provider  within  the  meaning  of  these  terms  and  conditions  of  participation  and use is GCP itself or a service provider or authorised representative commissioned by GCP.',
    de: '(6) Leistungserbringer im Sinne dieser Teilnahme- und Nutzungsbedingungen ist GCP selbst oder ein von GCP beauftragter Dienstleister oder Bevollmächtigter.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_2: {
    en: '2. Eligibility',
    de: '2. Teilnahmeberechtigung'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_2_1: {
    en: '(1) Persons who have a valid rental contract with GCP and are at least 18 years old are eligible to participate. Participation is limited to one person or household per rented flat.',
    de: '(1) Teilnahmeberechtigt sind Personen, die einen gültigen Mietvertrag mit GCP aufweisen können und mindestens 18 Jahre alt sind. Die Teilnahme ist pro angemieteter Wohnung lediglich auf eine Person, bzw. ein Haushalt begrenzt.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_2_2: {
    en: '(2) Participation is free of charge.',
    de: '(2) Die Teilnahme ist kostenlos.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_2_3: {
    en: '(3) There is no legal claim to participation in the loyalty program.',
    de: '(3) Ein Rechtsanspruch auf Teilnahme am Treueprogramm besteht nicht.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_3: {
    en: '3. Registration',
    de: '3. Registrierung'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_1: {
    en: '(1) Participation in the loyalty program requires that participants register within the service app with the necessary data (first name, last name, e-mail or telephone number, tenant number or  contract  number).  Furthermore,  the  participants  must  submit  an  active  declaration  of consent to these terms and conditions of participation and use and take note of the provisions of data protection law.',
    de: '(1) Die  Teilnahme  am  Treueprogramm  setzt  voraus,  dass  sich  die  Teilnehmer  innerhalb  der Service-App mit den notwendigen Daten (Vorname, Nachname, E-Mail oder Telefonnummer, Mieternummer bzw. Vertragsnummer) anmelden. Ferner müssen die Teilnehmer eine aktive Einverständniserklärung zu diesen Teilnahme- und Nutzungsbedingungen abgeben sowie die datenschutzrechtlichen Bestimmungen zur Kenntnis nehmen.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_2: {
    en: '(2) After  successful  registration,  a  separate  loyalty  points  account  will  be  created  for  each participant. It is possible to view the points balance at any time.',
    de: '(2) Nach der erfolgreichen Anmeldung wird für jeden Teilnehmer ein eigenes Treuepunkte-Konto angelegt. Es besteht die Möglichkeit, den Punktestand jederzeit einzusehen.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_3: {
    en: '(3) The  participants  commit  themselves  to  inform  GCP  immediately  of  any  changes  in  their personal data.',
    de: '(3) Die Teilnehmer verpflichten sich, GCP umgehend etwaige Änderungen der persönlichen Daten mitzuteilen.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_4: {
    en: '(4) Multiple  registrations  for  the  loyalty  program  are  not  possible.  GCP  is  entitled  to  delete  all loyalty  point  accounts  that  have  been  opened  unlawfully  and,  in  particular,  with  false  data. GCP reserves the right, in the case of illegally opened accounts, to also close the associated account that was legally opened. In such cases, the loyalty points credited to the loyalty points account will expire without replacement.',
    de: '(4) Mehrfachanmeldungen zum Treueprogramm sind nicht möglich. GCP ist berechtigt, sämtliche unrechtmäßig  und  insbesondere  mit  falschen  Daten  eröffneten  Treuepunkte-Konten  zu löschen.  GCP  behält  sich  das  Recht  vor,  bei  unrechtmäßig  eröffneten  Konten  auch  das zugehörige Konto zu schließen, welches rechtmäßig eröffnet wurde. In solchen Fällen verfallen die auf dem Treuepunkte-Konto gutgeschriebenen Treuepunkte ersatzlos.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_5: {
    en: '(5) Participation in the loyalty program is voluntary and, unless otherwise provided for in these terms and conditions of participation and use, there is no legal obligation for the participating person.',
    de: '(5) Die  Teilnahme  an  dem  Treueprogramm  ist  freiwillig  und  es  besteht,  soweit  in  diesen Teilnahme-   und   Nutzungsbedingungen   nichts   anderes   geregelt   ist,   keine   rechtliche Verpflichtung für die teilnehmende Person.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_4: {
    en: '4. Loyalty points',
    de: '4. Treuepunkte'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_1: {
    en: '(1) The  loyalty  program  is  a  level-based  customer  loyalty  program,  which  is  determined  by  the number of loyalty points that a participant has collected through eligible activities to collect points.',
    de: '(1) Bei dem Treueprogramm handelt es sich um ein stufenbasiertes Kundenbindungsprogramm, das von der Anzahl an Treuepunkten bestimmt wird, die ein Teilnehmer durch anrechenbare Aktivitäten zum Punktesammeln gesammelt hat.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_2: {
    en: '(2) By  enrolling  in  this  loyalty  program,  participants  are  given  the  opportunity  to  earn  loyalty points  through  various  activities.  For  example,  loyalty  points  can  already  be  collected  by registering for the loyalty program. Other ways to collect points include:',
    de: '(2) Mittels Anmeldung in diesem Treueprogramm erhalten die Teilnehmer die Möglichkeit, über verschiedene  Aktivitäten Treuepunkte  zu  sammeln. Beispielsweise  können  bereits  durch  die Anmeldung zum Treueprogramm Treuepunkte gesammelt werden. Weitere Sammelmöglichkeiten sind beispielsweise:'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_1: {
    en: 'Registration in the loyalty program (200 loyalty points),',
    de: 'Registrierung im Treueprogramm (200 Treuepunkte),'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_2: {
    en: 'Regular  punctual  and  complete  payment  of  the  rent  (10  loyalty  points  per  month), including   any   subsequent   payments   on   the   utility   bills   (50   loyalty   points   per subsequent payment);',
    de: 'regelmäßig  pünktliche  und  vollständige  Zahlung  der  Miete  (10  Treuepunkte  pro Monat), einschließlich etwaiger Nachzahlungen auf die Betriebskostenabrechnungen (50 Treuepunkte je Nachzahlung);'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_3: {
    en: 'Conclusion of a new tenancy agreement with GCP (300 loyalty points);',
    de: 'Abschluss eines neuen Mietvertrages bei GCP (300 Treuepunkte);'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_4: {
    en: 'participation in selected tenant promotions where points can be collected (120 -240 loyalty points);',
    de: 'die Teilnahme an ausgewählten Mieteraktionen, bei denen Punktesammeln möglich ist (120-240 Treuepunkte);'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_5: {
    en: 'taking out and providing proof of active household insurance (50 -100 loyalty points);',
    de: 'der Abschluss und Nachweis einer aktiven Hausratsversicherung (50-100 Treuepunkte);'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_6: {
    en: 'for each completed rental year with GCP from the start of participation in the loyalty program (50 loyalty points);',
    de: 'für jedes abgeschlossene Mietjahr bei GCP ab Beginn der Teilnahme am Treueprogramm (50 Treuepunkte);'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_7: {
    en: 'Participation in a service survey (50 loyalty points);',
    de: 'Teilnahme an einer Service Umfrage (50 Treuepunkte);'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_8: {
    en: 'Proof of a completed and active eco-electricity contract (120 loyalty points).',
    de: 'Nachweis eines abgeschlossenen und aktiven Ökostromvertrages (120 Treuepunkte).'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_3: {
    en: '(3) The above list is not conclusive. Multiple points are possible for selected actions. It is at the disposal of GCP how many loyalty points are granted for the respective collection opportunities. GCP reserves the right to introduce additional opportunities to collect points. In this case, participants will be informed of these new opportunities. All opportunities to collect points, including those introduced at a later date, are subject to these Terms and Conditions of Participation and Use without the need for any further agreement.',
    de: '(3) Die vorstehende Aufzählung ist nicht abschließend. Eine Mehrfachpunktung ist bei ausgewählten Aktionen möglich. Es liegt im Ermessen von GCP, wie viele Treuepunkte bei den jeweiligen Sammelmöglichkeiten gewährt  werden. GCP behält sich das Recht vor, weitere Möglichkeiten zum Punktesammeln einzuführen. Die Teilnehmer werden in diesem Fall über diese neuen Möglichkeiten informiert. Sämtliche, auch später eingeführte Möglichkeiten zur Punktesammlung unterliegen diesen Teilnahme-  und Nutzungsbedingungen, ohne dass es einer weiteren Vereinbarung bedarf.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_4: {
    en: '(4) The collection option shall only exist from the time the participant registers to participate in the loyalty program. A subsequent crediting of loyalty points, which lie before the registration with the loyalty program, is not possible.',
    de: '(4) Die Sammelmöglichkeit besteht erst ab der Registrierung des Teilnehmers zur Teilnahme am Treueprogramm.  Ein  nachträgliches  Gutschreiben  von  Treueunkten,  die  zeitlich  vor  der Registrierung beim Treueprogramm liegen, ist nicht möglich.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_5: {
    en: '(5) GCP  reserves  the  right  to  temporarily  or  permanently  deactivate  one  or  more  of  the  above collection  options,  as  well  as  future  collection  options  after  their  introduction,  and/or  to increase or decrease the number of loyalty points. Participants will be fully informed of any such changes in the loyalty program.',
    de: '(5) GCP    behält    sich    das    Recht    vor,    einzelne    oder    mehrere    der    oben    angegebenen Sammelmöglichkeiten  sowie  nach  deren  Einführung  auch  künftige  Sammelmöglichkeiten vorübergehend   oder   permanent   zu   deaktivieren   und/oder   die   Anzahl   der   jeweiligen Treuepunkte   zu   erhöhen   oder   zu   verringern.   Die   Teilnehmer   werden   jeweils   über entsprechende Änderungen im Treueprogramm umfassend informiert.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_5: {
    en: '5. Redeeming loyalty points for credit or rewards',
    de: '5. Einlösen von Treuepunkten in Guthaben oder Prämien'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_5_1: {
    en: "(1) The collected loyalty points can be used to purchase rewards such as merchandise vouchers or credits on the tenant's account.",
    de: '(1) Mit   den   gesammelten   Treuepunkten   können   Prämien   wie   Warenwertgutscheine   oder Gutschriften auf dem Mieterkonto erworben werden.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_5_2: {
    en: "(2) If the participant wishes a reward, the redeemability results directly from the respective reward list being published. The premium list for merchandise value vouchers will be published separately according to the availability of the merchandise value vouchers. GCP has the right to refrain from publishing award lists for merchandise vouchers, or to change or remove lists that have already been published. A claim to receive a specific value voucher in a previously published  reward  list  exists  only  to  the  extent  that  the  value  vouchers  are  available.  If  the vouchers  are  exhausted  or  a  reward  list  is  removed  after  publication,  the  loyalty  points  can only be redeemed for credits on the tenant's account. The currently valid reward list for credits on the tenant account results from the following paragraph 3 and the regulations under point 4 of these Terms and Conditions of Participation and Use.",
    de: '(2) Wünscht der Teilnehmer eine Prämie, ergibt sich die Einlösbarkeit direkt aus der jeweils zu veröffentlichenden Prämienliste. Die Prämienliste für Warenwertgutscheine wird entsprechend der Verfügbarkeit der Warenwertgutscheine separat veröffentlicht. GCP hat das Recht, auf die Veröffentlichung von  Prämienlisten  für  Warenwertgutscheine  zu  verzichten, oder bereits veröffentlichte Listen zu ändern oder zu entfernen. Ein Anspruch auf Erhalt eines bestimmten  Wertgutscheines  in  einer  bereits  veröffentlichten  Prämienliste  besteht  leidglich in  dem  Umfang,  indem  die  Wertgutscheine  vorhanden  sind.  Sollten  die  Wertgutscheine aufgebraucht oder eine Prämienliste nach Veröffentlichung wieder entfernt werden, können die Treuepunkte lediglich in Gutschriften auf dem Mieterkonto eingelöst werden. Die derzeit geltende  Prämienliste  für  Gutschriften  auf  dem  Mieterkonto  ergibt  sich  aus  dem  folgenden Absatz 3 und den Regelungen unter Punkt 4 dieser Teilnahme- und Nutzungsbedingungen.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_5_3: {
    en: "(3) If a participant wishes to have his/her account credited, 120 loyalty points are equivalent to 1 euro.  A  conversion  is  only  possible  with  the  full  120  loyalty  points.  A  pro  rata  conversion  or conversion  into  fractions  shall  not  take  place.  A  payment  can  only  be  made  if  the  tenant's account has no balance. If this is the case, the collected loyalty points will be credited to the tenant's account at the end of the calendar year. A pay-out can only be made if the participant explicitly provides his/her account details for this purpose. A prerequisite for the redemption of the loyalty points is that the achieved number of loyalty points on the loyalty points account corresponds to the minimum requirements of the desired redemption.",
    de: '(3) Wünscht  ein  Teilnehmer  eine  Gutschrift  auf  seinem   Konto,  entsprechen  120  Treuepunkte umgerechnet  1  Euro.  Eine  Umwandlung  ist  nur  mit  vollen  120  Treuepunkten  möglich.  Eine anteilige  Umwandlung  bzw.  Umrechnung  in  Bruchteilen  erfolgt  nicht.  Eine  Auszahlung  kann nur dann erfolgen, wenn das Mieterkonto keinen Saldo aufweist. Ist dies der Fall, werden die gesammelten   Treuepunkte   am   Ende   eines   Kalenderjahres   dem   Konto   des   Mieters gutgeschrieben.  Eine  Auszahlung  kann  nur  dann  erfolgen,  wenn  der  Teilnehmer  seine Kontodaten hierfür explizit mitteilt. Voraussetzung für das Einlösen der Treuepunkte ist, dass die erreichte Treuepunkteanzahl auf dem Treuepunkte-Konto den Mindestanforderungen der gewünschten Einlösung entspricht.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_6: {
    en: '6. Access to loyalty account',
    de: '6. Zugriff auf das Treuepunkte-Konto'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_6_1: {
    en: '(1) Participants have the option of accessing the loyalty points account at any time via the service app. Participants undertake to protect the loyalty points account from unauthorised access by a third party. GCP is not liable for unauthorised disposals on the loyalty points account.',
    de: '(1) Die  Teilnehmer  haben  jederzeit  über  die  Service-App  die  Möglichkeit,  auf  das  Treuepunkte-Konto   zuzugreifen.   Die   Teilnehmer   verpflichten   sich,   das   Treuepunkte-Konto   vor   dem unbefugten Zugriff eines Dritten zu schützen. GCP haftet nicht für unbefugte Verfügungen auf dem Treuepunkte-Konto.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_6_2: {
    en: '(2) GCP is entitled to close access to the loyalty program or to temporarily or permanently exclude individual  participants  from  using  it  if  there  is  suspicion that a participant is not using the program in accordance with these terms and conditions of participation and use.',
    de: '(2) GCP  ist  berechtigt,  den  Zugang  zum  Treueprogramm  zu  schließen  oder  einzelne  Teilnehmer vorübergehend oder dauerhaft von der Nutzung auszuschließen, wenn der Verdacht besteht, dass  ein Teilnehmer das Programm nicht entsprechend dieser Teilnahme- und Nutzungsbedingungen verwendet.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_7: {
    en: '7. Liability',
    de: '7. Haftung'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_7_1: {
    en: '(1) GCP is liable, regardless of the legal reason, only for intent or gross negligence. This also applies in  particular  to  any  partners  in  the  loyalty  program,  for  services  to  be  provided  or  the acquisition of loyalty points.',
    de: '(1) GCP haftet, gleich aus welchem Rechtsgrund, nur für Vorsatz oder grobe Fahrlässigkeit. Dies gilt insbesondere auch für etwaige Partner im Treueprogramm, für zu erbringende Leistungen oder den Erwerb von Treuepunkten.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_7_2: {
    en: "(2) The limitation of liability does not apply to personal injury or such damage arising from the Product Liability Act. Insofar as liability  is  excluded,  GCP  is  in  particular  not  obliged  to reimburse participants for loyalty points not credited to them due to the negligence of GCP's partners. GCP assumes no liability for illegal, incorrect or incomplete content of third parties who  are  involved  in  any  way  in  the  loyalty  program,  provided  that  GCP  had  no  actual knowledge  of  the  incompleteness,  incorrectness  or  illegality  and  did  not  cause  this  lack  of knowledge intentionally or negligently or is responsible for it.",
    de: '(2) Die Haftungsbegrenzung gilt nicht für Personenschäden oder solche Schäden aus dem Produkthaftungsgesetz. Soweit die Haftung ausgeschlossen  ist,  ist  GCP  insbesondere  nicht dazu  verpflichtet,  den  Teilnehmern  aufgrund  von  Versäumnissen  der  Partner von GCP nicht gutgeschriebene Treuepunkte zu erstatten. GCP übernimmt keinerlei Haftung für rechtswidrige, fehlerhafte oder unvollständige Inhalte von Dritten, die in irgendeiner Weise an dem  Treueprogramm  beteiligt  sind,  sofern  GCP  von  der  Unvollständigkeit,  Fehlerhaftigkeit oder Rechtswidrigkeit tatsächlich keine Kenntnis hatte und diese Unkenntnis nicht vorsätzlich oder fahrlässig selbst herbei geführt oder zu vertreten hat.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_7_3: {
    en: '(3) GCP  assumes  no  liability  for  the  actual  accessibility  of  the  loyalty  program  and  expressly reserves  the  right  to  terminate  or  temporarily  or  permanently  suspend  the  loyalty  program without   giving   reasons.   The   relevant   decisions   of   GCP   will   be   communicated   to   the participants. In the event of a termination of the loyalty program, the loyalty points collected up  to  that  point  may  still  be  redeemed,  provided  that  they  meet  the  above  minimum requirements.  No  loyalty  points  may  be  collected  after  the  termination  of  this  program  or during its suspension.',
    de: '(3) GCP  übernimmt  keine  Haftung  für  die  tatsächliche  Zugänglichkeit  des  Treueprogramms  und behält sich ausdrücklich vor, das Treueprogramm ohne Angabe von Gründen zu beenden oder vorübergehend  oder  endgültig  auszusetzen.  Die  entsprechenden  Entscheidungen  von  GCP werden den Teilnehmern mitgeteilt. Im Falle einer Beendigung des Treueprogramms können die  bis  dahin  gesammelten  Treuepunkte  noch  eingelöst  werden,  sofern  diese  die  obigen Mindestanforderungen  erfüllen.  Ein  Sammeln  von  Treuepunkten  ist  nach  der  Beendigung dieses Programms oder während dessen Aussetzung nicht möglich.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_8: {
    en: '8. Cancellation/termination of participation in the loyalty program',
    de: '8. Kündigung/Beendigung der Teilnahme am Treueprogramm'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_8_1: {
    en: '(1) The participant has the option to terminate his/her participation in the loyalty program at any time without stating reasons. Both the personal data and the collected loyalty points will be deleted immediately. The termination can be made in writing to GCP or by e-mail to post@grandcityproperty.de. In doing so, the participant must state his serial number or tenant number.',
    de: '(1) Der Teilnehmer hat die Möglichkeit, seine Teilnahme an dem Treueprogramm jederzeit ohne Nennung von Gründen zu kündigen. Sowohl die persönlichen Daten als auch die gesammelten Treuepunkte werden unverzüglich gelöscht. Die Kündigung kann schriftlich an GCP oder per E-Mail an post@grandcityproperty.de erfolgen. Hierbei hat der Teilnehmer seine Seriennummer bzw. Mieternummer anzugeben.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_8_2: {
    en: '(2) The loyalty program ends automatically if the tenancy is terminated, the participant has given notice, the participant has left the tenancy or participation in the loyalty program is refused by GCP for other reasons already mentioned.',
    de: '(2) Das Treueprogramm endet automatisch, wenn das Mietverhältnis beendet wird, der Teilnehmer gekündigt hat, der Teilnehmer aus dem Mietverhältnis ausgetreten ist oder die Teilnahme an dem Treueprogramm aus sonstigen, bereits genannten Gründen, durch GCP verweigert wird.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_9: {
    en: '9. Data protection',
    de: '9. Datenschutz'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_1a: {
    en: '(1) Insofar as personal data are provided by the participants, these are subject to our data protection provisions, which can be viewed ',
    de: '(1) Soweit von den Teilnehmern personenbezogene Daten zur Verfügung gestellt werden, unterliegen diese unseren Datenschutzbestimmungen, welche '
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_LINK: {
    en: 'here',
    de: 'hier'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_1b: {
    en: '.',
    de: ' einsehbar sind.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_2: {
    en: '(2) We process your personal data to implement the loyalty program. This is done on the basis of Art. 6 (1) lit. b GDPR, which permits the processing of data for the performance of a contract or pre-contractual measures. Without processing your data, it would not be possible for us to collect loyalty points and provide you with your rewards.',
    de: '(2) Wir verarbeiten Ihre personenbezogenen Daten zur Durchführung des Treueprogramms. Dies erfolgt  auf  Grundlage  von  Art.  6  Abs.  1  lit.  b  DSGVO,  der  die  Verarbeitung  von  Daten  zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet. Ohne die Verarbeitung Ihrer  Daten  wäre  es  uns  nicht  möglich,  Treuepunkte  zu  sammeln  und  Ihnen  Ihre  Prämien bereitzustellen.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_3: {
    en: '(3) Your data will remain stored after completion of the reward request in order to recognise you in case of renewed reward requests and, if applicable, to be able to acknowledge your special loyalty. Processing for other purposes apart from this will not take place.',
    de: '(3) Ihre  Daten  bleiben  nach  Abschluss  der  Prämienanforderung  weiter  gespeichert,  um  Sie  bei erneuten    Prämienanforderungen    wiederzuerkennen    und    ggf.    Ihre    besondere    Treue anerkennen zu können. Eine Verarbeitung für andere Zwecke außer diesem findet nicht statt.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_4: {
    en: '(4) This  processing  is  carried  out  in  accordance  with  Art.  6  (1)  lit.  f  GDPR,  which  permits  the processing of data if there are legitimate interests on our part. Our legitimate interest here is to provide long-term support to our loyal and faithful customers via our loyalty program.',
    de: '(4) Diese Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten bei   Vorhandensein   berechtigter   Interessen   unsererseits   gestattet.   Unser   berechtigtes Interesse dabei ist, unsere loyalen und treuen Kunden über unser Treueprogramm langjährig zu betreuen.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_5: {
    en: '(5) You can object to the storage of your data at any time, in which case we will delete your data unless there are still legal retention periods. Such retention periods result, among other things, from tax law regulations and extend back up to ten years for documents and receipts relevant under tax law.',
    de: '(5) Sie können der Speicherung Ihrer Daten jederzeit widersprechen, in dem Falle werden wir Ihre Daten löschen, sofern nicht noch gesetzliche Aufbewahrungsfristen bestehen. Solche ergeben sich  unter  anderem  aus  steuerrechtlichen  Vorschriften  und  reichen  für  steuerrechtlich relevante Unterlagen und Belege bis zu zehn Jahre zurück.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_10: {
    en: '10. Amendment of the terms and conditions of participation and use',
    de: '10. Änderung der Teilnahme- und Nutzungsbedingungen'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_10_1: {
    en: 'GCP expressly reserves the right to make changes, additions and/or deletions to these terms and  conditions  of  participation  and  use.  Corresponding  changes,  additions  and/or  deletions will become valid if GCP notifies the participants in writing in advance.',
    de: 'Änderungen, Ergänzungen und/oder Streichungen dieser Teilnahme- und Nutzungsbedingungen    werden    ausdrücklich    durch    GCP    vorbehalten.    Entsprechende Änderungen,  Ergänzungen  und/oder  Streichungen  erlangen  Gültigkeit,  wenn  GCP  diese  den Teilnehmern vorher schriftlich mitteilt.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_11: {
    en: '11. Place of jurisdiction',
    de: '11. Gerichtsstand'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_11_1: {
    en: 'German law shall apply exclusively. The place of jurisdiction is Berlin, insofar as permissible.',
    de: 'Es gilt ausschließlich deutsches Recht. Gerichtstand ist Berlin, soweit zulässig.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_12: {
    en: '12. Final Clauses',
    de: '12. Schlussbestimmungen'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_12_1: {
    en: 'Should any provision of these Terms of Participation and Use be or become invalid, this shall not  affect  the  validity  of  the  remaining  provisions.  The  invalid  provision  shall  be  replaced,  if necessary, by a provision that comes as close as possible to the meaning and purpose of the invalid provision in a legally effective manner. The same shall apply in the event of loopholes.',
    de: 'Sollte  eine  Bestimmung  dieser  Teilnahme-  und  Nutzungsbedingungen  unwirksam  sein  oder werden,  wird  hierdurch  die  Wirksamkeit  der  übrigen  Bestimmungen  nicht  berührt.  Die unwirksame Bestimmung wird gegebenenfalls durch eine solche Bestimmung ersetzt, welche dem  Sinn  und  Zweck  der  unwirksamen  Bestimmung  in  rechtswirksamer  Weise  am  nächsten kommt. Gleiches gilt bei Regelungslücken.'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_BOLD_NOTE: {
    en: 'Note:',
    de: 'Hinweis:'
  },
  LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_NOTE: {
    en: 'Within these Terms and Conditions of Participation and Use, the masculine form is used for all non-gender-neutral designations for reasons of simplification. Of course, the information always refers to all genders (male, female, diverse).',
    de: 'Innerhalb    dieser    Teilnahme-    und    Nutzungsbedingungen    wird    für    alle    nicht geschlechtsneutralen  Bezeichnungen  aus  Vereinfachungsgründen  die  männliche  Form  verwendet. Selbstverständlich beziehen sich die Angaben stets auf alle Geschlechter (männlich, weiblich, divers).'
  },
  LOYALTY_TEXT_BOLD: {
    en: 'Collect more loyalty points!',
    de: 'Sammeln Sie noch mehr Treuepunkte!'
  },
  LOYALTY_UPLOAD_TEXT: {
    en: 'Click below to upload the document.',
    de: 'Klicken Sie unten, um das Dokument hochzuladen.'
  },
  ADD_CERTIFICATE: {
    en: 'Add eco-certificate',
    de: 'Ökostromzertifikat hinzufügen'
  },
  ADD_PROOF: {
    en: 'Add proof of insurance',
    de: 'Versicherungsnachweis hinzufügen'
  },
  SUBMIT_CERTIFICATE: {
    en: 'Submit eco-certificate*',
    de: 'Ökostromzertifikat senden*'
  },
  SUBMIT_PROOF: {
    en: 'Submit proof of insurance*',
    de: 'Versicherungsnachweis senden*'
  },
  UPLOAD_SUBMIT_TEXT: {
    en: '*After submitting, the upload will be checked and the points will be assigned.',
    de: '*Sobald der Nachweis hochgeladen und bestätigt wurde, werden die Punkte nach Überprüfung gutgeschrieben.'
  },
  BACK_TO_LOYALTY: {
    en: 'Back to loyalty program',
    de: 'Zurück zum Treueprogramm'
  },
  THANK_YOU_CERTIFICATE: {
    en: 'Your eco electricity certificate is now pending for review.',
    de: 'Ihr Ökostrom-Zertifikat wird nun überprüft.'
  },
  THANK_YOU_PROOF: {
    en: 'Your proof of insurance is now pending for review.',
    de: 'Ihr Versicherungsnachweis wird nun überprüft.'
  },
  INVALID_FILE_TYPE: {
    en: 'The document you are trying to upload is of invalid type or it is larger than 20MB. Please try again.',
    de: 'Das Dokument, das Sie hochladen möchten, ist ein ungültiger Dateityp oder größer als 20 MB. Bitte versuchen Sie es erneut.'
  },
  EXTERNAL_FILE_ERROR: {
    en: 'You can only upload documents from your device. Please remove external documents from uploads, add documents from your device and try again.',
    de: 'Sie können nur Dokumente von Ihrem Gerät hochladen. Bitte entfernen Sie externe Dokumente aus Uploads, fügen Sie Dokumente von Ihrem Gerät hinzu und versuchen Sie es erneut.'
  },
  LOYALTY_EXTERNAL_FILE_ERROR: {
    en: 'You can only upload documents from your device. Please try again.',
    de: 'Sie können nur Dokumente von Ihrem Gerät hochladen. Bitte versuchen Sie es erneut.'
  },
  LOGOUT_TEXT: {
    en: 'Are you sure you want to log out?',
    de: 'Sind Sie sicher, dass Sie sich abmelden möchten?'
  },
  LOGOUT_YES: {
    en: 'Yes',
    de: 'Ja'
  },
  LOGOUT_NO: {
    en: 'No',
    de: 'Nein'
  },
  DELETE_ACCOUNT: {
    en: 'Delete account',
    de: 'Account löschen'
  },
  DELETE_ACCOUNT_TEXT: {
    en: 'Are you sure you want to delete your account? If you select “Yes”, your account will be immediately deactivated, and your data will be deleted within 90 days.',
    de: 'Wollen Sie Ihren Account wirklich löschen? Wenn sie bestätigen, wird Ihr Account deaktiviert und innerhalb von 90 Tagen gelöscht.'
  },
  DELETE_ACCOUNT_YES: {
    en: 'Yes',
    de: 'Ja'
  },
  DELETE_ACCOUNT_NO: {
    en: 'No',
    de: 'Nein'
  },
  UPLOAD_CODE_BUTTON: {
    en: 'Redeem promotion code',
    de: 'Aktionscode einlösen'
  },
  LOYALTY_CODE_SUBTITLE: {
    en: 'Here you can redeem a promotional code that you have received as part of a loyalty promotion.',
    de: 'Hier können Sie einen Aktionscode einlösen, den Sie im Rahmen einer Treueaktion erhalten haben.'
  },
  LOYALTY_SUBMIT_CODE: {
    en: 'Confirm',
    de: 'Bestätigen'
  },
  LOYALTY_CASH_OUT_BUTTON: {
    en: 'Start Cash-Out',
    de: 'Auszahlung starten'
  },
  LOYALTY_INITIAL_MESSAGE_TITLE: {
    en: 'Loyalty Program Outpayment possible',
    de: 'Treueprogramm Auszahlung möglich'
  },
  LOYALTY_INITIAL_MESSAGE_TEXT1: {
    en: 'Congratulations! You have collected loyalty points worth ',
    de: 'Herzlichen Glückwunsch! Sie haben bisher Treuepunkte im Wert von '
  },
  LOYALTY_INITIAL_MESSAGE_TEXT2: {
    en: ' Euro in the GCP loyalty program.',
    de: ' Euro im GCP Treueprogramm gesammelt.'
  },
  LOYALTY_INITIAL_MESSAGE_TEXT3: {
    en: 'You can either get a deposit as credit on your rent account – or you keep collecting your loyalty points.',
    de: 'Ihr Punkteguthaben können Sie sich wahlweise als Guthaben auf Ihr Mieterkonto übertragen lassen oder Sie sammeln einfach noch mehr Treuepunkte.'
  },
  LOYALTY_INITIAL_MESSAGE_TEXT4: {
    en: 'Just choose one of the following options:',
    de: 'Bitte wählen Sie eine der folgenden Optionen:'
  },
  LOYALTY_INITIAL_MESSAGE_BTN1: {
    en: 'Deposit credit to rent account',
    de: 'Übertragen als Guthaben auf Mieterkonto'
  },
  LOYALTY_INITIAL_MESSAGE_BTN2: {
    en: 'Deposit to my rent account',
    de: 'Einzahlen auf mein Mieterkonto'
  },
  LOYALTY_INITIAL_MESSAGE_BTN3: {
    en: 'Keep the points and save for later',
    de: 'Punkte behalten und weiter sparen'
  },
  LOYALTY_PAYMENT_CASH_OUT_TITLE: {
    en: 'Deposit credit to rent account',
    de: 'Übertragen von Guthaben auf Mieterkonto'
  },
  LOYALTY_PAYMENT_CASH_OUT_TEXT1: {
    en: 'We will gladly credit your rent account with the equivalent value of the GCP loyalty points you have collected so far.',
    de: 'Gerne schreiben wir den Gegenwert für Ihre bisher gesammelten GCP Treuepunkte auf Ihrem Mieterkonto gut.'
  },
  LOYALTY_PAYMENT_CASH_OUT_TEXT2: {
    en: 'Confirm this by clicking on „Deposit“.',
    de: 'Bestätigen Sie dies, indem sie auf „Einzahlen“ klicken.'
  },
  LOYALTY_PAYMENT_IBAN: {
    en: 'Enter IBAN',
    de: 'IBAN eingeben'
  },
  LOYALTY_PAYMENT_CASH_OUT_TEXT3: {
    en: 'I confirm that this IBAN may be used for the cash-out of the loyalty points to my bank account.',
    de: 'Ich bestätige, dass diese IBAN für die Auszahlung meines Treuepunkteguthabens genutzt werden darf.'
  },
  LOYALTY_PAYMENT_CASH_OUT_TEXT4: {
    en: 'Please enter your GCP app credentials to authorize the cash-out',
    de: 'Bitte geben Sie hier noch einmal Ihre Anmeldedaten für die GCP App ein, um die Auszahlung zu autorisieren.'
  },
  LOYALTY_PAYMENT_CASH_OUT_BTN: {
    en: 'Deposit',
    de: 'Einzahlen'
  },
  LOYALTY_PAYMENT_CASH_OUT_THANK_YOU: {
    en: 'The equivalent value of your GCP loyalty points collected so far will be transferred to your bank account in a timely manner.',
    de: 'Der Gegenwert Ihrer bisher gesammelten GCP Treuepunkte wird Ihnen zeitnah auf Ihr Bankkonto überwiesen.'
  },
  LOYALTY_PAYMENT_DEPOSIT_TITLE: {
    en: 'Loyalty Program Deposit to my rent account',
    de: 'Treueprogramm Einzahlung auf mein Mieterkonto'
  },
  LOYALTY_PAYMENT_DEPOSIT_TEXT1: {
    en: 'We will gladly credit your tenant account with the equivalent value of the GCP loyalty points you have collected so far.',
    de: 'Gerne schreiben wir den Gegenwert für Ihre bisher gesammelten GCP Treuepunkte Ihrem Mieterkonto gut.  '
  },
  LOYALTY_PAYMENT_DEPOSIT_TEXT2: {
    en: 'Confirm this by clicking on "Deposit".',
    de: 'Bestätigen Sie dies, indem sie auf „Einzahlen“ klicken.'
  },
  LOYALTY_DEPOSIT_BTN: {
    en: 'Deposit',
    de: 'Einzahlen'
  },
  LOYALTY_PAYMENT_KEEP_POINTS_TITLE: {
    en: 'Keep the points and save for later',
    de: 'Punkte behalten und weiter sparen'
  },
  LOYALTY_PAYMENT_KEEP_POINTS_TEXT1: {
    en: 'You want to increase your points balance further?',
    de: 'Sie wollen Ihr Punkteguthaben weiter erhöhen?'
  },
  LOYALTY_PAYMENT_KEEP_POINTS_TEXT2: {
    en: 'Then keep your current points balance and simply collect even more loyalty points!',
    de: 'Dann behalten Sie Ihr aktuelles Punkteguthaben und sammeln Sie einfach noch mehr Treuepunkte!'
  },
  LOYALTY_PAYMENT_KEEP_POINTS_TEXT3: {
    en: 'Your already saved points balance will of course remain with you.',
    de: 'Ihr bisher bereits angespartes Punkteguthaben bleibt Ihnen'
  },
  LOYALTY_PAYMENT_KEEP_POINTS_BTN: {
    en: 'Confirm',
    de: 'Bestätigen'
  },
  LOYALTY_CODE_PLACEHOLDER: {
    en: 'Enter code here',
    de: 'Hier Code eingeben'
  },
  LOYALTY_CODE_ERROR_NOT_FOUND: {
    en: 'The code you entered is not correct. Please try again with another code. If you receive an error message again, please contact us in the chat.',
    de: 'Der eingegebene Code ist leider ungültig. Bitte versuchen Sie einen anderen Code. Erhalten Sie wieder eine Fehlermeldung, dann kontaktieren Sie uns im Chat.'
  },
  LOYALTY_CODE_ERROR_NOT_ACCEPTABLE: {
    en: 'The code you entered is expired. Please try again with another code. If you receive an error message again, please contact us in the chat.',
    de: 'Der eingegebene Code ist leider abgelaufen. Bitte versuchen Sie einen anderen Code. Erhalten Sie wieder eine Fehlermeldung, dann kontaktieren Sie uns im Chat.'
  },
  LOYALTY_CODE_ERROR_ALREADY_REDEEMED: {
    en: 'The code you entered is already redeemed. Please try again with another code. If you receive an error message again, please contact us in the chat.',
    de: 'Der eingegebene Code wurde bereits eingelöst. Bitte versuchen Sie einen anderen Code. Erhalten Sie wieder eine Fehlermeldung, dann kontaktieren Sie uns im Chat.'
  },
  LOYALTY_CODE_ERROR_LIMIT_EXCEEDED: {
    de: 'Ihre Anfragen werden derzeit verarbeitet. Wenn Sie weitere Anfragen stellen möchten, versuchen Sie es später erneut.',
    en: 'Your requests are currently being processed. If you would like to make additional requests, please try again later.'
  },
  THANK_YOU_REDEEM_CODE_1: {
    en: 'Your loyalty points account will be credited with ',
    de: 'Ihrem Treuepunkte-Konto werden '
  },
  THANK_YOU_REDEEM_CODE_2: {
    en: ' loyalty points.',
    de: ' Treuepunkte gutgeschrieben.'
  },
  LOYALTY_CODE_THANK_YOU: { de: 'Danke!', en: 'Thank you!' },
  LOYALTY_SLIDE_TEXT_1: {
    en: 'WORTH IT:',
    de: 'LOHNT SICH:'
  },
  LOYALTY_SLIDE_TEXT_2: {
    en: 'THE GCP',
    de: 'DAS GCP'
  },
  LOYALTY_SLIDE_TEXT_3: {
    en: 'LOYALTY PROGRAM',
    de: 'TREUEPROGRAMM'
  },
  LOYALTY_CODE_EMPTY: {
    en: 'Please enter your valid promotional code',
    de: 'Bitte geben Sie einen gültigen Aktionscode an'
  },
  NEWS_SLIDE_TITLE: {
    de: 'Neuigkeiten',
    en: 'News'
  },
  FOR: {
    de: 'fur',
    en: 'for'
  },
  IS: {
    de: 'ist',
    en: 'is'
  },
  FROM: {
    de: 'ab',
    en: 'from'
  },
  ON: {
    de: 'am',
    en: 'on'
  },
  NEW: {
    de: 'NEU',
    en: 'NEW'
  },
  RESEND_VERIFICATION_EMAIL: {
    de: 'Bestätigungsmail erneut senden',
    en: 'Resend verification email'
  },
  RESEND_VERIFICATION_EMAIL_SUCCESS: {
    de: 'Bestätigungs-E-Mail gesendet!',
    en: 'Verification email sent!'
  },
  RESEND_VERIFICATION_EMAIL_ERROR: {
    de: 'Etwas ist schief gelaufen! Bitte versuche es erneut!',
    en: 'Something went wrong! Please try again!'
  },
  SOMETHING_WENT_WRONG: {
    de: 'Etwas ist schief gelaufen! Bitte versuche es erneut!',
    en: 'Something went wrong! Please try again!'
  },
  HEATING_AND_ENERGY_TITLE: { de: 'Energieverbrauch (UVI)', en: 'Energy consumption (UVI)' },
  HEATING_AND_ENERGY_HEATING: { de: 'Heizung', en: 'Heating' },
  HEATING_AND_ENERGY_WARM_WATER: { de: 'Warmwasser', en: 'Warm water' },
  HEATING_AND_ENERGY_CONSUMPTION: { de: 'Verbrauch:', en: 'Consumption:' },
  HEATING_AND_ENERGY_PREVIOUS_MONTH: { de: 'Vormonat:', en: 'Previous month:' },
  HEATING_AND_ENERGY_PREVIOUS_MONTH_LAST_YEAR: { de: 'Monat des Vorjahres:', en: 'Month previous year:' },
  HEATING_AND_ENERGY_COMPARISON: { de: 'Vergleich zum Durchschinttsnutzer:', en: 'Comparison with the average user:' },
  HEATING_AND_ENERGY_NO_DATA: { de: 'Für den ausgewählten Zeitraum sind keine Daten verfügbar.', en: 'There is no data available for the selected period.' },
  HEATING_AND_ENERGY_EMPTY_RESULTS: { de: 'Für Ihr Mietobjekt liegen derzeit keine Energiedaten vor. Verbrauchsdaten können dann nicht angezeigt werden, wenn der Zähler nicht fernauslesbar ist oder ein Mieterwechsel stattgefunden hat. Wir arbeiten bereits an einer Lösung.', en: 'No energy data is currently available for your rental property. Consumption data cannot be displayed if the meter cannot be read remotely or if there has been a change of tenant. We are already working on a solution.' },
  HEATING_AND_ENERGY_ERROR: { de: 'Ihre Daten konnten nicht abgerufen werden, bitte versuchen Sie es später noch einmal.', en: 'There was an error retrieving your data, please try again later.' },
  HEATING_AND_ENERGY_WELCOME_ONE: { de: 'Die smarte, kostenfreie Lösung zum Erhalt der monatlichen Verbrauchsinformationen! Registrieren Sie ich jetzt!', en: 'The smart, free solution to receive monthly consumption information! Register now!' },
  HEATING_AND_ENERGY_WELCOME_TWO: { de: 'Mit Registrierung und Einwilligung, entfällt der kostenpflichtige Briefversand. Voraussetzung für den Service ist, dass fernauslesbare Messtechnik in der Nutzeinheit vorhanden ist, die ohne Zutritt zur Nutzeinheit, Verbrauchsdaten an den Messdienst sendet.', en: 'With registration and consent, there is no need to send a letter for a fee. A prerequisite for the service is that remotely readable metering technology is available in the user unit, which can sends consumption data to the metering service without access to the unit' },
  HEATING_AND_ENERGY_REGISTER: { de: 'Registrieren', en: 'Register' },
  HEATING_AND_ENERGY_CANCEL: { de: 'Abbrechen', en: 'Cancel' },
  HEATING_AND_ENERGY_CONFIRM: { de: 'Bestätigen', en: 'Confirm' },
  HEATING_AND_ENERGY_CANCEL_CONFIRM_TEXT_ONE: { de: 'Ich bestätige hiermit die Verarbeitung und Bereitstellung der unterjährigen Verbrauchsdaten via App.', en: 'I hereby confirm the processing and provision of the consumption data during the year via app.' },
  HEATING_AND_ENERGY_CANCEL_CONFIRM_TEXT_TWO: { de: 'Dadurch entfällt eine Übermittlung mittels Anschreiben und der zu belastenden, umlagefähigen Kosten für die Erstellung und dem Briefversand, in Höhe von 2,50 € je Brief.', en: 'This eliminates the need for transmission by letter and the chargeable, apportionable costs of € 2.50 per letter for the preparation and dispatch of the letter.' },
  HEATING_AND_ENERGY_CHECKNOX_TEXT: { de: 'Sie erhalten die Informationen per App.', en: 'Receive information via app.' },
  HEATING_AND_ENERGY_UNREGISTER_TEXT_ONE: { de: 'Ich nehme meine Einwilligung zur Bereitstellung der unterjährigen Verbrauchsdaten via App hiermit zurück.', en: 'I hereby withdraw my consent to the provision of consumption data during the year via the app.' },
  HEATING_AND_ENERGY_UNREGISTER_TEXT_TWO: { de: 'Eine Rücknahme der Einwilligung wird im folgenden Monat wirksam. Daraus resultierend werden zukünftig, neue Verbrauchsinformationen, mittels Anschreiben, kostenpflichtig versendet. Die Kosten für den Briefversand (Erstellung und Porto) betragen bis zu 2,50 € pro Monat. Diese Kosten werden im Zuge der jährlichen Heizkostenabrechnung zur Abrechnung gebracht.', en: 'Withdrawal of consent will take effect in the following month. As a result, new consumption information will be sent in future by letter at a charge. The costs for sending the letter (production and postage) amount up to € 2.50 per month. These costs are allocated with the annual heating bill.' },
  HEATING_AND_ENERGY_NOTIFICATION: { de: 'Benachrichtigung', en: 'Notification' },
  HEATING_AND_ENERGY_LEARN_MORE: { de: 'Mehr erfahren', en: 'Learn more' },
  HEATING_AND_ENERGY_REMIND: { de: 'Später erinnern', en: 'Remind me later' },
  HEATING_AND_ENERGY_NOTIFICATION_TEXT: { de: 'Die unterjährige Verbrauchsinformation ist jetzt in der App abrufbar.', en: 'The monthly energy consumption is now available in the app' },
  LETTERS_REGISTRATION_TEXT_1: {de: 'Jetzt neu:', en: 'Now available:'},

  LETTERS_REGISTRATION_TEXT_2: {de: 'Lassen Sie sich Infobriefe und Co. ab sofort direkt über unsere App zustellen - umweltfreundlich und kostenlos! Registrieren Sie sich jetzt für diesen praktischen Service und sichern Sie sich 50 Treuepunkte.', en: 'Have info letters and other mailings delivered directly via our app - environmentally friendly and free of charge! Register now for this convenient service and earn 50 loyalty points.'},
  LETTERS_UNREGISTRATION_TEXT: {de: 'Sind Sie sicher, dass Sie diesen umweltfreundlichen und kostenlosen Service abmelden möchten? Sie werden keine Infobriefe und Co. mehr über unsere App erhalten.', en: 'Are you sure you want to unsubscribe from this environmentally friendly and free service? You will no longer receive info letters and other mailings via our app.'},
  NO_LETTERS_MESSAGE: {de: 'Noch keine Briefe vorhanden.', en: 'No letters available yet.'},
  DAY_MONTAG: { de: 'Montag', en: 'Monday' },
  DAY_DIENSTAG: { de: 'Dienstag', en: 'Tuesday' },
  DAY_MITTWOCH: { de: 'Mittwoch', en: 'Wednesday' },
  DAY_DONNERSTAG: { de: 'Donnerstag', en: 'Thursday' },
  DAY_FREITAG: { de: 'Freitag', en: 'Friday' },
  DAY_SAMSTAG: { de: 'Samstag', en: 'Saturday' },
  DAY_SONNTAG: { de: 'Sonntag', en: 'Sunday' },
  CONTRACT_OVERVIEW_TITLE: {de: 'Vertragsübersicht', en: 'Contract overview'},
  CONTRACT_OVERVIEW_DETAILS: {de: 'Einzelheiten', en: 'Details'},
  CONTRACT_OVERVIEW_MY_RENT: {de: 'Meine Miete', en: 'My rent'},
  CONTRACT_OVERVIEW_CONTRACT_NUMBER: {de: 'Vertragsnummer:', en: 'Contract number:'},
  CONTRACT_OVERVIEW_RENT_START: {de: 'Beginndatum des Mietvertrags:', en: 'Rent contract start date:'},
  CONTRACT_OVERVIEW_RENT_END  : {de: 'Enddatum des Mietvertrags:', en: 'Rent contract end date:'},
  CONTRACT_OVERVIEW_ADDRESS  : {de: 'Adresse:', en: 'Address:'},
  CONTRACT_OVERVIEW_TENANTS  : {de: 'Vertragspartner:', en: 'Tenant name(s):'},
  CONTRACT_OVERVIEW_COLD_RENT  : {de: 'Kaltmiete:', en: 'Cold rent:'},
  CONTRACT_OVERVIEW_OPERATIONAL_COST  : {de: 'Betriebskosten:', en: 'Operational cost:'},
  CONTRACT_OVERVIEW_HEATING_COST  : {de: 'Heizung:', en: 'Heating cost:'},
  CONTRACT_OVERVIEW_TOTAL_COST: {de: 'Gesamtkosten', en: 'Total cost'},
  CONTRACT_OVERVIEW_RAB_BTN: {de: 'Saldo - ', en: 'Rent account balance - '},
  CONTRACT_OVERVIEW_DOCUMENTS_BTN: {de: 'Mieterunterlagen', en: 'Tenant documents'},
  VIEW_OC_CALCULATION: { en: 'View OC calculation', de: 'Nebenkostenabrechnung anzeigen' },
  OC_CALCULATION: { en: 'OC calculation', de: 'Nebenkostenabrechnung' },
  CONTRACT_OVERVIEW_ERROR: {de: 'Zuzeit sind keine Daten verfügbar', en: 'There is no data available at the moment'},
  RENT_CONTRACT_DOCUMENTS: { en: 'Rent contract documents', de: 'Mietvertragsunterlagen' },
  RECEIPT_INSPECTION: {
    de: 'Betriebskosten Belegeinsicht',
    en: 'Operational cost receipt inspection'
  },
  NO_RECEIPTS: {
    de: 'Es liegen keine Belege zur Einsichtnahme vor',
    en: 'There are no receipts available for inspection'
  },
  NO_CALCULATIONS: {
    de: 'Es liegen gerade keine Abrechnungen vor.',
    en: 'There are currently no calculations available.'
  },
  NO_RENT_CONTRACT_DOCUMENTS: {
    de: 'Es sind keine Dokumente verfügbar.',
    en: 'There are no documents available.'
  },
  CONTACT_OC: {
    de: 'Betriebskostenabteilung kontaktieren',
    en: 'Contact the OC department'
  },
  CONTACT_TECHNICAL: {
    de: 'Technisches Problem melden',
    en: 'Report a technical problem',
  },
  YOUR_MESSAGE: {
    de: 'Ihre Nachricht',
    en: 'Your Message'
  },
  ABSENDEN: {
    de: 'Absenden',
    en: 'Send',
  },
  OC_COMPLAINT_THANKS: {
    de: 'Vielen Dank für Ihre Nachricht!\nWir werden uns über bei Ihnen über die in der TenantApp gespeicherte Emailadresse melden.',
    en: 'Thank you for your message!\nWe will contact you via the email address stored in the TenantApp.'
  },
  TRACKING_MODAL_TITLE: {
    de: 'Accept tracking',
    en: 'Accept tracking'
  },
  TRACKING_MODAL_CONTENT: {
    en: 'Do you accept?',
    de: 'Do you accept?',
  },
  ACCEPT: {
    en: 'Accept',
    de: 'Accept',
  },
  CLOSE: {
    en: 'Close',
    de: 'Close',
  }
}
export default strings;
